import { CheckOutlined, CloseOutlined, CloudDownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataEdit,
    NyDataTable,
    NyDatePicker,
    NyRequestResolver,
    NySearchField,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Tabs, Tooltip } from 'antd';
import moment from 'moment';
import React, { ReactText, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import {
    getColumnSearchByCodebookBusinessUnit,
    getColumnSearchByCodebookEmployeeWithAlias,
    getColumnSearchByCodebookSimple,
    getColumnSearchByCodebookSimpleWithAddedData,
    getColumnSearchWorkplaceDetails,
} from '../../../../utils/Filters';
import {
    customEmployeeRenderOption,
    errorNodeNotification,
    errorNotification,
    fileDownload,
    getDateFormat,
    okNotification,
    setSearchFormat,
} from '../../../../utils/Utils';
import NyFilesUpload from '../../../../utils/ny-file-upload-custom';
import { BusinessUnitSearch } from '../business-unit/search';
import WorkplaceDetailsIndex from '../workplace/DetailIndex';
import { AdministrationRights } from '../../../../rights/administrationRights';

const ScheduleSuggestionsEdit = (props: any) => {
    const table = useTableSettings();
    const [editHeader, setEditHeader] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [visibleModalApproval, setVisibleModalApproval] = useState<any>(false);
    const [typeMORH, setTypeMORH] = useState<any>(false);
    const [selectedValuesDeleteGroup, setSelectedValuesDeleteGroup] = useState<any>([]);
    const [hasSelectedDeleteGroup, setHasSelectedDeleteGroup] = useState(false);
    const [refreshTable, setRefreshTable] = useState(1);
    const [isAddListModalVisibleGroup, setIsAddListModalVisibleGroup] = useState(false);
    const [rowModalVisible, setRowModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loadingModalGroup, setLoadingModalGroup] = useState(false);
    const [sentToMorhAction, setSentToMorhAction] = useState<any>(false);
    const militaryWorkApprovalType = useEnum('MILITARY_WORK_APPROVAL_TYPE');
    const [approvalForm] = Form.useForm();
    const [employeeId, setEmployeeId] = useState<any>(undefined);
    const [businessUnit, setBusinessUnit] = useState<any>(undefined);
    const [businessUnitAddToSuggest, setBusinessUnitAddToSuggest] = useState<any>(undefined);
    const [workplace, setWorkplace] = useState<any>(undefined);
    const [workplaceAddToSuggest, setWorkplaceAddToSuggest] = useState<any>(undefined);
    const [selectedRowEmployee, setSelectedRowEmployee] = useState<any>(undefined);
    const [editEmployee, setEditEmployee] = useState<any>(undefined);
    const [activeKey, setActiveKey] = useState('1');
    const [activeKeyModalApprove, setActiveKeyModalApprove] = useState('1');
    const defenseDepartmentType = useEnum('DEFENSE_DEPARTMENT_TYPE');
    const militaryWorkApprovalStatus = useEnum('MILITARY_WORK_APPROVAL_STATUS');
    const mobilizationReportType = useEnum('MOBILIZATION_REPORT_TYPE');
    const [form] = Form.useForm();
    const { id } = useParams<any>();
    const history = useHistory();
    const focusInput = useRef<any>(null);
    const uploadFilesRef = useRef<any>(null);
    const militaryWorkApprovalFileType = useEnum('MILITARY_WORK_APPROVAL_FILE_TYPE');
    const { employee } = useSelector((state: RootState) => state.employee);
    const [refreshFiles, setRefreshFiles] = useState(0);

    const getAdminRole = () => {
        return AdministrationRights.isAdmin();
    };

    const [isAdmin, setIsAdmin] = useState<any>(getAdminRole());

    function setValues(dataForm: any) {
        setEditHeader(
            geti18nText('app.enum.MILITARY.TYPE.' + dataForm.type) +
                (dataForm.type === militaryWorkApprovalType.MORH
                    ? dataForm.defenseDepartment && dataForm.defenseDepartment.name
                        ? ' -' + dataForm.defenseDepartment.name
                        : ''
                    : '')
        );

        if (dataForm.type === militaryWorkApprovalType.MORH) {
            setTypeMORH(true);
        }

        setDataForm(dataForm);
    }

    const setDefaultFilterValueSearch = (type: any) => {
        return [{ field: 'type', condition: 'equals', value: type }];
    };

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('schedule.suggestions.edit.header'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };
    let clearKeysDeleteGroup: any;
    const setSelectedValuesFuncDeleteGroup = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        setHasSelectedDeleteGroup(hasSelected);
        setSelectedValuesDeleteGroup(selectedRows);
        clearKeysDeleteGroup = clearSelectedRowKeys;
    };

    const removeFromGroup = () => {
        const res = selectedValuesDeleteGroup.map((value: any) => {
            return value.employee.id;
        });

        NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.REMOVE_EMPLOYEES, undefined, {
            militaryWorkApprovalId: dataForm.id,
            employeesId: res,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (clearKeysDeleteGroup) {
                    clearKeysDeleteGroup();
                }
                okNotification();
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };

    const addEmployeeToSuggestion = () => {
        form.validateFields()
            .then((values: any) => {
                setLoadingModalGroup(true);
                let data: any = {
                    militaryWorkApprovalId: dataForm.id,
                    type: dataForm.type,
                    employeeId: employeeId.id,
                };

                if (businessUnitAddToSuggest) {
                    data.businessUnit = { id: businessUnitAddToSuggest.id };
                }
                if (workplaceAddToSuggest) {
                    data.workplaceDetails = { id: workplaceAddToSuggest.id };
                }
                if (values.note) {
                    data.remark = values.note;
                }

                NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.ADD_EMPLOYEE, undefined, data).then(
                    (result: any) => {
                        if (setLoadingModalGroup) setLoadingModalGroup(false);
                        if (result && result.status === RESPONSE.CREATED) {
                            okNotification();
                            setIsAddListModalVisibleGroup(false);
                            setRefreshTable((refreshTable) => refreshTable + 1);
                            form.resetFields();
                            setBusinessUnitAddToSuggest(undefined);
                            setWorkplaceAddToSuggest(undefined);
                            setEmployeeId(undefined);
                        } else {
                            errorNotification(geti18nText('app.default.save.nok'));
                            setIsAddListModalVisibleGroup(false);
                            setRefreshTable((refreshTable) => refreshTable + 1);
                            form.resetFields();
                            setBusinessUnitAddToSuggest(undefined);
                            setWorkplaceAddToSuggest(undefined);
                            setEmployeeId(undefined);
                        }
                    }
                );
                setLoadingModalGroup(false);
                setIsAddListModalVisibleGroup(false);
            })
            .catch(() => {
                return false;
            });
    };

    const getCustomFooterContent = (
        <React.Fragment>
            {
                <div style={{ float: 'left' }}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        icon={
                            <i
                                style={{
                                    fontSize: '14px',
                                    marginRight: '3px',
                                    marginLeft: '-3px',
                                    marginTop: '-4px',
                                }}
                                className="las la-folder-plus"
                            ></i>
                        }
                        onClick={() => {
                            setIsAddListModalVisibleGroup(true);
                        }}
                    >
                        {geti18nText('schedule.suggestions.modal.employees.btn.add.employee.to.suggestion')}
                    </Button>

                    <Popconfirm
                        title={geti18nText('schedule.suggestions.remove.from.group.comfirm')}
                        okText={geti18nText('app.default.button.confirm')}
                        cancelText={geti18nText('app.default.button.cancel')}
                        disabled={!hasSelectedDeleteGroup}
                        onConfirm={removeFromGroup}
                    >
                        <Button
                            style={{ marginLeft: '5px' }}
                            disabled={!hasSelectedDeleteGroup}
                            danger
                            icon={<DeleteOutlined />}
                        >
                            {geti18nText('schedule.suggestions.modal.employees.btn.remove')}
                        </Button>
                    </Popconfirm>
                </div>
            }
        </React.Fragment>
    );

    const onEmployeeChange = (val: any) => {
        if (val.id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + val.id, undefined).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        let data = result.data;
                        if (data.businessUnit) {
                            setBusinessUnitAddToSuggest(data.businessUnit);
                            data.businessUnit = {
                                id: data.businessUnit.id,
                                name: data.businessUnit.code
                                    ? data.businessUnit.name + ' (' + data.businessUnit.code + ')'
                                    : data.businessUnit.name,
                            };

                            data.businessUnit = setSearchFormat(data.businessUnit, 'name', 'name');
                            form.setFieldsValue({ businessUnit: data.businessUnit });
                        }

                        if (data.workplaceDetails) {
                            NyRequestResolver.requestGet(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.CHECK_WORKPLACE_DETAILS, {
                                workplaceDetailsId: data.workplaceDetails.id,
                                militaryWorkApprovalId: dataForm.id,
                                employeeId: employeeId,
                            }).then((result: any) => {
                                if (result.status === RESPONSE.OK) {
                                    let workplaceDetails = {
                                        text: data.workplaceDetails?.workplace?.vocation?.name,
                                        vocationName: data.workplaceDetails?.workplace?.vocation?.name,
                                        vocationDescription:
                                            data.workplaceDetails?.workplace?.vocationDescription?.name,
                                    };
                                    setWorkplaceAddToSuggest({
                                        key: data.workplaceDetails.id,
                                        id: data.workplaceDetails.id,
                                        text: customRenderOptionWorkPlaceDetails(workplaceDetails),
                                    });
                                    form.setFieldsValue({
                                        workplaceDetailsToSuggest: {
                                            key: data.workplaceDetails.id,
                                            id: data.workplaceDetails.id,
                                            text: customRenderOptionWorkPlaceDetails(workplaceDetails),
                                        },
                                    });
                                } else {
                                    errorNodeNotification(
                                        undefined,
                                        'schedule.suggestions.genereate.edit.employee.workplaceDetails.exist'
                                    );
                                }
                            });
                        }
                    }
                }
            });
        }
        setEmployeeId(val);
    };

    const approveAction = (values?: any) => {
        setConfirmLoading(true);
        let data: any = {
            militaryWorkApproval: {
                type: typeMORH ? militaryWorkApprovalType.MORH : militaryWorkApprovalType.GOVERNOR,
                id: dataForm.id,
            },
        };
        if (values) {
            if (values.objectName) {
                data.militaryWorkApproval.objectName = values.objectName;
            }
            if (values.classification) {
                data.militaryWorkApproval.classification = values.classification;
            }
            if (values.submissionNumber) {
                data.militaryWorkApproval.submissionNumber = values.submissionNumber;
            }
            if (values.dateInput) {
                data.militaryWorkApproval.inputDate = getDateFormat(values.dateInput);
            }
            if (values.dateOutput) {
                data.militaryWorkApproval.outputDate = getDateFormat(values.dateOutput);
            }
        }
        if (typeMORH) {
            if (dataForm.defenseDepartment) {
                data.militaryWorkApproval.defenseDepartment = { id: dataForm.defenseDepartment.id };
            }
            if (dataForm.defenseSection) {
                data.militaryWorkApproval.defenseSection = { id: dataForm.defenseSection.id };
            }
        }

        NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.APPROVE, undefined, data).then(
            (result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification(geti18nText('schedule.suggestions.edit.approve.success'));
                    props.setVisible(false);
                    props.onSave();
                    approvalForm.resetFields();
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                }
                setConfirmLoading(false);
            }
        );
    };

    const onRowSelect = (item: any) => {
        let url = CONSTANTS_REQ.MILITARY_WORK_APPROVAL.GET_EMPLOYEE;
        url = url.replace('{militaryWorkApprovalId}', dataForm.id);
        url = url.replace('{itemId}', item.id);
        NyRequestResolver.requestGet(url, undefined).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                let data = result.data;
                if (data.employee) {
                    setEditEmployee(data.employee);
                }
                if (data.businessUnit) {
                    setBusinessUnit(data.businessUnit);
                    data.businessUnit = {
                        id: data?.businessUnit?.id,
                        name: data.businessUnit.code
                            ? data.businessUnit.name + ' (' + data.businessUnit.code + ')'
                            : data.businessUnit.name,
                    };
                    data.businessUnit = setSearchFormat(data.businessUnit, 'name', 'name');
                    form.setFieldsValue({ businessUnit: data.businessUnit });
                }
                if (data.workplaceDetails) {
                    let workplaceDetails = {
                        text: data.workplaceDetails?.workplace?.vocation?.name,
                        vocationName: data.workplaceDetails?.workplace?.vocation?.name,
                        vocationDescription: data.workplaceDetails?.workplace?.vocationDescription?.name,
                    };
                    setWorkplace({
                        key: data.workplaceDetails.id,
                        id: data.workplaceDetails.id,
                        text: customRenderOptionWorkPlaceDetails(workplaceDetails),
                    });
                    form.setFieldsValue({
                        workplaceDetails: {
                            key: data.workplaceDetails.id,
                            id: data.workplaceDetails.id,
                            text: customRenderOptionWorkPlaceDetails(workplaceDetails),
                        },
                    });
                }
                if (data.remark) {
                    form.setFieldsValue({ note: data.remark });
                }
            }
        });
        setSelectedRowEmployee(item);
        setRowModalVisible(true);
    };

    const saveToEmployeeRow = () => {
        let data: any = {
            id: selectedRowEmployee.id,
            militaryWorkApproval: { id: dataForm.id },
            employee: { id: selectedRowEmployee.employee.id },
        };
        if (businessUnit) {
            data.businessUnit = { id: businessUnit.id };
        }
        if (workplace) {
            data.workplaceDetails = { id: workplace.id };
        }
        if (form.getFieldValue('note')) {
            data.remark = form.getFieldValue('note');
        }
        NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.UPDATE_EMPLOYEE, undefined, {
            employeeItem: data,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
                //props.setVisible(false)
                setRowModalVisible(false);
                form.resetFields();
                setBusinessUnit(undefined);
                setWorkplace(undefined);
                setRefreshTable((refreshTable) => refreshTable + 1);
            } else {
                errorNotification(geti18nText('app.default.save.nok'));
            }
        });
    };
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const sentToMorh = (values: any) => {
        setConfirmLoading(true);
        let data: any = { militaryWorkApprovalId: dataForm.id };
        if (values) {
            if (values.objectName) {
                data.sentObjectName = values.objectName;
            }
            if (values.classification) {
                data.sentClassification = values.classification;
            }
            if (values.submissionNumber) {
                data.sentSubmissionNumber = values.submissionNumber;
            }
            if (values.dateInput) {
                data.sentInputDate = getDateFormat(values.dateInput);
            }
            if (values.dateOutput) {
                data.sentOutputDate = getDateFormat(values.dateOutput);
            }
        }

        NyRequestResolver.requestPost(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.SEND_PLAN, undefined, data).then(
            (result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    okNotification(geti18nText('schedule.suggestions.edit.sent.success'));
                    props.setVisible(false);
                    approvalForm.resetFields();
                    setSentToMorhAction(false);
                    form.resetFields();
                    props.onSave();
                } else {
                    errorNotification(geti18nText('app.default.save.nok'));
                }
                setConfirmLoading(false);
            }
        );
    };

    const downloadDocument = (type: any) => {
        fileDownload(
            CONSTANTS_REQ.REPORT.MOBILIZATION_REPORT,
            { type: type, refId: dataForm.id },
            geti18nText('schedule.suggestions.generate.plan') +
                '(' +
                getDateFormat(new Date(), 'DD.MM.YYYY') +
                ')' +
                (dataForm.type === militaryWorkApprovalType.MORH
                    ? dataForm.defenseDepartment && dataForm.defenseDepartment.name
                        ? ' -' + dataForm.defenseDepartment.name
                        : ''
                    : '') +
                '.xlsx'
        );
    };

    const formSelectedEmployeeRowTitle = () => {
        if (selectedRowEmployee) {
            return (
                (selectedRowEmployee.person.oib ? selectedRowEmployee.person.oib + ' - ' : '') +
                (selectedRowEmployee.person.firstName ? selectedRowEmployee.person.firstName : '') +
                ' ' +
                (selectedRowEmployee.person.lastName ? selectedRowEmployee.person.lastName : '')
            );
        }
    };

    const customRenderOptionWorkPlaceDetails = (option: any) => {
        return (
            <>
                <span>
                    {option.vocationName + (option.vocationDescription ? ' - ' + option.vocationDescription : '')}
                </span>
            </>
        );
    };

    const onChangeWorkplaceEditEmployee = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.CHECK_WORKPLACE_DETAILS, {
            workplaceDetailsId: value.id,
            militaryWorkApprovalId: dataForm.id,
            employeeId: editEmployee.id,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setWorkplace(value);
            } else {
                setWorkplace(workplace ? workplace : undefined);
                form.setFieldsValue({ workplaceDetails: workplace ? workplace : undefined });
                errorNodeNotification(undefined, 'schedule.suggestions.genereate.edit.employee.workplaceDetails.exist');
            }
        });
    };

    const onChangeWorkplaceAddEmployee = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.MILITARY_WORK_APPROVAL.CHECK_WORKPLACE_DETAILS, {
            workplaceDetailsId: value.id,
            militaryWorkApprovalId: dataForm.id,
            employeeId: employeeId,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setWorkplaceAddToSuggest(value);
            } else {
                setWorkplaceAddToSuggest(workplaceAddToSuggest ? workplaceAddToSuggest : undefined);
                form.setFieldsValue({
                    workplaceDetailsToSuggest: workplaceAddToSuggest ? workplaceAddToSuggest : undefined,
                });
                errorNodeNotification(undefined, 'schedule.suggestions.genereate.edit.employee.workplaceDetails.exist');
            }
        });
    };

    const onChangeBusinessUnit = (value: any) => {
        setBusinessUnit(value);
        setWorkplace(undefined);
        form.setFieldsValue({ workplaceDetails: undefined });
    };

    const onChangeBusinessUnitAddToSuggest = (value: any) => {
        setBusinessUnitAddToSuggest(value);
        setWorkplaceAddToSuggest(undefined);
        form.setFieldsValue({ workplaceDetailsToSuggest: undefined });
    };

    const columnsFiles: any = [
        ...[
            {
                title: geti18nText('fileList.table.column.file.name'),
                dataIndex: 'name',
            },
            {
                title: geti18nText('schedule.suggestions.approve.modal.tab.files.note'),
                dataIndex: 'description',
            },
            {
                title: geti18nText('schedule.suggestions.approve.modal.tab.files.type'),
                dataIndex: 'type',
                render: (text: any, record: any) => {
                    if (record.type != undefined) {
                        return geti18nText('app.enum.MILITARY.WORK.APPROVAL.FILE.TYPE.' + record.type);
                    }
                },
            },
            {
                title: geti18nText('fileList.table.column.uploaded'),
                dataIndex: 'uploaded',
                render: (text: any, record: any) => {
                    if (record.uploaded) {
                        return moment(record.uploaded).format('DD.MM.YYYY HH:mm');
                    }
                },
            },
        ],
        ...[
            {
                title: geti18nText('fileList.table.column.user.name'),
                dataIndex: ['person.firstName'],
                render: (text: any, record: any) => {
                    if (record.person) {
                        return record.person.firstName + ' ' + record.person.lastName;
                    }
                },
            },
            isAdmin
                ? {
                      title: geti18nText('fileList.table.column.active'),
                      dataIndex: 'active',
                      width: '10%',
                      render: (text: any, record: { active: any }) => {
                          if (record.active) {
                              return <CheckOutlined style={{ color: 'green' }} />;
                          } else {
                              return <CloseOutlined style={{ color: 'red' }} />;
                          }
                      },
                      ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
                  }
                : null,
            {
                dataIndex: 'actions',
                width: '100px',
                render: (text: any, record: any) => {
                    if (record) {
                        return (
                            <div style={{ textAlign: 'center' }}>
                                {record.fileId && (
                                    <Tooltip title={geti18nText('app.default.download')}>
                                        <CloudDownloadOutlined
                                            style={{ marginLeft: '10px', fontSize: '16px' }}
                                            onClick={() => {
                                                fileDownload(
                                                    CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.fileId,
                                                    null,
                                                    record.name
                                                );
                                            }}
                                        />
                                    </Tooltip>
                                )}
                                {/*   {checkIsFileImage(record) && (
                                <Tooltip title={geti18nText('app.default.preview')}>
                                    <EyeOutlined
                                        style={{ marginLeft: '10px', fontSize: '16px' }}
                                        onClick={() => {
                                            onPreview(record);
                                        }}
                                    />
                                </Tooltip>
                            )} */}
                            </div>
                        );
                    }
                },
            },
        ],
    ].filter((x) => x !== null);

    const onChangeTab = (key: any) => {
        setActiveKey(key);
    };

    const getFilesView = (canAddNewFile: any) => (
        <>
            {dataForm?.id && (
                <NyFilesUpload
                    id={dataForm?.id}
                    hasNoRefId
                    url={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.LIST_FILES.replace(
                        '{militaryWorkApprovalId}',
                        dataForm?.id
                    )}
                    manageUploadUrl={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.ADD_FILE.replace(
                        '{militaryWorkApprovalId}',
                        dataForm?.id
                    )}
                    manageDeleteUrl={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.DELETE_FILE}
                    maxFiles={1}
                    defaultFilesFilter={() => [
                        {
                            field: 'type',
                            condition: 'equals',
                            value: sentToMorhAction
                                ? militaryWorkApprovalFileType.EXPORT
                                : militaryWorkApprovalFileType.IMPORT,
                        },
                    ]}
                    morhType={
                        sentToMorhAction ? militaryWorkApprovalFileType.EXPORT : militaryWorkApprovalFileType.IMPORT
                    }
                    canAddNewFile={canAddNewFile}
                    canDelete={true}
                    canEdit={false}
                    ref={uploadFilesRef}
                    employee={employee}
                    maxHeight={'205px'}
                    showHeader={false}
                    isMobilization={true}
                    refresh={refreshFiles}
                />
            )}
        </>
    );

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.EDIT}
                setValues={setValues}
                width={1900}
                form={form}
                // setIsCreate={setIsCreate}
                goBack={() => history.goBack()}
                hideActivationButtons={true}
                hideSubmitButton={true}
                paramsId={id}
                {...props}
                shortcuts={true}
                onModalOpen={onModalOpen}
                checkIsFormChanged={false}
                customFooterContent={
                    dataForm && dataForm.status !== militaryWorkApprovalStatus.APPROVAL && activeKey !== '2'
                        ? getCustomFooterContent
                        : undefined
                }
            >
                <Tabs
                    type="card"
                    className="tabs-sticky"
                    activeKey={activeKey}
                    onChange={(key: any) => {
                        onChangeTab(key);
                        if (key == '2') {
                            setRefreshFiles((refresh: any) => refresh + 1);
                        }
                    }}
                >
                    <Tabs.TabPane tab={geti18nText('employee.table.header')} key="1">
                        {dataForm && dataForm.status !== militaryWorkApprovalStatus.APPROVAL && (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                                <Button
                                    onClick={() => downloadDocument(mobilizationReportType.EV_RO_2_2)}
                                    style={{ marginRight: 5 }}
                                >
                                    {geti18nText('schedule.suggestions.generate.document')}
                                </Button>
                                {dataForm.type === militaryWorkApprovalType.MORH &&
                                    dataForm.status !== militaryWorkApprovalStatus.SENT && (
                                        <Button
                                            type="primary"
                                            style={{ marginRight: 5 }}
                                            onClick={() => {
                                                setSentToMorhAction(true);
                                                setVisibleModalApproval(true);
                                            }}
                                        >
                                            {geti18nText('schedule.suggestions.sent.to.morh')}
                                        </Button>
                                    )}
                                <Button
                                    onClick={() => setVisibleModalApproval(true)}
                                    style={{ backgroundColor: 'green', color: 'white' }}
                                >
                                    {geti18nText('schedule.suggestions.edit.approval.of.the.proposal')}
                                </Button>
                            </div>
                        )}
                        {dataForm && (
                            <NyDataTable
                                nyId="human-military-work-approval-employees-in-approval"
                                rowKey="id"
                                url={CONSTANTS_REQ.MILITARY_WORK_APPROVAL.EMPLOYEES_IN_APPROVAL + '/' + dataForm.id}
                                showRecordModal={true}
                                hideNewButton={true}
                                setDefaultSortOrder={props?.editProps?.sortOrder ?? table.setDefaultSortOrder()}
                                scroll={props?.editProps?.scroll}
                                setDefaultFilterValue={setDefaultFilterValue}
                                fetchWhenChange={refreshTable}
                                showRowSelection={
                                    dataForm.status !== militaryWorkApprovalStatus.APPROVAL ? true : false
                                }
                                hideButtons
                                readonly={dataForm.status !== militaryWorkApprovalStatus.APPROVAL ? false : true}
                                setDefaultPageSize={table.setDefaultPageSize()}
                                rowSelectionType={'checkbox'}
                                rowSelectionModal={setSelectedValuesFuncDeleteGroup as any}
                                onRowSelect={onRowSelect}
                                columns={[
                                    ...[
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.bussiness.unit'
                                            ),
                                            width: '11%',
                                            dataIndex: [
                                                "concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))",
                                            ],
                                            render: (text: any, record: any) => {
                                                if (record.businessUnit) {
                                                    return (
                                                        record.businessUnit.name +
                                                        (record.businessUnit.code
                                                            ? ' (' + record.businessUnit.code + ')'
                                                            : '')
                                                    );
                                                }
                                            },
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearchByCodebookBusinessUnit(),
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.name.lastname'
                                            ),
                                            dataIndex:
                                                "concat(person.first_name, ' ', person.last_name, ' ', person.parent_name ) ",
                                            width: '10%',
                                            sorter: (a: any, b: any) => {},
                                            render: (text: any, record: any) => {
                                                if (record.person) {
                                                    return (
                                                        <div>
                                                            {(record.person.lastName
                                                                ? record.person.lastName + ', '
                                                                : '') +
                                                                (record?.person?.parentName
                                                                    ? record?.person?.parentName + ', '
                                                                    : record?.person?.firstName
                                                                    ? ' -, '
                                                                    : '') +
                                                                (record.person.firstName
                                                                    ? record.person.firstName
                                                                    : '')}
                                                        </div>
                                                    );
                                                }
                                            },
                                            ...getColumnSearchByCodebookEmployeeWithAlias('employeeId'),
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.work.place'
                                            ),
                                            width: '15%',
                                            dataIndex: ['workplaceDetails', 'id'],
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearchWorkplaceDetails(),
                                            render: (created: any, record: any) => {
                                                if (record.vocation) {
                                                    return (
                                                        record.vocation.name +
                                                        (record.vocationDescription
                                                            ? ' - ' + record.vocationDescription.name
                                                            : '')
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.birthdate'
                                            ),
                                            dataIndex: ['person', 'birthDate'],
                                            width: '8%',
                                            sorter: (a: any, b: any) => {},
                                            render: (created: any, record: any) => {
                                                if (record.person && record.person.birthDate) {
                                                    return getDateFormat(record.person.birthDate, 'DD.MM.YYYY');
                                                }
                                            },
                                            ...getColumnDateOption(false),
                                        },
                                        {
                                            title: geti18nText('schedule.suggestions.modal.employees.table.column.oib'),
                                            dataIndex: ['person', 'oib'],
                                            width: '3%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.qualifications'
                                            ),
                                            dataIndex: ['employeeProfessionalQualification', 'name'],
                                            width: '8%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearchByCodebookSimple(
                                                CONSTANTS_REQ.PROFESSIONAL_QUALIFICATION.SEARCH,
                                                'employeeVocationProfessionalQualification.id',
                                                'name',
                                                'name',
                                                {
                                                    id: 'id',
                                                    label: 'name',
                                                    text: 'name',
                                                    code: 'code',
                                                }
                                            ),
                                           render: (created: any, record: any) => {
                                                if (record.employee && record?.employee?.vocation?.professionalQualification) {
                                                    return record.employee.vocation.professionalQualification;
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.address'
                                            ),
                                            dataIndex: "concat(address.street, ' ', settlement.name ) ",
                                            sorter: (a: any, b: any) => {},
                                            render: (created: any, record: any) => {
                                                if (record.address) {
                                                    return (
                                                        <div>
                                                            {(record.address.street
                                                                ? record.address.street + ', '
                                                                : '') +
                                                                (record.address.settlement
                                                                    ? record.address.settlement.name
                                                                    : '')}
                                                        </div>
                                                    );
                                                }
                                            },
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.mobile'
                                            ),
                                            dataIndex: ['contact', 'mobile'],
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText(
                                                'schedule.suggestions.modal.employees.table.column.email'
                                            ),
                                            dataIndex: ['contact', 'email'],
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                    ],
                                    ...(typeMORH === true
                                        ? [
                                              {
                                                  title: geti18nText(
                                                      'schedule.suggestions.modal.employees.table.column.regional.department.defense'
                                                  ),
                                                  dataIndex: ['defenseDepartment', 'name'],
                                                  width: '12%',
                                                  sorter: (a: any, b: any) => {},
                                                  ...getColumnSearchByCodebookSimpleWithAddedData(
                                                      CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                                                      'defenseDepartment.id',
                                                      setDefaultFilterValueSearch(defenseDepartmentType.DEPARTMENT)
                                                  ),
                                              },
                                              {
                                                  title: geti18nText(
                                                      'schedule.suggestions.modal.employees.table.column.regional.subdivision.defense'
                                                  ),
                                                  dataIndex: ['defenseSection', 'name'],
                                                  width: '12%',
                                                  sorter: (a: any, b: any) => {},
                                                  ...getColumnSearchByCodebookSimpleWithAddedData(
                                                      CONSTANTS_REQ.DEFENSE_DEPARTMENT.SEARCH,
                                                      'defenseSection.id',
                                                      setDefaultFilterValueSearch(defenseDepartmentType.SECTION)
                                                  ),
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={geti18nText('app.default.files')} key="2">
                        {getFilesView(false)}
                    </Tabs.TabPane>
                </Tabs>
                <Modal
                    title={geti18nText('schedule.suggestions.modal.employees.btn.add.employee.to.suggestion')}
                    visible={isAddListModalVisibleGroup}
                    okText={geti18nText('app.default.button.add')}
                    //okButtonProps={{ disabled: !hasSelectedAddGroup }}
                    onOk={(e) => addEmployeeToSuggestion()}
                    confirmLoading={loadingModalGroup}
                    okButtonProps={{ htmlType: 'submit' }}
                    destroyOnClose={true}
                    width={1000}
                    onCancel={() => {
                        form.resetFields();
                        setEmployeeId(undefined);
                        setBusinessUnitAddToSuggest(undefined);
                        setWorkplaceAddToSuggest(undefined);
                        setIsAddListModalVisibleGroup(false);
                    }}
                >
                    {' '}
                    <>
                        <Row gutter={24}>
                            <Col span={16}>
                                <Form.Item
                                    label={geti18nText('schedule.suggestions.modal.add.employee')}
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    name="employee"
                                >
                                    {dataForm && (
                                        <NySearchField
                                            url={CONSTANTS_REQ.EMPLOYEE.MILITARY_WORK_APPROVAL}
                                            addedData={{ militaryWorkApprovalId: dataForm.id, type: dataForm.type }}
                                            map={{
                                                id: 'id',
                                                label: 'text',
                                                employmentRecordId: 'employmentRecordId',
                                                businessUnit: 'businessUnit',
                                            }}
                                            searchBy="person.first_name || ' ' || person.last_name"
                                            itemName={[
                                                ['person', 'firstName'],
                                                ['person', 'lastName'],
                                            ]}
                                            renderOption={customEmployeeRenderOption}
                                            customItemNameLabel={'firstName lastName'}
                                            style={{ width: '100%' }}
                                            onChange={(val: any) => onEmployeeChange(val)}
                                            order="person.last_name, person.first_name"
                                        />
                                    )}
                                </Form.Item>
                            </Col>

                            <Col span={16}>
                                <BusinessUnitSearch
                                    formItemName={'businessUnit'}
                                    onChange={onChangeBusinessUnitAddToSuggest}
                                />
                            </Col>

                            <Col span={16}>
                                <Form.Item
                                    label={geti18nText('schedule.suggestions.modal.employees.table.column.work.place')}
                                    name="workplaceDetailsToSuggest"
                                >
                                    <NySearchField
                                        disabled={!businessUnitAddToSuggest}
                                        url={CONSTANTS_REQ.WORKPLACE_DETAILS.SEARCH}
                                        map={{
                                            id: 'id',
                                            label: 'text',
                                            vocationName: 'vocation.name',
                                            vocationDescription: 'vocationDescription.name',
                                        }}
                                        searchBy="vocation.name"
                                        order={'vocation.name'}
                                        onChange={onChangeWorkplaceAddEmployee}
                                        setDefaultFilterValue={() => [
                                            {
                                                field: 'businessUnit.id',
                                                condition: 'equals',
                                                value: businessUnitAddToSuggest.id,
                                            },
                                        ]}
                                        renderOption={customRenderOptionWorkPlaceDetails}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={16}>
                                <Form.Item
                                    label={geti18nText('schedule.suggestions.modal.add.employee.note')}
                                    name="note"
                                >
                                    <Input></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                </Modal>
                <Modal
                    visible={rowModalVisible}
                    title={formSelectedEmployeeRowTitle()}
                    onOk={(event: any) => {
                        setRowModalVisible(false);
                        saveToEmployeeRow();
                    }}
                    onCancel={() => {
                        form.resetFields();
                        setBusinessUnit(undefined);
                        setWorkplace(undefined);
                        setRowModalVisible(false);
                    }}
                    destroyOnClose={true}
                    okText={geti18nText('app.default.button.save')}
                    width={1000}
                    forceRender={true}
                    closable={true}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <BusinessUnitSearch formItemName={'businessUnit'} onChange={onChangeBusinessUnit} />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('schedule.suggestions.modal.employees.table.column.work.place')}
                                name="workplaceDetails"
                            >
                                <NySearchField
                                    disabled={!businessUnit}
                                    url={CONSTANTS_REQ.WORKPLACE_DETAILS.SEARCH}
                                    map={{
                                        id: 'id',
                                        label: 'text',
                                        vocationName: 'vocation.name',
                                        vocationDescription: 'vocationDescription.name',
                                    }}
                                    searchBy="vocation.name"
                                    order={'vocation.name'}
                                    onChange={onChangeWorkplaceEditEmployee}
                                    setDefaultFilterValue={() => [
                                        { field: 'businessUnit.id', condition: 'equals', value: businessUnit?.id },
                                    ]}
                                    SearchPopupComponent={
                                        <WorkplaceDetailsIndex
                                            filter={{
                                                field: 'businessUnit.id',
                                                condition: 'equals',
                                                value: businessUnit?.id,
                                            }}
                                        />
                                    }
                                    renderOption={customRenderOptionWorkPlaceDetails}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('schedule.suggestions.modal.add.employee.note')} name="note">
                                <Input></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
            </NyDataEdit>

            <Modal
                visible={visibleModalApproval}
                title={
                    sentToMorhAction
                        ? geti18nText('schedule.suggestions.sent.to.morh')
                        : geti18nText('schedule.suggestions.edit.approval.header')
                }
                onOk={(event: any) => {
                    setVisibleModalApproval(false);
                    setActiveKeyModalApprove('1');
                    approvalForm.submit();
                }}
                destroyOnClose={true}
                onCancel={() => {
                    setVisibleModalApproval(false);
                    setSentToMorhAction(false);
                    setActiveKeyModalApprove('1');
                }}
                okText={
                    sentToMorhAction
                        ? geti18nText('schedule.suggestions.select.sent.to.morh.btn')
                        : geti18nText('app.default.button.approve')
                }
                width={1000}
                okButtonProps={{ disabled: activeKeyModalApprove === '2' }}
                forceRender={true}
                confirmLoading={confirmLoading}
                closable={true}
                maskClosable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Tabs
                    type="card"
                    className="tabs-sticky"
                    activeKey={activeKeyModalApprove}
                    onChange={(key: any) => {
                        setActiveKeyModalApprove(key);
                    }}
                    destroyInactiveTabPane={true}
                >
                    <Tabs.TabPane tab={geti18nText('schedule.suggestions.approve.modal.tab.basic')} key="1">
                        <Form
                            form={approvalForm}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={(val) => (sentToMorhAction ? sentToMorh(val) : approveAction(val))}
                        >
                            {dataForm && (
                                <Row gutter={24}>
                                    {(dataForm.type === militaryWorkApprovalType.MORH || sentToMorhAction) && (
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('schedule.suggestions.edit.approval.name')}
                                                name="objectName"
                                            >
                                                <Input ref={focusInput} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(dataForm.type === militaryWorkApprovalType.MORH || sentToMorhAction) && (
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('schedule.suggestions.edit.classification')}
                                                name="classification"
                                            >
                                                <Input ref={focusInput} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    <Col span={12}>
                                        <Form.Item
                                            label={
                                                dataForm.type === militaryWorkApprovalType.MORH
                                                    ? geti18nText('schedule.suggestions.edit.approval.registry.number')
                                                    : geti18nText(
                                                          'schedule.suggestions.edit.approval.registry.number.governor'
                                                      )
                                            }
                                            name="submissionNumber"
                                        >
                                            <Input ref={focusInput} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={
                                                dataForm.type === militaryWorkApprovalType.MORH
                                                    ? geti18nText(
                                                          'schedule.suggestions.edit.approval.date.document.input'
                                                      )
                                                    : geti18nText(
                                                          'schedule.suggestions.edit.approval.date.document.input.governor'
                                                      )
                                            }
                                            name="dateInput"
                                        >
                                            <NyDatePicker
                                                format="DD.MM.YYYY"
                                                style={{ width: '100%' }}
                                                mustGetPopupContainer={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {(dataForm.type === militaryWorkApprovalType.MORH || sentToMorhAction) && (
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText(
                                                    'schedule.suggestions.edit.approval.date.document.output'
                                                )}
                                                name="dateOutput"
                                            >
                                                <NyDatePicker
                                                    format="DD.MM.YYYY"
                                                    style={{ width: '100%' }}
                                                    mustGetPopupContainer={false}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                            )}
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={geti18nText('app.default.files')} key="2">
                        {/* <FileListMilitaryWork
                            isAdmin={isAdmin}
                            files={files}
                            setFiles={setFiles}
                            id={dataForm}
                            ref={fileUploadRef}
                            sentToMorh={sentToMorhAction}
                            saveAndDeleteOnUpload={true}
                            //setFileListRefresh={setRefreshFiles}
                            loading={fileListLoading}
                            setLoading={setFileListLoading}
                        /> */}
                        {getFilesView(true)}
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
        </>
    );
};

export default ScheduleSuggestionsEdit;
