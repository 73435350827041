import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import LocationIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import LocationEdit from './edit';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';

const LocationSearch = ({
    label = geti18nText('device.edit.location'),
    name = 'location',
    required = false,
    onChange,
    initialValue = undefined,
}: any) => {
    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeAndAttendanceCodebooks();
    };

    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
            initialValue={initialValue}
        >
            <NySearchField
                url={CONSTANTS_REQ.LOCATION.SEARCH}
                map={{ id: 'id', label: 'name' }}
                searchBy="name"
                itemName="name"
                SearchPopupComponent={<LocationIndex />}
                AddNewModalComponent={canCreate() ? LocationEdit : null}
                nyTestId="location"
                order="name"
                onChange={onChange}
                customListWidth={'1400px'}
                mustGetPopupContainer={false}
                defaultValue={initialValue}
            />
        </Form.Item>
    );
};

export default LocationSearch;
