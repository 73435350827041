import { NySession } from '@nybble/nyreact';
export const AdministrationRights = (function () {
    return {
        //general
        isAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN']);
        },
        isUser: () => {
            return NySession.hasAnyRole(['ROLE_USER']);
        },
        isAdminOrUser: () => {
            return AdministrationRights.isAdmin() || AdministrationRights.isUser();
        },
        hasRoleWorkLogin: () => {
            return NySession.hasAnyRole(['ROLE_WORK_LOGIN']);
        },
        //orderView
        canCreateOrderView: () => {
            return NySession.hasAnyRole(['ROLE_ORDER_VIEW_EDIT']);
        },
        //notifications
        canCreateNotifications: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_NOTIFICATIONS_EDIT']);
        },
        canCsvExportNotifications: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_NOTIFICATION_EXPORT']);
        },
        //settings
        canCreateSettings: () => {
            return NySession.hasAnyRole(['ROLE_APPLICATION_SETTINGS_EDIT']);
        },
        canCreateSettingsAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_APPLICATION_SETTINGS_EDIT', 'ROLE_APPLICATION_SETTINGS']);
        },
        canCreateApplicationSettings: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_APPLICATION_SETTINGS_EDIT', 'ROLE_APPLICATION_SETTINGS']);
        },
        canCreateCompanySettings: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_COMPANY_EDIT', 'ROLE_APPLICATION_SETTINGS']);
        },
        canCreateDashboardSettings: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_DASHBOARD_SETTINGS']);
        },
        canAccessSystemSettings: () => {
            return NySession.hasAnyRole(['ROLE_SYSTEM_SETTINGS']);
        },
        //codebooks
        canCreateAdministrationCodebooks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CODEBOOKS_EDIT']);
        },
        canCreateCompany: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CODEBOOKS_EDIT', 'ROLE_COMPANY_EDIT']);
        },
        canCreateAdministrationCodebooksAndAdmin: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES', 'ROLE_CODEBOOKS_EDIT']);
        },
        canCsvExportAdministrationCodebook: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CODEBOOKS_EXPORT']);
        },
        canCsvExportCompany: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CODEBOOKS_EXPORT', 'ROLE_COMPANY_EDIT']);
        },
        canCsvExportAdministrationCodebookSettlement: () => {
            return NySession.hasAnyRole([
                'ROLE_ADMIN',
                'ROLE_CODEBOOKS_EXPORT',
                'ROLE_CODEBOOKS_VIEW',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_MOBILIZATION',
                'ROLE_HR_CODEBOOKS_VIEW',
            ]);
        },
        canCsvExportOrderViewCodebook: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ORDER_VIEW_EXPORT']);
        },
        canCreateLinks: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_LINKS_EDIT']);
        },
        //accounting
        canCreateAccounting: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ACCOUNTING_CODEBOOKS_EDIT']);
        },
        canCsvExportAccounting: () => {
            return NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_ACCOUNTING_CODEBOOKS_EXPORT']);
        },
        ///////////////////
        //menu
        isAdminOrUserMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_USER'];
        },
        isAdminMenu: () => {
            return ['ROLE_ADMIN'];
        },
        canViewAccountingOverviewMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_ACCOUNTING_CODEBOOKS_MENU'];
        },
        canViewAccountingMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_ACCOUNTING_CODEBOOKS_MENU'];
        },
        canViewAdministrationHome: () => {
            return ['ROLE_ADMIN', 'ROLE_ADMINISTRATION_VIEW', 'ROLE_AUTH_USER'];
        },
        canViewAppSettingsMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_APPLICATION_SETTINGS_MENU', 'ROLE_APPLICATION_SETTINGS'];
        },
        canViewNotifSettingsMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_NOTIFICATION_SETTINGS_MENU'];
        },
        canViewAccessRightsMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_ACCESS_RIGHT_MENU'];
        },
        canViewOrderViewMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_ORDER_VIEW_MENU'];
        },
        canViewNotifMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_NOTIFICATION_VIEW', 'ROLE_NOTIFICATION_EDIT'];
        },
        canViewLogsMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_LOGS', 'ROLE_APPLICATION_SETTINGS'];
        },
        canViewSuperAdminMenu: () => {
            return ['SUPER_ADMIN'];
        },
        canViewCodebooksMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_CODEBOOKS_MENU'];
        },
        canViewCodebooksCompanyMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_CODEBOOKS_MENU', 'ROLE_COMPANY_EDIT'];
        },
        canViewSettlementMenu: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_CODEBOOKS_MENU',
                'ROLE_MOBILIZATION',
                'ROLE_HR_CODEBOOKS_MENU',
                'ROLE_HUMAN_RESOURCES',
            ];
        },
        canViewAdministrationOverview: () => {
            return ['ROLE_ADMIN', 'ROLE_ADMINISTRATION_MENU', 'ROLE_AUTH_USER'];
        },
        canViewDashboardMenu: () => {
            return ['ROLE_ADMIN', 'ROLE_DASHBOARD_MENU'];
        },
        canViewAuthUsersMenu: () => {
            return ['ROLE_AUTH_USER', 'ROLE_ADMIN'];
        },
        //routes
        canViewAccessRightsRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_ACCESS_RIGHT_VIEW'];
        },
        canViewAccessRightsCompanyRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_ACCESS_RIGHT_VIEW', 'ROLE_COMPANY_EDIT', 'ROLE_AUTH_USER'];
        },
        canViewCodebooksRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_CODEBOOKS_VIEW'];
        },
        canViewCodebooksCompanyRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_CODEBOOKS_VIEW', 'ROLE_COMPANY_EDIT'];
        },
        canViewOrderViewRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_ORDER_VIEW_VIEW'];
        },
        canViewAppSettingsRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_APPLICATION_SETTINGS_VIEW', 'ROLE_APPLICATION_SETTINGS'];
        },
        canViewSettlementRoutes: () => {
            return [
                'ROLE_ADMIN',
                'ROLE_CODEBOOKS_VIEW',
                'ROLE_HUMAN_RESOURCES',
                'ROLE_MOBILIZATION',
                'ROLE_HR_CODEBOOKS_VIEW',
            ];
        },
        canViewNotificationRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_NOTIFICATION_SETTINGS_VIEW'];
        },
        canViewDashboardRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_DASHBOARD_VIEW'];
        },
        canViewMyPortalRoutes: () => {
            return ['ROLE_ADMIN', 'ROLE_DASHBOARD_VIEW'];
        },
    };
})();
