import { NyDataEdit, geti18nText, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, Row, Select } from 'antd';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumArray } from '../../../../utils/Utils';
import PostOfficeIndex from '../../../administration/views/post-office';
import PostOfficeEdit from '../../../administration/views/post-office/edit';
import SettlementIndex from '../../../administration/views/settlement';
import SettlementEdit from '../../../administration/views/settlement/edit';
import NyLocationPicker from '../../../../components/location-picker';
import { LatLngLiteral } from 'leaflet';

const LocationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('location.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const [location, setLocation] = useState<LatLngLiteral>({ lat: 45.75219336063106, lng: 15.952148437500002 });

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployeeAndAttendanceCodebooks();
    };

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.hasOwnProperty('name')) {
            setEditHeader(geti18nText('location.edit.title') + ' - ' + dataForm.name);
        }
        if (dataForm.address && dataForm.address.postOffice) {
            dataForm.address.postOffice.text = [
                dataForm.address.postOffice.zipCode,
                dataForm.address.postOffice.name,
            ].join(' ');
        }

        if (dataForm?.lat || dataForm?.lng) {
            setLocation({ lat: dataForm?.lat ?? 0, lng: dataForm?.lng ?? 0 });
        }

        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const setLocationValues = (values: LatLngLiteral) => {
        form.setFieldsValue({ ...form.getFieldsValue(), ...values });
    };

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('location.edit.new'));
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.LOCATION.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={true}
            {...props}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('location.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input ref={focusInput} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('location.edit.street')}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                                whitespace: true,
                            },
                        ]}
                        name={['address', 'street']}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('location.edit.postoffice')}
                        name={['address', 'postOffice']}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.POST_OFFICE.SEARCH}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="name || ' ' || zipCode"
                            SearchPopupComponent={<PostOfficeIndex />}
                            AddNewModalComponent={PostOfficeEdit}
                            nyTestId="post-office"
                            // itemName={[['zipCode'], ['name']]}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('location.edit.settlement')}
                        name={['address', 'settlement']}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            url={CONSTANTS_REQ.SETTLEMENT.SEARCH}
                            map={{ id: 'id', label: 'name', text: 'text' }}
                            searchBy="name"
                            SearchPopupComponent={<SettlementIndex />}
                            AddNewModalComponent={SettlementEdit}
                            nyTestId="settlement"
                            customListWidth={'1200px'}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('location.edit.ipAddress')}
                        name="ipAddress"
                        rules={[
                            {
                                pattern: new RegExp(
                                    '^(([01]?[0-9]?[0-9]|2([0-4][0-9]|5[0-5])).){3}([01]?[0-9]?[0-9]|2([0-4][0-9]|5[0-5]))$'
                                ),
                                message: geti18nText('app.default.validation.ipAddress'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('location.edit.type')} name="type">
                        <Select options={getEnumArray('LOCATION_TYPE', 'label', 'value')} />
                    </Form.Item>
                </Col>
                <NyLocationPicker setLocation={setLocation} location={location} setLocationValues={setLocationValues} />
            </Row>
        </NyDataEdit>
    );
};

export default LocationEdit;
