import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Alert, Button, Col, Modal, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useEnum from '../../hooks/useEnum';
import useTableSettings from '../../hooks/useTableSettings';
import { TravelWarrantRights } from '../../rights/travelWarrantRights';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../utils/Constants';
import { getColumnSearchByCodebookCurrency } from '../../utils/Filters';
import { errorNotification, formatTime, getDateFormat, getEnumBooleanArray, numberFormat } from '../../utils/Utils';
import { wageAmountReceivedIcon, wageIcon, wageIconSmall } from '../travel-warrant-icons';
import TravelWarrantWageRecapitulationTable from '../travel-warrant-wage-recapitulation';
import TravelWarrantWageEdit from './edit';

const { Text } = Typography;

const TravelWarrantWageIndex = ({
    isAdmin = false,
    showAddedButtons = false,
    showWageRecapitulation = true,
    isWizard = false,
    id,
    travelWarrant,
    componentDaysRef,
    printPdf,
    travelWarrantType,
    wageAmountReceived,
    activeKey,
    disabled = false,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
}: any) => {
    const [refresh, setRefresh] = useState(0);
    const [travelWarrantHours, setTravelWarrantHours] = useState<any>(null);
    const { employee } = useSelector((state: RootState) => state.employee);
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const enTravelWageType = useEnum('TRAVEL_WAGE_TYPE');
    const paymentInDeclaredCurrency = useApplicationSetting('PAYMENT_IN_DECLARED_CURRENCY');
    const defaultCurrencyIsoCode = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const [wageID, setWageID] = useState<any>(null);
    const [useStandardWage, setUseStandardWage] = useState<boolean>(false);
    const [delStandardWage, setDelStandardWage] = useState<boolean>(false);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [travelWarrantWage, setTravelWarrantWage] = useState(null);

    const table = useTableSettings();

    const isNonStandardWage = (record: any) => {
        return record?.travelWage?.type !== enTravelWageType.NON_STANDARD;
    };

    const canCreate = () => {
        return (
            (isWizard && TravelWarrantRights.canCreateOnlyTravelWarrantPersonal()) ||
            (!isWizard && TravelWarrantRights.canOpenTravelWarrantWage(employee, notificationType))
        );
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'travelWarrant.id', condition: 'equals', value: id },
        ];
    };

    const canEditWage = () => {
        return isAdmin && !isWizard;
    };

    useEffect(() => {
        fetchTravelWarantHours();
    }, [refresh]);

    useEffect(() => {
        if (activeKey === 'wage') {
            setRefresh((refresh) => refresh + 1);
        }
    }, [activeKey]);

    // useEffect(() => {
    //     if (showWageRecapitulation && isWizard) {
    //         importTravelWarrantWageData();
    //     }
    // }, [id]);

    const fetchTravelWarantHours = () => {
        setTravelWarrantHours(null);
        if (id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.TRAVEL_WARRANT.GET_TRAVEL_WARRANT_HOURS + '/' + id).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            setTravelWarrantHours(result.data);
                        }
                    }
                }
            );
        }
    };

    const columns: any = [
        {
            title: geti18nText('travelWarrantWage.table.column.name'),
            dataIndex: ['travelWage', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.travelWage && record.travelWage.name) {
                    if (isNonStandardWage(record)) {
                        if (record.taxable) {
                            return (
                                <>
                                    {record.travelWage.name} {wageIconSmall}
                                </>
                            );
                        } else {
                            return record.travelWage.name;
                        }
                    } else {
                        if (record.taxable) {
                            return (
                                <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                    {record.travelWage.name} {wageIconSmall}
                                </span>
                            );
                        } else {
                            return (
                                <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                    {record.travelWage.name}
                                </span>
                            );
                        }
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.totalHours'),
            dataIndex: 'totalHours',
            sorter: (a: any, b: any) => {},
            //...getColumnSearch('number'),
            render: (totalHours: any) => {
                return <Text>{formatTime(totalHours ?? 0)}</Text>;
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.realHours'),
            dataIndex: 'realHours',
            sorter: (a: any, b: any) => {},
            //...getColumnSearch('number'),
            render: (realHours: any) => {
                return <Text>{formatTime(realHours ?? 0)}</Text>;
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.totalDays'),
            dataIndex: 'totalDays',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (totalDays: any) => {
                return <Text>{numberFormat(totalDays ?? 0)}</Text>;
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.amountInCurrency'),
            dataIndex: 'amountInCurrency',
            align: 'right',
            width: '128px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amountInCurrency: any) => {
                return <div style={{ float: 'right' }}>{numberFormat(amountInCurrency ?? 0)}</div>;
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.isoCode'),
            dataIndex: ['currency', 'isoCode'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookCurrency(),
            render: (text: any, recrod: any) => {
                if (recrod.currency) {
                    return recrod.currency.isoCode;
                }
            },
        },
        ...(paymentInDeclaredCurrency > 0
            ? [
                  {
                      title: geti18nText('travelWarrantWage.table.column.exchangeRate'),
                      dataIndex: 'exchangeRate',
                      align: 'right',
                      width: '92px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (exchangeRate: any) => {
                          return <Text style={{ float: 'right' }}>{numberFormat(exchangeRate ?? 0, 6)}</Text>;
                      },
                  },
                  {
                      title: geti18nText('travelWarrantWage.table.column.amount'),
                      dataIndex: 'amount',
                      align: 'right',
                      width: '92px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amount: any) => {
                          return <div style={{ float: 'right' }}>{numberFormat(amount ?? 0)}</div>;
                      },
                  },
              ]
            : []),
        {
            dataIndex: ['country', 'id'],
            width: '30px',
            render: (text: any, record: any) => {
                if (canCreate()) {
                    if (
                        record?.nonStandardWageNr > 0 &&
                        record?.travelWage?.type === enTravelWageType.STANDARD &&
                        record?.includeNonStandard == false
                    ) {
                        return (
                            <Button
                                onClick={() => {
                                    setWageID(record.id);
                                    setUseStandardWage(true);
                                }}
                                type="primary"
                                disabled={disabled}
                            >
                                {geti18nText('travelWarrantWage.table.column.nonStandardWageNr')}
                            </Button>
                        );
                    } else if (
                        record?.nonStandardWageNr > 0 &&
                        record?.travelWage?.type === enTravelWageType.STANDARD &&
                        record?.includeNonStandard == true
                    ) {
                        return (
                            <Button
                                onClick={() => {
                                    setWageID(record.id);
                                    setDelStandardWage(true);
                                }}
                                type="primary"
                                danger
                                disabled={disabled}
                            >
                                {geti18nText('travelWarrantWage.table.column.delNonStandardWageNr')}
                            </Button>
                        );
                    }
                }
            },
        },
    ];

    const adminColumns = [
        {
            title: geti18nText('travelWarrantWage.table.column.id'),
            dataIndex: 'id',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('travelWarrantWage.table.column.name'),
            dataIndex: ['travelWage', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.travelWage && record.travelWage.name) {
                    if (isNonStandardWage(record)) {
                        if (record.taxable) {
                            return (
                                <>
                                    {record.travelWage.name} {wageIconSmall}
                                </>
                            );
                        } else {
                            return record.travelWage.name;
                        }
                    } else {
                        if (record.taxable) {
                            return (
                                <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                    {record.travelWage.name} {wageIconSmall}
                                </span>
                            );
                        } else {
                            return (
                                <span style={{ color: isDarkTheme ? '#7ce7ac' : '#23c76c' }}>
                                    {record.travelWage.name}
                                </span>
                            );
                        }
                    }
                }
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.startDate'),
            dataIndex: 'startDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (startDate: any, record: any) => {
                if (record.startDate) {
                    return getDateFormat(record.startDate, 'DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.endDate'),
            dataIndex: 'endDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(false),
            render: (endDate: any, record: any) => {
                if (record.endDate) {
                    return getDateFormat(record.endDate, 'DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.totalHours'),
            dataIndex: 'totalHours',
            sorter: (a: any, b: any) => {},
            // ...getColumnSearch('number'),
            render: (totalHours: any) => {
                return <Text>{formatTime(totalHours ?? 0)}</Text>;
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.totalDays'),
            dataIndex: 'totalDays',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (totalDays: any) => {
                return <Text>{numberFormat(totalDays ?? 0)}</Text>;
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.amountInCurrency'),
            dataIndex: 'amountInCurrency',
            align: 'right',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
            render: (amountInCurrency: any) => {
                return <div style={{ float: 'right' }}>{numberFormat(amountInCurrency ?? 0)}</div>;
            },
        },
        {
            title: geti18nText('travelWarrantWage.table.column.isoCode'),
            dataIndex: ['currency', 'isoCode'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, recrod: any) => {
                if (recrod.currency) {
                    return recrod.currency.isoCode;
                }
            },
        },
        ...(paymentInDeclaredCurrency > 0
            ? [
                  {
                      title: geti18nText('travelWarrantWage.table.column.exchangeRate'),
                      dataIndex: 'exchangeRate',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (exchangeRate: any) => {
                          return <Text>{numberFormat(exchangeRate ?? 0, 6)}</Text>;
                      },
                  },
                  {
                      title: geti18nText('travelWarrantWage.table.column.amount'),
                      dataIndex: 'amount',
                      align: 'right',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('number'),
                      render: (amount: any) => {
                          return <div style={{ float: 'right' }}>{numberFormat(amount ?? 0)}</div>;
                      },
                  },
              ]
            : []),

        {
            title: geti18nText('travelWarrantWage.table.column.taxable'),
            dataIndex: 'taxable',
            render: (text: any, record: { taxable: any }) => {
                if (record.taxable) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
        },
        {
            title: geti18nText('travelWarrantWage.table.column.active'),
            dataIndex: 'active',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
            defaultFilteredValue: setDefaultFilterValue(),
        },
        {
            dataIndex: ['country', 'id'],
            width: '30px',
            render: (text: any, record: any) => {
                if (canCreate()) {
                    if (
                        record?.nonStandardWageNr > 0 &&
                        record?.travelWage?.type === enTravelWageType.STANDARD &&
                        record?.includeNonStandard == false
                    ) {
                        return (
                            <Button
                                onClick={() => {
                                    setWageID(record.id);
                                    setUseStandardWage(true);
                                }}
                                type="primary"
                                disabled={disabled}
                            >
                                {geti18nText('travelWarrantWage.table.column.nonStandardWageNr')}
                            </Button>
                        );
                    } else if (
                        record?.nonStandardWageNr > 0 &&
                        record?.travelWage?.type === enTravelWageType.STANDARD &&
                        record?.includeNonStandard == true
                    ) {
                        return (
                            <Button
                                onClick={() => {
                                    setWageID(record.id);
                                    setDelStandardWage(true);
                                }}
                                type="primary"
                                danger
                                disabled={disabled}
                            >
                                {geti18nText('travelWarrantWage.table.column.delNonStandardWageNr')}
                            </Button>
                        );
                    }
                }
            },
        },
    ];

    const importTravelWarrantWageData = () => {
        if (id) {
            NyRequestResolver.requestPost(CONSTANTS_REQ.TRAVEL_WARRANT.GENERATE_TRAVEL_WARRANT_WAGE, undefined, {
                id: id,
            }).then((result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    setRefresh((refresh) => refresh + 1);
                } else {
                    errorNotification(geti18nText(result.data.error));
                }
            });
        }
    };

    const addedButtons = () => {
        return (
            <Button onClick={importTravelWarrantWageData} type="primary" style={{ float: 'left' }} disabled={disabled}>
                {geti18nText('travelWarrantPlan.edit.import.wage')}
            </Button>
        );
    };

    const addNonStandardWage = () => {
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.TRAVEL_WARRANT_WAGE.SET_INCLUDE_NON_STANDARD + '/' + wageID,
            undefined,
            {
                id: wageID,
                includeNonStandard: true,
            }
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setRefresh((refresh) => refresh + 1);
            } else {
                errorNotification(geti18nText(result.data.error));
            }
            setUseStandardWage(false);
        });
    };

    const delNonStandardWage = () => {
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.TRAVEL_WARRANT_WAGE.SET_INCLUDE_NON_STANDARD + '/' + wageID,
            undefined,
            {
                id: wageID,
                includeNonStandard: false,
            }
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setRefresh((refresh) => refresh + 1);
            } else {
                errorNotification(geti18nText(result.data.error));
            }
            setDelStandardWage(false);
        });
    };

    return (
        <>
            {travelWarrantType?.wageType !== enTravelWageType.PER_DIEM && (
                <Alert
                    message={geti18nText('travelWarrantWage.table.info.wage', [
                        formatTime(travelWarrantHours?.totalHours ?? 0),
                        travelWarrantHours?.totalWageDays ?? 0,
                    ])}
                    type="info"
                    showIcon
                    style={{
                        margin: '5px',
                        marginBottom:
                            travelWarrantType?.taxableWage || travelWarrantType?.payoutByAnotherOrganization
                                ? '12px'
                                : '32px',
                    }}
                />
            )}
            <div style={{ marginBottom: '-24px' }}>
                {wageIcon}
                <b>{geti18nText('travelWarrantRecapitulation.table.taxable.wage').split('**')}</b>
                {travelWarrantType?.payoutByAnotherOrganization && (
                    <b style={{ marginLeft: '2em' }}>
                        {wageAmountReceivedIcon}
                        {geti18nText('travelWarrantRecapitulation.table.wage.amount.received', [
                            numberFormat(travelWarrant?.wageAmountReceived),
                            defaultCurrencyIsoCode,
                        ]).split('***')}
                    </b>
                )}
            </div>
            <NyDataTable
                nyId="travel-warrent-wage"
                url={CONSTANTS_REQ.TRAVEL_WARRANT_WAGE.LIST}
                addNewButtonText={geti18nText('travelWarrantWage.table.add')}
                buttonsClassName={'buttons-sticky'}
                showRecordModal={true}
                fetchWhenChange={refresh}
                setDefaultSortOrder={sortOrder ?? { order: 'id', orderType: 'asc' }}
                scroll={scroll}
                setDefaultPageSize={table.setDefaultPageSize()}
                onRowSelect={(record: any) => {
                    if (
                        record?.travelWage?.type !== enTravelWageType.NON_STANDARD &&
                        !isWizard &&
                        TravelWarrantRights.canOpenTravelWarrantWage(employee, notificationType)
                    ) {
                        setTravelWarrantWage(record);
                        setEditModalVisible(true);
                    }
                }}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={isAdmin ? adminColumns : columns}
                hideNewButton
                shortcuts={true}
                nyTestId="travel-wage-table"
                addedButtons={showAddedButtons && showWageRecapitulation ? addedButtons : undefined}
                onSaveAndGetID={() => setRefresh((refresh) => refresh + 1)}
            />
            {editModalVisible && (
                <TravelWarrantWageEdit
                    editProps={{
                        value: travelWarrantWage,
                        visible: editModalVisible,
                        setVisible: setEditModalVisible,
                        refresh: refresh,
                        setRefresh: setRefresh,
                        isAdmin: isAdmin,
                        isWizard: isWizard,
                        id: id,
                        employeeId: travelWarrant?.employee?.id,
                        disabled: disabled,
                    }}
                />
            )}

            {showWageRecapitulation && (
                <>
                    <div ref={componentDaysRef}>
                        <TravelWarrantWageRecapitulationTable
                            travelWarrant={travelWarrant}
                            id={id}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            printPdf={printPdf}
                            canEditWage={canEditWage()}
                            isWizard={isWizard}
                            disabled={disabled}
                        />
                    </div>
                </>
            )}
            {useStandardWage && (
                <Modal
                    visible={useStandardWage}
                    okText={geti18nText('app.default.button.yes')}
                    cancelText={geti18nText('app.default.button.no')}
                    title={geti18nText('travelWarrantWage.useStandardWage')}
                    onOk={() => addNonStandardWage()}
                    onCancel={() => {
                        setUseStandardWage(false);
                    }}
                    width={600}
                    maskClosable={false}
                    closable={false}
                    className="borderless"
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Alert
                                message={geti18nText('travelWarrantWage.useStandardWage.info')}
                                type="info"
                                showIcon
                            />
                        </Col>
                    </Row>
                </Modal>
            )}
            {delStandardWage && (
                <Modal
                    visible={delStandardWage}
                    okText={geti18nText('app.default.button.yes')}
                    cancelText={geti18nText('app.default.button.no')}
                    title={geti18nText('travelWarrantWage.deleteStandardWage')}
                    onOk={() => delNonStandardWage()}
                    onCancel={() => {
                        setDelStandardWage(false);
                    }}
                    width={600}
                    maskClosable={false}
                    closable={false}
                    className="borderless"
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Alert
                                message={geti18nText('travelWarrantWage.deleteStandardWage.info')}
                                type="info"
                                showIcon
                            />
                        </Col>
                    </Row>
                </Modal>
            )}
        </>
    );
};

export default TravelWarrantWageIndex;
