import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { NySession, geti18nText, NyIsDirtyModal } from '@nybble/nyreact';
import { Tabs } from 'antd';
import React, { useState } from 'react';
import useHelper from '../../../../hooks/useHelper';
import { TravelWarrantRights } from '../../../../rights/travelWarrantRights';
import ApplicationSettingsTable from './ApplicationSetingsTable';
import AssetSettingsIndex from './AssetSettingsIndex';
import CompanyInformationIndex from './CompanyInformationIndex';
import EmailSettingsIndex from './EmailSettingsIndex';
import EmployeeLeaveSettingsIndex from './EmployeeLeaveSettingsIndex';
import EmployeeSettingsIndex from './EmployeeSettingsIndex';
import PaymentOrderIndex from './PaymentOrderIndex';
import PayrollIndex from './PayrollIndex';
import PrintSettingsIndex from './PrintSettingsIndex';
import SystemSettingsTable from './SystemSettingsTable';
import TravelWarrantSettingsIndex from './TravelWarrantSettingsIndex';
import { AdministrationRights } from '../../../../rights/administrationRights';

const { TabPane } = Tabs;

const ApplicationSettingsIndex = () => {
    useHelper('common/app_settings');

    const [dirty, setDirty] = useState<any>({
        companyInformation: false,
        employeeSettings: false,
        payroll: false,
        emailSettings: false,
        asset: false,
        travelWarrantSettings: false,
        printSettings: false,
        employeeLeaveSettings: false,
        systemSettings: false,
    });

    const [showDirtyDataModal, setShowDirtyDataModal] = useState(false);
    const [activeTab, setActiveTab] = useState('companyInformation');
    const [nexActiveTab, setNextActiveTab] = useState<any>(undefined);

    const canCreate = () => {
        return AdministrationRights.canCreateSettingsAdmin();
    };
    const canAccessSystemSettings = () => {
        return AdministrationRights.canAccessSystemSettings();
    };

    const onTabChange = (activeKey: any) => {
        setNextActiveTab(activeKey);
        if (dirty[activeTab]) {
            setShowDirtyDataModal(true);
        } else {
            setActiveTab(activeKey);
        }
    };

    const onContinue = () => {
        setShowDirtyDataModal(false);
        let tmpDirty = { ...dirty };
        tmpDirty[activeTab] = false;
        setDirty(tmpDirty);
        dirty[activeTab] = false;
        if (nexActiveTab) {
            setActiveTab(nexActiveTab);
        }
    };

    return (
        <React.Fragment>
            <Tabs
                type="card"
                className="tabs-sticky"
                onChange={onTabChange}
                activeKey={activeTab}
                style={{ maxHeight: '80vh' }}
            >
                <TabPane
                    tab={
                        <span>
                            {geti18nText('app.setting.tab.companyInformation')}
                            {dirty['companyInformation'] ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="companyInformation"
                >
                    <CompanyInformationIndex setDirty={setDirty} dirty={dirty} canCreate={canCreate} />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            {geti18nText('app.setting.tab.employeeSettings')}
                            {dirty['employeeSettings'] ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="employeeSettings"
                >
                    <EmployeeSettingsIndex setDirty={setDirty} dirty={dirty} canCreate={canCreate} />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            {geti18nText('app.setting.tab.payroll')}
                            {dirty['payroll'] ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="payroll"
                >
                    <PayrollIndex setDirty={setDirty} dirty={dirty} canCreate={canCreate} />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            {geti18nText('app.setting.tab.paymentOrder')}
                            {dirty['paymentOrder'] ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="paymentOrder"
                >
                    <PaymentOrderIndex setDirty={setDirty} dirty={dirty} canCreate={canCreate} />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            {geti18nText('app.setting.tab.asset')}
                            {dirty['asset'] ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="asset"
                >
                    <AssetSettingsIndex setDirty={setDirty} dirty={dirty} canCreate={canCreate} />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            {geti18nText('app.setting.tab.travelWarrant')}
                            {dirty['travelWarrantSettings'] ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="travelWarrantSettings"
                >
                    <TravelWarrantSettingsIndex
                        setDirty={setDirty}
                        dirty={dirty}
                        canCreate={TravelWarrantRights.canCreateAppSettings()}
                    />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            {geti18nText('app.setting.tab.employeeLeave')}
                            {dirty['employeeLeaveSettings'] ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="employeeLeaveSettings"
                >
                    <EmployeeLeaveSettingsIndex setDirty={setDirty} dirty={dirty} canCreate={canCreate} />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            {geti18nText('app.setting.tab.printSettings')}
                            {dirty['printSettings'] ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="printSettings"
                >
                    <PrintSettingsIndex canCreate={canCreate} />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            {geti18nText('app.setting.tab.email')}
                            {dirty['emailSettings'] ? (
                                <ExclamationCircleTwoTone
                                    twoToneColor="#faad14"
                                    style={{ paddingLeft: '5px', marginRight: '0px' }}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    }
                    key="emailSettings"
                >
                    <EmailSettingsIndex setDirty={setDirty} dirty={dirty} canCreate={canCreate} />
                </TabPane>
                {canAccessSystemSettings() ? (
                    <TabPane tab={geti18nText('app.setting.tab.systemSettings')} key="systemSettings">
                        <SystemSettingsTable setDirty={setDirty} dirty={dirty} canCreate={canCreate} />
                    </TabPane>
                ) : (
                    ''
                )}
                <TabPane tab={geti18nText('app.setting.tab.applicationSettings')} key="applicationSettings">
                    <ApplicationSettingsTable />
                </TabPane>
            </Tabs>
            <NyIsDirtyModal
                visible={showDirtyDataModal}
                onContinue={onContinue}
                onCancel={() => setShowDirtyDataModal(false)}
            />
        </React.Fragment>
    );
};

export default ApplicationSettingsIndex;
