import { CloudDownloadOutlined, DeleteOutlined, EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { NySpinner, geti18nText } from '@nybble/nyreact';
import { Button, Image, Tooltip, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CONSTANTS_REQ } from '../Constants';
import { fileDownload, warningNotification } from '../Utils';
import { deleteFiles, uploadImage } from '../ny-file-upload-custom/FilesFunctions';
import HistoryIndex from './HistoryIndex';
import FileEdit from './edit';
import { INyImageUpload } from './types';

const NyImageUpload = forwardRef(
    (
        {
            files,
            setFiles,
            documentType,
            saveOnUpload = false,
            maxImage = 1,
            cropShape = 'rect',
            listType = 'picture-card',
            accept = 'image/*',
            disabled = false,
            width = 200,
            customAction,
            allowedFileSize,
            downloadUrl = CONSTANTS_REQ.FILES.DOWNLOAD,
            uploadUrl = CONSTANTS_REQ.FILES.UPLOAD,
            manageUploadUrl = CONSTANTS_REQ.FILES.MANAGE_UPLOAD,
            deleteUrl = CONSTANTS_REQ.FILES.DELETE_BY_IDS,
            manageDeleteUrl = CONSTANTS_REQ.FILES.MANAGE_DELETE,
            showPreviev = true,
            showHistoryId = null,
            isContractTypeView = false,
            canEditFile = false,
            setNewTemplate,
            canDeleteFile = true,
            canUpload = true,
            showRemoveIcon = true,
        }: INyImageUpload,
        ref
    ) => {
        const [previewVisible, setPreviewVisible] = useState(false);
        const [previewImage, setPreviewImage] = useState<any>(undefined);
        const [deleteFileList, setDeleteFileList] = useState<any>([]);
        const [loading, setLoading] = useState(false);
        //edit file
        const [selectedFile, setSelectedFile] = useState<any>(null);
        const [editModalVisible, setEditModalVisible] = useState<any>(false);

        useImperativeHandle(ref, () => ({
            filesUpload(returnedId: any) {
                if (files?.length >= 1) {
                    uploadFile(returnedId);
                }
                if (deleteFileList?.length >= 1) {
                    deleteFile(returnedId);
                }
            },
        }));

        useEffect(() => {
            if (saveOnUpload && files && files?.length >= 1 && !files[0].id) {
                uploadFile(null);
            }
        }, [files]);

        useEffect(() => {
            if (saveOnUpload && deleteFileList?.length >= 1) {
                deleteFile(null);
            }
        }, [deleteFileList]);

        const callCustomAction = (value: any = null) => {
            if (customAction) {
                customAction(value);
            }
        };

        const uploadFile = async (id: any) => {
            setLoading(true);
            const result = await uploadImage(
                id,
                files,
                documentType,
                uploadUrl,
                manageUploadUrl,
                downloadUrl,
                deleteUrl,
                manageDeleteUrl
            ).then((res: any) => {
                console.log('res', res);
                if (res) {
                    const timeout = setTimeout(() => {
                        setFiles(res);
                        callCustomAction(res[0].id);
                    }, 1000);
                }
            });
            setLoading(false);
        };

        const deleteFile = (id: any) => {
            setLoading(true);
            deleteFiles(deleteFileList, id, documentType, deleteUrl, manageDeleteUrl);
            setLoading(false);
            callCustomAction();
        };

        const deleteAction = (file: any) => {
            if (file) {
                setFiles((files: any) => {
                    const index = files.indexOf(file);
                    const newFileList = files.slice();
                    newFileList.splice(index, 1);

                    return newFileList;
                });
                if (file.id) {
                    setDeleteFileList(() => {
                        const deleteFilesListTemp = [...deleteFileList];
                        deleteFilesListTemp.push(file.id);

                        return deleteFilesListTemp;
                    });
                }
                if (setNewTemplate) {
                    setNewTemplate(true);
                }
            }
        };

        const uploadProps = {
            multiple: true,
            onRemove: (file: any) => {
                deleteAction(file);
            },
            beforeUpload: (file: any) => {
                if (allowedFileSize && file && file.size > allowedFileSize) {
                    warningNotification(geti18nText('upload.file.size.too.big'));
                    return false;
                } else {
                    setFiles([...files, file]);
                    return true;
                }
            },
            files,
            accept,
            showUploadList:
                listType == 'text'
                    ? {}
                    : {
                          showRemoveIcon: showRemoveIcon,
                          removeIcon: <DeleteOutlined style={{ color: 'red', fontSize: '22px' }} />,
                      },
            fileList: files,
            listType: listType,
            disabled: disabled,
        };

        const onPreview = async (file: any) => {
            setPreviewImage(file.url);
            setPreviewVisible(true);
        };

        const getAddNewLabel = () => {
            return isContractTypeView ? geti18nText('contract.type.add.file') : geti18nText('webshopServices.addFile');
        };

        const uploadButton =
            listType == 'text'
                ? canUpload && (
                      <Button type="primary">
                          <UploadOutlined />
                          {getAddNewLabel()}
                      </Button>
                  )
                : canUpload && (
                      <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>{getAddNewLabel()}</div>
                      </div>
                  );

        const getActions = (file: any) => (
            <>
                {(file?.id || file?.fileId) && (
                    <div style={{ float: 'right', fontSize: '22px', cursor: 'pointer' }}>
                        {showHistoryId && <HistoryIndex contractTypeId={showHistoryId} />}
                        <Tooltip title={geti18nText('app.default.download')}>
                            <CloudDownloadOutlined
                                style={{ color: 'green', marginRight: '10px', fontSize: '24px' }}
                                onClick={() => {
                                    fileDownload(
                                        CONSTANTS_REQ.FILES.DOWNLOAD + '/' + (file?.id ?? file?.fileId),
                                        null,
                                        file.name
                                    );
                                }}
                            />
                        </Tooltip>
                        {canEditFile && (
                            <Tooltip title={geti18nText('app.default.button.edit')}>
                                <EditOutlined
                                    style={{ fontSize: '22px', marginRight: '10px' }}
                                    onClick={() => {
                                        setSelectedFile(file);
                                        setEditModalVisible(true);
                                    }}
                                />
                            </Tooltip>
                        )}
                        {canDeleteFile && (
                            <Tooltip title={geti18nText('app.default.button.delete')}>
                                <DeleteOutlined
                                    style={{ color: 'red', fontSize: '22px' }}
                                    onClick={() => deleteAction(file)}
                                />
                            </Tooltip>
                        )}
                    </div>
                )}
            </>
        );

        const getUpload = () => (
            <>
                {listType == 'text' ? (
                    <Upload
                        {...uploadProps}
                        onPreview={showPreviev ? onPreview : undefined}
                        itemRender={(originNode, file) => (
                            <div style={{ width: '100%', display: 'inline-block' }}>
                                {file.name}
                                {getActions(file)}
                            </div>
                        )}
                    >
                        {files && files.length >= maxImage ? null : uploadButton}
                    </Upload>
                ) : (
                    <Upload {...uploadProps} onPreview={showPreviev ? onPreview : undefined}>
                        {files && files.length >= maxImage ? null : uploadButton}
                    </Upload>
                )}
            </>
        );

        return (
            <>
                {loading ? (
                    <NySpinner />
                ) : (
                    <>
                        <div style={{ paddingTop: '10px' }}>
                            {cropShape ? (
                                <ImgCrop
                                    rotate={true}
                                    shape={cropShape}
                                    modalTitle={geti18nText('upload.crop')}
                                    modalCancel={geti18nText('app.default.button.cancel')}
                                    modalOk={geti18nText('app.default.button.save')}
                                    quality={0.7}
                                >
                                    {getUpload()}
                                </ImgCrop>
                            ) : (
                                getUpload()
                            )}
                        </div>
                        <Image
                            width={width}
                            style={{ display: 'none' }}
                            preview={{
                                visible: previewVisible,
                                src: previewImage,
                                onVisibleChange: (value) => {
                                    setPreviewVisible(value);
                                },
                            }}
                        />
                        {editModalVisible && canEditFile && (
                            <FileEdit
                                visible={editModalVisible}
                                setVisible={setEditModalVisible}
                                file={selectedFile}
                                setFile={setSelectedFile}
                                files={files}
                                setFiles={setFiles}
                                accept={accept}
                                allowedFileSize={allowedFileSize}
                                uploadFile={uploadFile}
                                setNewTemplate={setNewTemplate}
                            />
                        )}
                    </>
                )}
            </>
        );
    }
);

export default NyImageUpload;
