import { NyDataTable, NyRequestResolver, NySpinner, RESPONSE, getColumnSearch, geti18nText } from '@nybble/nyreact';
import { useState, useEffect } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { DecompositionTreeGraph, IGraph } from '@ant-design/graphs';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { Button, Col, Form, Modal, Result, Row, Segmented, Slider, Switch, Tooltip } from 'antd';
import {
    DownloadOutlined,
    FileOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
    MinusOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { BusinessUnitSearch } from '../../views/human/views/business-unit/search';
import useTableSettings from '../../hooks/useTableSettings';
import { getColumnSearchByCodebookSimple } from '../../utils/Filters';

const BusinessUnitsGraph = () => {
    const companyName = useApplicationSetting('COMPANY_NAME');
    const companyAddress = useApplicationSetting('COMPANY_ADDRESS');
    const [allBusinessUnits, setAllBusinessUnits] = useState<any[]>([]);
    const [graphData, setGraphData] = useState<any>([]);
    const [graphDataInit, setGraphDataInit] = useState<any>([]);
    const [selectedItemID, setSelectedItemID] = useState<any>(null);
    const [selectedTopParentID, setSelectedTopParentID] = useState<any>(null);
    const [segmentedValue, setSegmentedValue] = useState<any>(geti18nText('businessUnit.graph.button.vocations'));
    const [modalVisible, setModalVisible] = useState<any>(false);
    const [loading, setLoading] = useState<any>(true);
    const [showEmployee, setShowEmployee] = useState<any>(true);
    const [title, setTitle] = useState<any>(null);
    const [level, setLevel] = useState<any>(2);
    const [shadowID, setShadowID] = useState<any>(2);
    const [showModalEmployees, setShowModalEmployees] = useState<any>(false);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme === 'dark';
    const table = useTableSettings();
    const [graphRefresh, setGraphRefresh] = useState(0);
    const [error, setError] = useState(false);
    const levelMarks = {
        1: geti18nText('businessUnit.graph.sector'),
        2: geti18nText('businessUnit.graph.directorate'),
        3: geti18nText('businessUnit.graph.department'),
    };

    const [businessUnitForm] = Form.useForm();

    useEffect(() => {
        if (allBusinessUnits.length) {
            setGraphDataInit({
                id: 'company',
                value: {
                    title: companyName,
                    items: {
                        text: companyAddress,
                    },
                },
                children: getChildren(allBusinessUnits),
            });
        }
    }, [allBusinessUnits]);

    useEffect(() => {
        getAllBusinessUnits();
    }, [selectedTopParentID, shadowID, showEmployee]);

    const getAllBusinessUnits = () => {
        if (selectedTopParentID) {
            const setDefaultFilterValue = () => {
                return [
                    { field: 'active', condition: 'equals_bool', value: 1 },
                    { field: 'id', condition: 'equals', value: selectedTopParentID },
                ];
            };

            const stringUri = encodeURI(JSON.stringify(setDefaultFilterValue()));

            NyRequestResolver.requestGet(CONSTANTS_REQ.BUSINESS_UNIT.LIST_ALL_CHART, { search: stringUri }).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            setGraphData(getBusinessUnit(getChildren(result.data), shadowID));
                        }
                    }
                    setLoading(false);
                }
            );
            // setLevel(3);
        } else {
            const setDefaultFilterValue = () => {
                return [{ field: 'active', condition: 'equals_bool', value: 1 }];
            };

            const stringUri = encodeURI(JSON.stringify(setDefaultFilterValue()));

            NyRequestResolver.requestGet(CONSTANTS_REQ.BUSINESS_UNIT.LIST_ALL_CHART, { search: stringUri }).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data) {
                            setAllBusinessUnits([...result.data]);
                        }
                        setError(false);
                    } else {
                        setError(true);
                    }
                    setLoading(false);
                }
            );
            //  setLevel(3);
        }
    };

    const getBusinessUnit = (graph: any[], id: number): any => {
        return graph
            .map((businessUnit) => {
                if (businessUnit.id == id) {
                    return businessUnit;
                }
                if (!businessUnit.children.length) {
                    return null;
                }
                return getBusinessUnit(businessUnit.children, id);
            })
            .filter(Boolean)[0];
    };

    const getChildren = (data: any) => {
        let tempChildren = data.map((child: any) => {
            let employmentRecordId = child?.employee?.employmentRecordId
                ? ' (' + child?.employee?.employmentRecordId + ')'
                : '';
            let item = {
                id: child?.id.toString(),
                name: child?.name.toString(),
                code: child?.code?.toString(),
                employee:
                    child?.employee?.person?.firstName &&
                    child?.employee?.person?.firstName + ' ' + child?.employee?.person?.lastName + employmentRecordId,
            };

            return {
                id: item.id,
                value: {
                    title: item.code ? '[' + item.code + '] ' + item.name : item.name,
                    items: [
                        {
                            text: showEmployee
                                ? item.employee && geti18nText('businessUnit.edit.employee') + ': ' + item.employee
                                : null,
                        },
                    ],
                },
                children: getChildren(child?.children?.length > 0 ? child.children : []),
                style: {
                    shadowColor: '#40a9ff',
                    // shadowBlur: item.id == shadowID ? 30 : 0,
                },
            };
        });
        return tempChildren;
    };

    const config = {
        onReady: (graph: any) => {
            graph.on('node:dblclick', (evt: any) => {
                setSelectedItemID(Number.parseInt(evt?.item?._cfg?.id));
                setTitle(evt?.item?._cfg?.model?.value?.title);
                setModalVisible(true);
            });
        },
        theme: 'default',
        data: selectedTopParentID ? graphData : graphDataInit,
        markerCfg: (cfg: any) => {
            const { level, direction } = cfg.value;
            const show = level !== 1 && cfg.children && cfg.children.length > 0;
            return {
                position: direction,
                show,
            };
        },
        nodeCfg: {
            autoWidth: true,
            items: {
                layout: 'follow',
            },
            style: {
                cursor: 'pointer',
                fill: isDarkTheme ? '#204473' : '#b2dcff',
            },
            title: {
                cursor: 'pointer',
                fill: '#111',
            },
            label: {
                style: {
                    cursor: 'pointer',
                    fill: isDarkTheme ? '#aaa' : '#000',
                },
            },
        },
        edgeCfg: {
            endArrow: {
                d: 5,
            },
        },
        level: level,
        toolbarCfg: {
            show: true,
            customContent: ({
                zoomIn,
                zoomOut,
                toggleFullscreen,
                fullscreen,
                graph,
            }: {
                zoomIn: () => void;
                zoomOut: () => void;
                toggleFullscreen: () => void;
                fullscreen: boolean;
                graph: IGraph;
            }) => (
                <>
                    <Button onClick={zoomIn} ghost>
                        <PlusOutlined style={{ color: 'black', fontSize: '20px' }} />
                    </Button>
                    <Button onClick={zoomOut} ghost>
                        <MinusOutlined style={{ color: 'black', fontSize: '20px' }} />
                    </Button>
                    <Button onClick={toggleFullscreen} ghost>
                        {fullscreen ? (
                            <FullscreenExitOutlined style={{ color: 'black', fontSize: '20px' }} />
                        ) : (
                            <FullscreenOutlined style={{ color: 'black', fontSize: '20px' }} />
                        )}
                    </Button>
                    <Button
                        onClick={() => {
                            const center = graph.getGraphCenterPoint();
                            const zoom = graph.getZoom();
                            graph.zoomTo(1, center, false);
                            graph.downloadFullImage(undefined, 'image/jpeg', {
                                backgroundColor: isDarkTheme ? '#0b1727' : 'white',
                                padding: 20,
                            });
                            graph.zoomTo(zoom, center, false);
                        }}
                        ghost
                    >
                        <DownloadOutlined style={{ color: 'black', fontSize: '20px' }} />
                    </Button>
                </>
            ),
        },
        layout: {
            getWidth: () => {
                return 200;
            },
        },
        behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
        style: {
            backgroundColor: isDarkTheme ? '#0b1727' : 'white',
            height: '60vh',
            outlineColor: isDarkTheme ? '#505764' : '#a5a5a5',
            outlineWidth: '1px',
            outlineStyle: 'solid',
            borderRadius: '5px',
        },
        minimapCfg: {
            show: true,
        },
    };

    const getBusinessUnitParent = (targetId: any) => {
        const findTopParent = (item: any) => {
            if (item.id === targetId) {
                return item.topParentId || item.id;
            }

            if (item.children && item.children.length > 0) {
                const childResults = item.children.map((child: any) => findTopParent(child));
                const matchingResult = childResults.find((result: any) => result !== null);

                return matchingResult || null;
            }

            return null;
        };
        const topParentId = allBusinessUnits
            .map((item: any) => findTopParent(item))
            .find((result: any) => result !== null);

        if (topParentId != selectedTopParentID) {
            setSelectedTopParentID(topParentId);
            setLoading(true);
        }
    };

    return (
        <div style={{ height: '79vh' }}>
            <div style={{ display: 'inline' }}>
                <Form form={businessUnitForm}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <BusinessUnitSearch
                                onChange={async (value: any) => {
                                    setShadowID(value.id);
                                    await getBusinessUnitParent(value.id);
                                }}
                                canAddNewModalComponent={false}
                                canAddSearchPopupComponent={true}
                                hideNewButton
                            />
                        </Col>
                        <Col span={4}>
                            {geti18nText('businessUnit.graph.showEmployees')}
                            <Switch
                                checked={showEmployee}
                                onChange={(checked: any) => {
                                    setLoading(true);
                                    setShowEmployee(checked);
                                }}
                                style={{ marginLeft: '10px' }}
                                checkedChildren={geti18nText('app.default.button.yes')}
                                unCheckedChildren={geti18nText('app.default.button.no')}
                            />
                        </Col>
                        <Col span={4}>
                            <div style={{ display: 'flex', marginLeft: '20px' }}>
                                <Slider
                                    onChange={(e: any) => {
                                        setLevel(e);
                                        setGraphRefresh((prev) => {
                                            return prev + 1;
                                        });
                                    }}
                                    handleStyle={{ background: '#2c7be5' }}
                                    disabled={false}
                                    min={1}
                                    max={3}
                                    marks={levelMarks}
                                    style={{ width: '80%', top: '-4px' }}
                                    defaultValue={level}
                                    value={level}
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
            {loading ? (
                <div style={{ height: 'calc(100% - 46px)', display: 'grid', justifyItems: 'center' }}>
                    <NySpinner size={'large'} />
                </div>
            ) : error ? (
                <Result status={500} title={'500'} subTitle={geti18nText('app.default.500')} />
            ) : (
                allBusinessUnits.length > 0 && <DecompositionTreeGraph {...config} key={graphRefresh} />
            )}

            <Modal
                open={modalVisible}
                onOk={() => {
                    setSegmentedValue(geti18nText('businessUnit.graph.button.vocations'));
                    setModalVisible(false);
                }}
                closable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={900}
                centered
                okText={geti18nText('app.default.button.ok')}
                title={title}
            >
                <Segmented
                    options={[
                        geti18nText('businessUnit.graph.button.vocations'),
                        geti18nText('businessUnit.graph.button.employees'),
                    ]}
                    value={segmentedValue}
                    onChange={setSegmentedValue}
                    size="large"
                />
                {segmentedValue == geti18nText('businessUnit.graph.button.vocations') && (
                    <div style={{ marginTop: '10px' }}>
                        {geti18nText('businessUnit.graph.showModalEmployees')}
                        <Switch
                            checked={showModalEmployees}
                            onChange={(checked: any) => setShowModalEmployees(checked)}
                            style={{ marginLeft: '10px' }}
                            checkedChildren={geti18nText('app.default.button.yes')}
                            unCheckedChildren={geti18nText('app.default.button.no')}
                        />
                    </div>
                )}

                {segmentedValue == geti18nText('businessUnit.graph.button.vocations') && !showModalEmployees && (
                    <NyDataTable
                        nyId="business-unit-graph-table1"
                        rowKey="id"
                        url={CONSTANTS_REQ.WORKPLACE_DETAILS.LIST}
                        showRecordModal={true}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        fetchWhenChange={selectedItemID}
                        scroll={{ y: 400, x: 500 }}
                        setDefaultFilterValue={() => [
                            { field: 'active', condition: 'equals_bool', value: 1 },
                            { field: 'businessUnit.id', condition: 'equals', value: selectedItemID },
                        ]}
                        columns={[
                            {
                                title: geti18nText('workplaceDetails.table.column.id'),
                                dataIndex: 'id',
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('systematization.of.jobs.table.column.vocation'),
                                dataIndex: ['vocation', 'name'],
                                width: '20%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearchByCodebookSimple(
                                    CONSTANTS_REQ.VOCATION.SEARCH,
                                    'vocation.id',
                                    'name',
                                    'name',
                                    {
                                        id: 'id',
                                        label: 'name',
                                    }
                                ),
                            },
                            {
                                title: geti18nText('systematization.of.jobs.table.column.vocationDescription'),
                                dataIndex: ['vocationDescription', 'name'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearchByCodebookSimple(
                                    CONSTANTS_REQ.VOCATION_DESCRIPTION.SEARCH,
                                    'vocationDescription.id',
                                    'name',
                                    'name',
                                    {
                                        id: 'id',
                                        label: 'name',
                                    }
                                ),
                            },
                        ]}
                        hideNewButton
                    />
                )}
                {segmentedValue == geti18nText('businessUnit.graph.button.vocations') && showModalEmployees && (
                    <NyDataTable
                        nyId="business-unit-graph-table2"
                        rowKey="id"
                        url={CONSTANTS_REQ.WORKPLACE_DETAILS.LIST}
                        showRecordModal={true}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        fetchWhenChange={selectedItemID}
                        scroll={{ y: 400, x: 500 }}
                        setDefaultFilterValue={() => [
                            { field: 'active', condition: 'equals_bool', value: 1 },
                            { field: 'businessUnit.id', condition: 'equals', value: selectedItemID },
                        ]}
                        columns={[
                            {
                                title: geti18nText('workplaceDetails.table.column.id'),
                                dataIndex: 'id',
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                            {
                                title: geti18nText('systematization.of.jobs.table.column.vocation'),
                                dataIndex: ['vocation', 'name'],
                                width: '20%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearchByCodebookSimple(
                                    CONSTANTS_REQ.VOCATION.SEARCH,
                                    'vocation.id',
                                    'name',
                                    'name',
                                    {
                                        id: 'id',
                                        label: 'name',
                                    }
                                ),
                            },
                            {
                                title: geti18nText('systematization.of.jobs.table.column.vocationDescription'),
                                dataIndex: ['vocationDescription', 'name'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearchByCodebookSimple(
                                    CONSTANTS_REQ.VOCATION_DESCRIPTION.SEARCH,
                                    'vocationDescription.id',
                                    'name',
                                    'name',
                                    {
                                        id: 'id',
                                        label: 'name',
                                    }
                                ),
                            },
                            {
                                title: geti18nText('workplaceDetails.table.column.firstName'),
                                dataIndex: ['person', 'firstName'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('workplaceDetails.table.column.lastName'),
                                dataIndex: ['person', 'lastName'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('workplaceDetails.table.column.employmentRecordId'),
                                dataIndex: ['employee', 'employmentRecordId'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                        ]}
                        hideNewButton
                    />
                )}
                {segmentedValue == geti18nText('businessUnit.graph.button.employees') && (
                    <NyDataTable
                        nyId="business-unit-graph-table3"
                        rowKey="id"
                        url={CONSTANTS_REQ.WORKPLACE_DETAILS.LIST}
                        showRecordModal={true}
                        setDefaultPageSize={table.setDefaultPageSize()}
                        fetchWhenChange={selectedItemID}
                        scroll={{ y: 400, x: 500 }}
                        setDefaultFilterValue={() => [
                            { field: 'active', condition: 'equals_bool', value: 1 },
                            { field: 'businessUnit.id', condition: 'equals', value: selectedItemID },
                        ]}
                        columns={[
                            {
                                title: geti18nText('workplaceDetails.table.column.id'),
                                dataIndex: ['employee', 'id'],
                                width: '10%',
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                                render: (text: any, record: any) => {
                                    return (
                                        <Button type="link" style={{ textDecoration: 'underline' }}>
                                            {record?.employee?.id}
                                        </Button>
                                    );
                                },
                            },
                            {
                                title: geti18nText('workplaceDetails.table.column.firstName'),
                                dataIndex: ['person', 'firstName'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('workplaceDetails.table.column.lastName'),
                                dataIndex: ['person', 'lastName'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('string'),
                            },
                            {
                                title: geti18nText('workplaceDetails.table.column.employmentRecordId'),
                                dataIndex: ['employee', 'employmentRecordId'],
                                sorter: (a: any, b: any) => {},
                                ...getColumnSearch('number'),
                            },
                        ]}
                        hideNewButton
                    />
                )}
            </Modal>
        </div>
    );
};

export default BusinessUnitsGraph;
