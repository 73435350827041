import { geti18nText, NyModalConfirm, NyRequestResolver, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Descriptions, Form, Modal, Row, Tooltip, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import useEnum from '../../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    errorNotification,
    getErrorNotificationMessage,
    okNotification,
    onKeyDownTextAreaCustom,
} from '../../../../../utils/Utils';
import { TasksRights } from '../../../../../rights/tasksRights';

const { Text } = Typography;

const TaskApprovalModal = ({
    taskApprovalUser = null,
    setTaskApprovalUser,
    visible = false,
    setVisible,
    refresh,
    setRefresh,
    type,
}: any) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>(null);
    const [metadata, setMetadata] = useState<any>([]);
    const [metadataTemplate, setMetadataTemplate] = useState<any>([]);
    const [metadataValueIsSet, setMetadataValueIsSet] = useState<boolean>(false);
    const enTaskApprovalStatus = useEnum('TASK_APPROVAL_STATUS');
    const taskTypeEnum = useEnum('TASK_TYPE');
    const [actionForm] = Form.useForm();
    const focusInput = useRef<any>(null);

    const canMakeAction = () => {
        return (
            TasksRights.canViewTaskApproval() &&
            taskApprovalUser?.taskApproval?.status == enTaskApprovalStatus.WAITING_FOR_APPROVAL
        );
    };

    useEffect(() => {
        if (visible) {
            fetch();
        }
    }, [visible]);

    useEffect(() => {
        if (metadataTemplate && metadataTemplate.length > 0 && metadata && metadata.length > 0 && !metadataValueIsSet) {
            setLoading(false);
            let newData = [...metadataTemplate];
            metadata.map((item: any) => {
                if (item.value) {
                    const findItem: any = newData.find((row: any) => row.id === item.taskTemplateMetadata.id);
                    const index = newData.findIndex((row: any) => row.id === item.taskTemplateMetadata.id);
                    if (findItem && index > -1) {
                        newData.splice(index, 1, { ...findItem, ...{ value: item.value } });
                    }
                }
            });
            setMetadataValueIsSet(true);
            setMetadataTemplate(newData);
            setLoading(false);
        }
    }, [metadataTemplate, metadata]);

    const fetch = () => {
        if (taskApprovalUser?.taskApprovalTask?.id) {
            setLoading(true);
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.TASK.EDIT + '/' + taskApprovalUser.taskApprovalTask.id,
                undefined
            )
                .then((result: any) => {
                    if (result.status === RESPONSE.OK && result.data) {
                        setData(result.data);
                        if (result.data?.taskMetadataList?.length > 0) {
                            setMetadata(result.data.taskMetadataList);
                            fetchMetadataTemplate(result.data?.taskTemplate?.id);
                        }
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const fetchMetadataTemplate = (templateId: any) => {
        if (templateId) {
            setLoading(true);
            const url = CONSTANTS_REQ.TASK_TEMPLATE.METADATA;
            const urlWithId = url.replace('{taskTemplateId}', templateId);

            let filter = encodeURI(JSON.stringify([{ field: 'active', condition: 'equals_bool', value: 1 }]));
            NyRequestResolver.requestGet(urlWithId, {
                max: 100,
                search: filter,
            })
                .then((result: any) => {
                    if (result.status === RESPONSE.OK && result.data && result.data.content) {
                        setMetadataTemplate(result.data.content);
                    }
                })
                .finally(() => setLoading(false));
        }
    };
    const changeStatusAction = (status: any, info: any) => {
        if (taskApprovalUser?.id) {
            setLoading(true);
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.TASK.CHANGE_TASK_APPROVAL_STATUS + '/' + taskApprovalUser?.id,
                undefined,
                {
                    id: taskApprovalUser?.id,
                    status: status,
                    info: info,
                }
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    okNotification();
                    closeModal();
                    if (setRefresh) setRefresh(() => refresh + 1);
                } else {
                    getErrorNotificationMessage(result);
                }
            });
            setLoading(false);
        }
    };

    const changeStatus = (status: any) => {
        const values = actionForm.getFieldsValue();
        if (status == enTaskApprovalStatus.REJECTED && (!values.note || values.note?.length < 1)) {
            errorNotification(geti18nText('task.reject.note'));
        } else {
            changeStatusAction(status, values.note);
        }
    };

    const closeModal = () => {
        setVisible(false);
        setLoading(false);
        setMetadataValueIsSet(false);
        setData(null);
        setMetadata([]);
        setMetadataTemplate([]);
        if (setTaskApprovalUser) {
            setTaskApprovalUser(null);
        }
    };

    const getCustomFooterContent = (
        <>
            <div>
                <Button key="cancel" onClick={closeModal}>
                    {geti18nText('app.default.button.cancel')}
                </Button>
                {canMakeAction() && (
                    <>
                        <NyModalConfirm
                            title={
                                type == taskTypeEnum.SERVICE
                                    ? geti18nText('task.reject.service.confirm')
                                    : type == taskTypeEnum.SUBTASK
                                    ? geti18nText('task.reject.task.confirm')
                                    : ''
                            }
                            onConfirm={() => {
                                changeStatus(enTaskApprovalStatus.REJECTED);
                            }}
                        >
                            <Button style={{ color: 'white', backgroundColor: 'red' }}>
                                {geti18nText('app.default.button.reject.it')}
                            </Button>
                        </NyModalConfirm>
                        <NyModalConfirm
                            title={
                                type == taskTypeEnum.SERVICE
                                    ? geti18nText('task.approve.service.confirm')
                                    : type == taskTypeEnum.SUBTASK
                                    ? geti18nText('task.approve.task.confirm')
                                    : ''
                            }
                            onConfirm={() => {
                                changeStatus(enTaskApprovalStatus.APPROVED);
                            }}
                        >
                            <Button style={{ color: 'white', backgroundColor: 'green' }}>
                                {geti18nText('app.default.button.approve')}
                            </Button>
                        </NyModalConfirm>
                    </>
                )}
            </div>
        </>
    );

    const getItemMetadataFormatValue = (item: any) => {
        const dataType = item?.objectMetadata?.dataType ?? 0;
        switch (dataType) {
            case 3:
                return item.value == true
                    ? geti18nText('app.default.button.yes')
                    : geti18nText('app.default.button.no');
            default:
                return item.value;
        }
    };

    const getDescriptionsTask = () => (
        <>
            <Descriptions
                column={1}
                bordered
                size="small"
                style={{ color: 'black' }}
                title={geti18nText('task.edit.tab.1')}
            >
                <Descriptions.Item className={'ant-typography-print-desc'} label={geti18nText('reportProblem.name')}>
                    <Text>{data?.name}</Text>
                </Descriptions.Item>
                <Descriptions.Item
                    className={'ant-typography-print-desc'}
                    label={geti18nText('reportProblem.description')}
                >
                    <Text>
                        <Tooltip placement="topLeft" overlayStyle={{ maxWidth: '400px' }} title={data?.description}>
                            <div className={'description-div-large'}>
                                <p className="description-paragraph">{data?.description}</p>
                            </div>
                        </Tooltip>
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item className={'ant-typography-print-desc'} label={geti18nText('reportProblem.user')}>
                    <Text>
                        {data?.user?.person?.firstName} {data?.user?.person?.lastName}
                    </Text>
                </Descriptions.Item>
                {data?.assignedAsset?.id && (
                    <Descriptions.Item
                        className={'ant-typography-print-desc'}
                        label={geti18nText('reportProblem.forAssignedAsset')}
                    >
                        <Text>
                            {data?.assignedAsset?.assetModel?.name} ({data?.assignedAsset?.inventoryNumber})
                        </Text>
                    </Descriptions.Item>
                )}
            </Descriptions>

            {metadataValueIsSet && metadataTemplate?.length > 0 && (
                <Descriptions
                    column={1}
                    bordered
                    size="small"
                    style={{ color: 'black' }}
                    title={geti18nText('task.edit.tab.2')}
                >
                    {metadataTemplate &&
                        metadataTemplate.map((item: any) => {
                            return (
                                <Descriptions.Item label={item.objectMetadata.name}>
                                    {getItemMetadataFormatValue(item)}
                                </Descriptions.Item>
                            );
                        })}
                </Descriptions>
            )}
        </>
    );

    return (
        <>
            {loading ? (
                <NySpinner />
            ) : (
                <>
                    {visible && (
                        <>
                            <Modal
                                visible={visible}
                                title={
                                    (type == taskTypeEnum.SERVICE
                                        ? geti18nText('task.approve.service')
                                        : type == taskTypeEnum.SUBTASK
                                        ? geti18nText('task.approve.task')
                                        : '') +
                                    ' - ' +
                                    data?.name
                                }
                                width={600}
                                onCancel={closeModal}
                                confirmLoading={loading}
                                maskClosable={false}
                                okButtonProps={{ style: { display: 'none' } }}
                                footer={getCustomFooterContent}
                            >
                                <>
                                    {data && (
                                        <>
                                            <Row gutter={24}>
                                                <Col span={24}>{getDescriptionsTask()}</Col>
                                            </Row>
                                            <Form
                                                form={actionForm}
                                                onFinishFailed={({ errorFields }) => {
                                                    actionForm.scrollToField(errorFields[0].name);
                                                }}
                                                layout="vertical"
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                style={{ marginTop: '20px' }}
                                            >
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={geti18nText('travelWarrantWizard.table.column.note')}
                                                            name="note"
                                                            labelAlign={'left'}
                                                        >
                                                            <TextArea
                                                                autoFocus={true}
                                                                ref={focusInput}
                                                                rows={4}
                                                                onKeyDown={onKeyDownTextAreaCustom}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </>
                                    )}
                                </>
                            </Modal>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default TaskApprovalModal;
