import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { useState } from 'react';
import useTableSettings from '../../../../hooks/useTableSettings';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getEnumBooleanArray } from '../../../../utils/Utils';
import UsersEdit from './edit';
import AuthGroupsIndex from './groups';
import UsersGroupsIndex from './users-groups';

const AuthUsersIndex = () => {
    const [activeKey, setActiveKey] = useState('1');
    const [fetchUsersGroupsWhenChange, setFetchUsersGroupsWhenChange] = useState(0);
    const table = useTableSettings();

    const columns = [
        {
            title: geti18nText('user.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('user.table.column.username'),
            dataIndex: 'username',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('user.table.column.employee'),
            dataIndex: 'employeeExist',
            width: '10%',
            render: (text: any, record: any) => {
                if (record.employee !== undefined) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool', undefined),
        },
        {
            title: geti18nText('user.table.column.employmentRecordId'),
            dataIndex: ['employee', 'employmentRecordId'],
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('user.table.column.firstName'),
            dataIndex: ['person', 'firstName'],
            width: '17%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('user.table.column.lastName'),
            dataIndex: ['person', 'lastName'],
            width: '17%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('user.table.column.oib'),
            dataIndex: ['person', 'oib'],
            width: '15%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('user.table.column.active'),
            dataIndex: 'active',
            width: '7%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
            defaultFilteredValue: table.setDefaultFilterValue(),
        },
    ];

    return (
        <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)} type="card">
            <TabPane tab={geti18nText('user.auth.tab.1')} key="1">
                <NyDataTable
                    nyId={'user-administration'}
                    url={CONSTANTS_REQ.USER.LIST}
                    columns={columns}
                    modalComponent={UsersEdit}
                    editProps={{ setFetchUsersGroupsWhenChange: setFetchUsersGroupsWhenChange }}
                    showRecordModal={true}
                    buttonsClassName={'buttons-sticky'}
                    scroll={{ y: 620 }}
                    setDefaultSortOrder={table.setDefaultSortOrder()}
                    onDataLoaded={table.onDataLoaded}
                    setDefaultFilterValue={table.setDefaultFilterValue}
                    setDefaultPageSize={table.setDefaultPageSize()}
                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                ></NyDataTable>
            </TabPane>
            <TabPane tab={geti18nText('user.auth.tab.2')} key="2">
                <AuthGroupsIndex editProps={{ setFetchUsersGroupsWhenChange: setFetchUsersGroupsWhenChange }} />
            </TabPane>
            <TabPane tab={geti18nText('user.auth.tab.3')} key="3">
                <UsersGroupsIndex fetchWhenChange={fetchUsersGroupsWhenChange} />
            </TabPane>
        </Tabs>
    );
};

export default AuthUsersIndex;
