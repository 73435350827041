import { geti18nText } from '@nybble/nyreact';
import { Descriptions, Typography } from 'antd';
import useApplicationSetting from '../../hooks/useApplicationSetting';
import useEnum from '../../hooks/useEnum';
import { numberFormat } from '../../utils/Utils';
import { carIcon, wageIcon, wageIconSmall } from '../travel-warrant-icons';

const { Text } = Typography;

const TravelWarrantWageSummaryIndexTotals = ({
    recapitulationData,
    printPdf,
    getTotalFromType,
    getExchangeRateFromTotalsType,
    showRecapitulationTable = true,
}: any) => {
    const defaultCurrencyIsoCode = useApplicationSetting('DEFAULT_CURRENCY_ISO_CODE');
    const EnTravelVehicleType = useEnum('TRAVEL_VEHICLE_TYPE');
    const enTravelWarrantTotals = useEnum('TRAVEL_WARRANT_TOTALS');
    const enTravelWarrantPaymentStatus = useEnum('TRAVEL_WARRANT_PAYMENT_STATUS');

    const showTravelVehicleExpenseAmountReceived = () => {
        return (
            recapitulationData?.travelWarrant?.travelWarrantType?.payoutByAnotherOrganization === true &&
            recapitulationData?.travelWarrant?.travelVehicleExpenseAmountReceived > 0
        );
    };

    const getTravelVehicleExpenseAmountReceived = () => {
        const travelVehicleExpenseAmountReceived =
            recapitulationData?.travelWarrant?.travelVehicleExpenseAmountReceived;

        return (
            <span>
                {travelVehicleExpenseAmountReceived > 0 ? numberFormat(travelVehicleExpenseAmountReceived) : '0,00'}{' '}
                <br />
            </span>
        );
    };

    const getTravelVehicleExpenseTotal = () => {
        const totalTravelWarrantRelation = recapitulationData?.totalTravelWarrantRelation;

        return (
            <span>
                {totalTravelWarrantRelation > 0 ? numberFormat(totalTravelWarrantRelation) : '0,00'} <br />
            </span>
        );
    };

    const getAdvaceExchangeRate = () => {
        const advancePaymentExchangeRate = recapitulationData?.travelWarrant?.advancePaymentExchangeRate;
        return numberFormat(advancePaymentExchangeRate ? advancePaymentExchangeRate : 1, 6);
    };
    const getAdvanceTotalInCurrency = () => {
        const advancePaymentInCurrency = recapitulationData?.travelWarrant?.advancePaymentInCurrency;
        const advancePaymentCurrency = recapitulationData?.travelWarrant?.advancePaymentCurrency;
        return (
            <span>
                {advancePaymentInCurrency ? numberFormat(advancePaymentInCurrency) : '0,00'}{' '}
                {advancePaymentCurrency?.isoCode ? advancePaymentCurrency.isoCode : defaultCurrencyIsoCode}
            </span>
        );
    };

    const getAdvanceTotal = () => {
        const totalAdvancePayment = recapitulationData?.travelWarrant?.totalAdvancePayment;
        return <span>{totalAdvancePayment ? numberFormat(totalAdvancePayment) : '0,00'} </span>;
    };

    const getAdvanceLabel = () => {
        const payments = recapitulationData?.travelWarrantPaymentsAll;
        const advanceTravelWarrantPayment =
            payments?.length > 0 && payments.filter((item: any) => item.advance == true);
        if (!advanceTravelWarrantPayment || advanceTravelWarrantPayment?.length < 1) {
            return geti18nText('travel.warrant.calculation.advance.0');
        } else {
            const paymentStatus =
                advanceTravelWarrantPayment &&
                advanceTravelWarrantPayment[0]?.status == enTravelWarrantPaymentStatus.PAID
                    ? 1
                    : 0;
            return geti18nText('travel.warrant.calculation.advance.' + paymentStatus);
        }
    };

    return (
        <>
            <div
                style={{
                    display: 'grid',
                    justifyContent:
                        !(recapitulationData?.travelWarrant?.travelWarrantType?.includeWage == true) ||
                        !recapitulationData?.travelWarrantWageCalculationJson ||
                        !showRecapitulationTable
                            ? 'left'
                            : 'right',
                }}
            >
                <Descriptions
                    column={3}
                    bordered
                    size="small"
                    labelStyle={{ width: '12rem', fontWeight: '500' }}
                    style={{ width: 'auto' }}
                >
                    {
                        <>
                            <Descriptions.Item
                                label={''}
                                labelStyle={{
                                    background: 'unset',
                                    borderLeft: 'none',
                                }}
                                contentStyle={{
                                    backgroundColor: 'var(--color-soft-primary)',
                                    textAlign: 'right',
                                    fontWeight: '500',
                                }}
                            >
                                {geti18nText('travelWarrantRecapitulation.table.amountInCurrency')}
                            </Descriptions.Item>
                            <Descriptions.Item
                                labelStyle={{ width: '0' }}
                                contentStyle={{
                                    backgroundColor: 'var(--color-soft-primary)',
                                    textAlign: 'right',
                                    fontWeight: '500',
                                }}
                            >
                                {geti18nText('travelWarrantRecapitulation.table.exchangeRate')}
                            </Descriptions.Item>
                            <Descriptions.Item
                                labelStyle={{ width: '0' }}
                                contentStyle={{
                                    backgroundColor: 'var(--color-soft-primary)',
                                    textAlign: 'right',
                                    fontWeight: '500',
                                }}
                            >
                                {geti18nText('travelWarrantRecapitulation.table.amount') +
                                    ' (' +
                                    defaultCurrencyIsoCode +
                                    ')'}
                            </Descriptions.Item>
                        </> /* : null */
                    }
                    {(recapitulationData?.travelWarrant?.travelVehicle?.vehicleType ==
                        EnTravelVehicleType.PRIVATE_CAR ||
                        recapitulationData?.travelWarrant?.vehicleApproved2) &&
                        recapitulationData?.travelWarrant?.travelWarrantType?.includeTravelVehicleExpenses && (
                            <>
                                <Descriptions.Item
                                    label={geti18nText('travelWarrantRecapitulation.travel.costs.calculated.short')}
                                    contentStyle={{
                                        width: '130px',
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: 'black',
                                            float: 'right',
                                            textAlign: 'right',
                                            width: '106%',
                                            marginRight: '-3px',
                                        }}
                                    >
                                        {''}
                                    </Text>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    labelStyle={{ width: '0' }}
                                    contentStyle={{
                                        width: '130px',
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: 'black',
                                            float: 'right',
                                            textAlign: 'right',
                                            marginRight: '-3px',
                                            marginLeft: '-5px',
                                            width: '106%',
                                        }}
                                    >
                                        {''}
                                    </Text>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    labelStyle={{ width: '0' }}
                                    contentStyle={{
                                        width: '130px',
                                        paddingLeft: '4px',
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: 'black',
                                            float: 'right',
                                            textAlign: 'right',
                                            marginRight: '-3px',

                                            width: '104%',
                                        }}
                                        strong
                                    >
                                        {getTravelVehicleExpenseTotal()}
                                    </Text>
                                </Descriptions.Item>

                                {showTravelVehicleExpenseAmountReceived() && (
                                    <>
                                        <Descriptions.Item
                                            label={geti18nText(
                                                'travelWarrantRecapitulation.travel.costs.received.short'
                                            )}
                                            contentStyle={{
                                                width: '130px',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: 'black',
                                                    float: 'right',
                                                    textAlign: 'right',
                                                    width: '106%',
                                                    marginRight: '-3px',
                                                }}
                                            >
                                                {''}
                                            </Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            labelStyle={{ width: '0' }}
                                            contentStyle={{
                                                width: '130px',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: 'black',
                                                    float: 'right',
                                                    textAlign: 'right',
                                                    marginRight: '-3px',
                                                    marginLeft: '-5px',
                                                    width: '106%',
                                                }}
                                            >
                                                {''}
                                            </Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            labelStyle={{ width: '0' }}
                                            contentStyle={{
                                                width: '130px',
                                                paddingLeft: '4px',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: 'black',
                                                    float: 'right',
                                                    textAlign: 'right',
                                                    marginRight: '-3px',

                                                    width: '104%',
                                                }}
                                                strong
                                            >
                                                <div style={{ float: 'left', marginLeft: '4px' }}>{carIcon}</div>
                                                {getTravelVehicleExpenseAmountReceived()}
                                            </Text>
                                        </Descriptions.Item>
                                    </>
                                )}
                                <>
                                    <Descriptions.Item
                                        label={geti18nText('travelWarrantRecapitulation.travel.costs.total.short')}
                                        contentStyle={{
                                            width: '130px',
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: 'black',
                                                float: 'right',
                                                textAlign: 'right',
                                                width: '106%',
                                                marginRight: '-3px',
                                            }}
                                        >
                                            {getTotalFromType(enTravelWarrantTotals.RELATIONS, true, true)}
                                        </Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        labelStyle={{ width: '0' }}
                                        contentStyle={{
                                            width: '130px',
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: 'black',
                                                float: 'right',
                                                textAlign: 'right',
                                                marginRight: '-3px',
                                                marginLeft: '-5px',
                                                width: '106%',
                                            }}
                                        >
                                            {getExchangeRateFromTotalsType(enTravelWarrantTotals.RELATIONS)}
                                        </Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        labelStyle={{ width: '0' }}
                                        contentStyle={{
                                            width: '130px',
                                            paddingLeft: '4px',
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: 'black',
                                                float: 'right',
                                                textAlign: 'right',
                                                marginRight: '-3px',

                                                width: '104%',
                                            }}
                                            strong
                                        >
                                            {getTotalFromType(enTravelWarrantTotals.RELATIONS, false)}
                                        </Text>
                                    </Descriptions.Item>
                                </>
                            </>
                        )}
                    {recapitulationData?.travelWarrant?.travelWarrantType?.includeTravelExpenses && (
                        <>
                            <Descriptions.Item
                                label={geti18nText('travelWarrantRecapitulation.table.column.expenses')}
                                contentStyle={{
                                    width: '110px',
                                }}
                            >
                                <Text
                                    style={{
                                        color: 'black',
                                        float: 'right',
                                        textAlign: 'right',
                                        marginRight: '-3px',

                                        width: '106%',
                                    }}
                                >
                                    {getTotalFromType(enTravelWarrantTotals.EXPENSES, true, true)}
                                </Text>
                            </Descriptions.Item>
                            <Descriptions.Item
                                labelStyle={{ width: '0' }}
                                contentStyle={{
                                    width: !printPdf ? '68px' : '66px',
                                }}
                            >
                                <Text
                                    style={{
                                        color: 'black',
                                        float: 'right',
                                        textAlign: 'right',
                                        marginRight: '-3px',

                                        width: '106%',
                                    }}
                                >
                                    {getExchangeRateFromTotalsType(enTravelWarrantTotals.EXPENSES)}
                                </Text>
                            </Descriptions.Item>
                            <Descriptions.Item
                                labelStyle={{ width: '0' }}
                                contentStyle={{
                                    width: !printPdf ? '108px' : '80px',
                                    paddingLeft: '4px',
                                }}
                            >
                                <Text
                                    style={{
                                        color: 'black',
                                        float: 'right',
                                        textAlign: 'right',
                                        marginRight: '-3px',

                                        width: '104%',
                                    }}
                                    strong
                                >
                                    {getTotalFromType(enTravelWarrantTotals.EXPENSES, false)}
                                </Text>
                            </Descriptions.Item>
                        </>
                    )}
                    <>
                        <Descriptions.Item
                            label={geti18nText('travelWarrantRecapitulation.table.column.totalExpenses')}
                            contentStyle={{
                                width: '110px',
                            }}
                        >
                            <Text
                                style={{
                                    color: 'black',
                                    float: 'right',
                                    textAlign: 'right',
                                    marginRight: '-3px',

                                    width: '106%',
                                }}
                            >
                                {getTotalFromType(enTravelWarrantTotals.TOTAL_COSTS, true, true)}
                            </Text>
                        </Descriptions.Item>
                        <Descriptions.Item
                            labelStyle={{ width: '0' }}
                            contentStyle={{
                                width: !printPdf ? '68px' : '66px',
                            }}
                        >
                            <Text
                                style={{
                                    color: 'black',
                                    float: 'right',
                                    textAlign: 'right',
                                    marginRight: '-3px',

                                    width: '106%',
                                }}
                            >
                                {getExchangeRateFromTotalsType(enTravelWarrantTotals.TOTAL_COSTS)}
                            </Text>
                        </Descriptions.Item>
                        <Descriptions.Item
                            labelStyle={{ width: '0' }}
                            contentStyle={{
                                width: !printPdf ? '108px' : '80px',
                                paddingLeft: '4px',
                            }}
                        >
                            <Text
                                style={{
                                    color: 'black',
                                    float: 'right',
                                    textAlign: 'right',
                                    marginRight: '-3px',
                                    width: '104%',
                                }}
                                strong
                            >
                                {getTotalFromType(enTravelWarrantTotals.TOTAL_COSTS, false)}
                            </Text>
                        </Descriptions.Item>
                    </>
                    <Descriptions.Item
                        label={getAdvanceLabel()}
                        contentStyle={{
                            width: '130px',
                        }}
                    >
                        <Text
                            style={{
                                color: 'black',
                                float: 'right',
                                textAlign: 'right',
                                width: '106%',
                                marginRight: '-3px',
                            }}
                        >
                            {getAdvanceTotalInCurrency()}
                        </Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ width: '0' }}
                        contentStyle={{
                            width: '130px',
                        }}
                    >
                        <Text
                            style={{
                                color: 'black',
                                float: 'right',
                                textAlign: 'right',
                                marginRight: '-3px',
                                marginLeft: '-5px',
                                width: '106%',
                            }}
                        >
                            {getAdvaceExchangeRate()}
                        </Text>
                    </Descriptions.Item>
                    <Descriptions.Item
                        labelStyle={{ width: '0' }}
                        contentStyle={{
                            width: '130px',
                            paddingLeft: '4px',
                        }}
                    >
                        <Text
                            style={{
                                color: 'black',
                                float: 'right',
                                textAlign: 'right',
                            }}
                            strong
                        >
                            {getAdvanceTotal()}
                        </Text>
                    </Descriptions.Item>
                    {recapitulationData?.travelWarrantPayments?.length > 0 &&
                        recapitulationData?.travelWarrantPayments
                            .reduce((paymentGroups: any, item: any) => {
                                const group = paymentGroups.find((group: any) => group.sort === item.sort);
                                if (group) {
                                    group.items.push(item);
                                } else {
                                    paymentGroups.push({ sort: item.sort, items: [item] });
                                }
                                return paymentGroups;
                            }, [])
                            .map((group: any) => {
                                return (
                                    <>
                                        {group.items
                                            .sort((a: any, b: any) => {
                                                const descriptionA = a.description.toUpperCase();
                                                const descriptionB = b.description.toUpperCase();
                                                if (descriptionA > descriptionB) {
                                                    return -1;
                                                }
                                                if (descriptionA < descriptionB) {
                                                    return 1;
                                                }
                                                return 0;
                                            })
                                            .map((items: any) => {
                                                return (
                                                    <>
                                                        <Descriptions.Item
                                                            span={2}
                                                            key={group.sort}
                                                            label={items?.description}
                                                            contentStyle={{
                                                                width: '180px',
                                                            }}
                                                        >
                                                            {''}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item
                                                            labelStyle={{ width: '0' }}
                                                            contentStyle={{
                                                                width: !printPdf ? '108px' : '58px',
                                                                paddingLeft: '4px',
                                                            }}
                                                        >
                                                            <Text
                                                                style={{
                                                                    color: 'black',
                                                                    float: 'right',
                                                                    textAlign: 'right',
                                                                    width: '100%',
                                                                }}
                                                                strong
                                                            >
                                                                <span
                                                                    style={
                                                                        items && items.taxable
                                                                            ? {
                                                                                  display: 'flex',
                                                                                  justifyContent: 'space-between',
                                                                                  width: '100%',
                                                                              }
                                                                            : {}
                                                                    }
                                                                >
                                                                    <div>
                                                                        {items && items.taxable == true
                                                                            ? wageIconSmall
                                                                            : ''}
                                                                    </div>
                                                                    <>
                                                                        {items && items['amount']
                                                                            ? numberFormat(items['amount'])
                                                                            : '0,00'}{' '}
                                                                    </>
                                                                    <br />
                                                                </span>
                                                            </Text>
                                                        </Descriptions.Item>
                                                    </>
                                                );
                                            })}
                                    </>
                                );
                            })}
                </Descriptions>
                <div style={{ marginTop: '0.5em', width: '100%' }}>
                    {wageIcon}
                    <b> {geti18nText('travelWarrantRecapitulation.table.taxable.wage')}</b>
                </div>
            </div>
        </>
    );
};

export default TravelWarrantWageSummaryIndexTotals;
