import { geti18nText, NyDataEdit, NyDatePicker, NyRequestResolver, NySearchField, RESPONSE } from '@nybble/nyreact';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import EmployeeExistsModal from '../../../../components/employee-exists-modal';
import useEnum from '../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { setModalOpen } from '../../../../slices/modalOpenSlice';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import NyFilesUpload from '../../../../utils/ny-file-upload-custom';
import { getDateFormat, getEnumArray, getEnumFormat, setDateFormat, setEnumFormat } from '../../../../utils/Utils';
import { JMBGCheck, oibCheck } from '../../../../utils/Validation';

const EmployeeFamilyMemberEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employeeFamilyMember.edit.new'));
    const [loading, setLoading] = useState(false);
    const focusInput = useRef<any>(null);

    const [dataForm, setDataForm] = useState<any>(null);
    const [type, setType] = useState<any>(null);
    const [hasDevelopmentalDisabilities, setHasDevelopmentalDisabilities] = useState<boolean>(false);

    const [isAdopted, setIsAdopted] = useState<boolean>(false);
    const [isSingleParent, setIsSingleParent] = useState<boolean>(false);
    const [employeeId, setEmployeeId] = useState<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const { employee } = useSelector((state: RootState) => state.employee);

    const [familyMemberExistsModalVisible, setFamilyMemberExistsModalVisible] = useState(false);
    const [personExistsModalVisible, setPersonExistsModalVisible] = useState(false);
    const [triggerCloseModal, setTriggerCloseModal] = useState(false);
    const [employeeData, setEmployeeData] = useState<any>({});
    const dispatch = useDispatch();

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployee();
    };

    const documentTypeEnum = useEnum('DOCUMENT_TYPE');
    const employeeFamilyMemberTypeEnum = useEnum('EMPLOYEE_FAMILY_MEMBER_TYPE');

    const uploadFilesRef = useRef<any>(null);

    const setDefaultFilterValueFiles = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'ref_id', condition: 'equals', value: dataForm },
            { field: 'employee_id', condition: 'equals', value: employeeId },
            {
                field: 'document_type',
                condition: 'equals',
                value: documentTypeEnum.DEVELOPMENTAL_DISABILITY_ADMINISTRATION,
            },
        ];
    };

    const isChild = () => {
        return type == employeeFamilyMemberTypeEnum.CHILD;
    };

    const isSpouseOrOther = () => {
        return type == employeeFamilyMemberTypeEnum.SPOUSE || type == employeeFamilyMemberTypeEnum.OTHERS;
    };

    useEffect(() => {
        if (props && props.editProps && props.editProps.employeeId) {
            setEmployeeId(props.editProps.employeeId);
        }
    }, [props]);

    function setValues(dataForm: any) {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });

        if (dataForm.person && dataForm.person.firstName && dataForm.person.lastName) {
            setEditHeader(
                geti18nText('employeeFamilyMember.edit.header') +
                    ' - ' +
                    dataForm.person.firstName +
                    ' ' +
                    dataForm.person.lastName
            );
        }

        if (dataForm.person && dataForm.person.birthDate) {
            dataForm.person.birthDate = setDateFormat(dataForm.person.birthDate);
        }
        if (dataForm.person && dataForm.person.sex) {
            dataForm.person.sex = setEnumFormat('SEX_TYPE', dataForm.person.sex);
        }

        if (dataForm.type) {
            setType(dataForm.type);
            dataForm.type = setEnumFormat('EMPLOYEE_FAMILY_MEMBER_TYPE', dataForm.type);
        }
        setHasDevelopmentalDisabilities(dataForm.developmentalDisabilities);

        setIsAdopted(dataForm.adopted);
        setIsSingleParent(dataForm.singleParent);
        delete dataForm.active;

        if (dataForm.employee?.id) {
            dataForm.employee = { id: dataForm.employee?.id };
        } else {
            dataForm.employee = { id: id };
        }
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('employeeFamilyMember.edit.new'));
        setDataForm(null);
        setType(null);
        setHasDevelopmentalDisabilities(false);
        setIsAdopted(false);
        setIsSingleParent(false);
        props.editProps?.setRefresh((prevValue: any) => prevValue + 1);
    };

    const onTypeChange = (value: any) => {
        if (value && value.id !== -1) {
            setType(value.id);
        } else {
            setType(null);
        }
    };

    const validateOib = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            if (oibCheck(value)) {
                callback();
            } else {
                callback(geti18nText('employee.edit.oib.not.correct'));
            }
        } else {
            callback();
        }
    };

    const oibOnchange = (event: any) => {
        var oib = event.target.value;
        if (oib !== undefined && oib !== '' && oibCheck(oib)) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.EMPLOYEE_FAMILY_MEMBER.GET_BY_PERSON_OIB + '/' + oib + '/' + employeeId
            ).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        if (result.data.id) {
                            setFamilyMemberExistsModalVisible(true);
                            setEmployeeData(result.data);
                        } else if (result.data.person.id) {
                            setPersonExistsModalVisible(true);
                            setEmployeeData(result.data);
                        }
                    }
                }
            });
        }
    };

    const familyMemberExistsOk = () => {
        setFamilyMemberExistsModalVisible(false);
        setTriggerCloseModal(true);
        dispatch(
            setModalOpen({
                visible: true,
                modal: 'employeeFamilyMemberEdit',
                item: employeeData.id,
                onSaveAnGetId: false,
                onSave: false,
                onSaveAndGetData: false,
            })
        );
    };

    const familyMemberExistsCancel = () => {
        setFamilyMemberExistsModalVisible(false);
        form.setFieldsValue({ person: { oib: null } });
    };

    const personExistsOk = () => {
        setPersonExistsModalVisible(false);
        let personData = employeeData?.person;
        form.setFieldsValue({
            employee: { id: employeeData.id },
            person: {
                birthDate: setDateFormat(personData?.birthDate),
                firstName: personData?.firstName,
                lastName: personData?.lastName,
                jmbg: personData?.jmbg,
                oib: personData?.oib,
                sex: setEnumFormat('SEX_TYPE', personData?.sex),
            },
        });
    };

    const personExistsCancel = () => {
        setPersonExistsModalVisible(false);
        form.setFieldsValue({ person: { oib: null } });
    };

    const validateJMBG = (rule: any, value: any, callback: any) => {
        if (value !== undefined && value !== '') {
            if (JMBGCheck(value)) {
                callback();
            } else {
                callback(geti18nText('employee.edit.jmbg.not.correct'));
            }
        } else {
            callback();
        }
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setTriggerCloseModal(false);
    };

    return (
        <>
            <NyDataEdit
                layout="vertical"
                formProps={{ labelCol: { offset: 1, span: 22 }, wrapperCol: { offset: 1, span: 22 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.EMPLOYEE_FAMILY_MEMBER.EDIT}
                setValues={setValues}
                width={600}
                form={form}
                hideSubmitButton={!canCreate()}
                hideActivationButtons={!canCreate()}
                goBack={() => history.goBack()}
                triggerCloseModal={triggerCloseModal}
                paramsId={id}
                onBeforeSave={() => {
                    if (!form.getFieldValue('type') || form.getFieldValue('type')?.id == -1) {
                        form.setFieldsValue({ type: undefined });
                        form.validateFields();
                        return;
                    }
                }}
                {...props}
                normalize={(values: any) => {
                    if (values.id == null || values.id == undefined || values.id == 'create' || values.id < 0)
                        delete values.id;
                    if (values.person.birthDate) values.person.birthDate = getDateFormat(values.person.birthDate);
                    if (values.person.sex) {
                        values.person.sex = getEnumFormat(values.person.sex);
                    }

                    if (!values.employee.id) {
                        values.employee.id = props?.editProps?.employeeId;
                    }

                    if (values.type) {
                        values.type = getEnumFormat(values.type);
                    }

                    if (type == isChild()) {
                        values.developmentalDisabilities = hasDevelopmentalDisabilities;
                        values.adopted = isAdopted;
                        values.singleParent = false;
                    } else {
                        values.singleParent = isSingleParent;
                        values.developmentalDisabilities = false;
                        values.adopted = false;
                    }

                    return values;
                }}
                onModalOpen={onModalOpen}
                onSaveAndGetID={(id: any) => {
                    if (uploadFilesRef?.current) {
                        uploadFilesRef.current.filesUpload(id);
                    }
                }}
                shortcuts={true}
                {...props}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item name={['person', 'id']} style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={['employee', 'id']} style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('employeeFamilyMember.edit.firstName')}
                            name={['person', 'firstName']}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input ref={focusInput} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeFamilyMember.edit.lastName')}
                            name={['person', 'lastName']}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeFamilyMember.edit.oib')}
                            name={['person', 'oib']}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                    whitespace: true,
                                },
                                {
                                    validator: validateOib,
                                },
                            ]}
                        >
                            <Input onChange={oibOnchange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeFamilyMember.edit.jmbg')}
                            name={['person', 'jmbg']}
                            rules={[
                                {
                                    validator: validateJMBG,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeFamilyMember.edit.birthDate')}
                            name={['person', 'birthDate']}
                        >
                            <NyDatePicker
                                style={{ width: '100%' }}
                                nyTestId="birth-date-2"
                                mustGetPopupContainer={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('employeeFamilyMember.edit.sex')} name={['person', 'sex']}>
                            <NySearchField
                                options={getEnumArray('SEX_TYPE')}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                                nyTestId="sex"
                                className=""
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label={geti18nText('employeeFamilyMember.edit.type')}
                            name={['type']}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                        >
                            <NySearchField
                                mustGetPopupContainer={false}
                                options={getEnumArray('EMPLOYEE_FAMILY_MEMBER_TYPE')}
                                map={{ id: 'id', label: 'text' }}
                                searchBy="text"
                                nyTestId="type"
                                onChange={onTypeChange}
                                className=""
                            />
                        </Form.Item>
                    </Col>
                    {isChild() && (
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('employeeFamilyMember.edit.developmentalDisabilities')}
                                name={'developmentalDisabilities'}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    checked={hasDevelopmentalDisabilities}
                                    onChange={(e) => {
                                        setHasDevelopmentalDisabilities(e.target.checked);
                                    }}
                                ></Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                    {isChild() && (
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('employeeFamilyMember.edit.adopted')}
                                name={'adopted'}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    checked={isAdopted}
                                    onChange={(e) => {
                                        setIsAdopted(e.target.checked);
                                    }}
                                ></Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                    {isSpouseOrOther() && (
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('employeeFamilyMember.edit.singleParent')}
                                name={'singleParent'}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    checked={isSingleParent}
                                    onChange={(e) => {
                                        setIsSingleParent(e.target.checked);
                                    }}
                                ></Checkbox>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                {hasDevelopmentalDisabilities && isChild() && employeeId && (
                    <NyFilesUpload
                        documentType={documentTypeEnum.DEVELOPMENTAL_DISABILITY_ADMINISTRATION}
                        url={CONSTANTS_REQ.EMPLOYEE_FILE.LIST}
                        editUrl={CONSTANTS_REQ.EMPLOYEE_FILE.EDIT}
                        defaultFilesFilter={setDefaultFilterValueFiles()}
                        ref={uploadFilesRef}
                        canAddNewFile={canCreate()}
                        canEdit={canCreate()}
                        canDelete={canCreate()}
                        refId={dataForm}
                        id={employeeId}
                        employee={employee}
                        maxHeight={'205px'}
                        customFilesTableWidth={'100%'}
                    />
                )}
                {(familyMemberExistsModalVisible || personExistsModalVisible) && (
                    <EmployeeExistsModal
                        visible={
                            familyMemberExistsModalVisible
                                ? familyMemberExistsModalVisible
                                : personExistsModalVisible
                                ? personExistsModalVisible
                                : false
                        }
                        onOk={
                            familyMemberExistsModalVisible
                                ? familyMemberExistsOk
                                : personExistsModalVisible
                                ? personExistsOk
                                : null
                        }
                        onCancel={
                            familyMemberExistsModalVisible
                                ? familyMemberExistsCancel
                                : personExistsModalVisible
                                ? personExistsCancel
                                : null
                        }
                        title={
                            familyMemberExistsModalVisible
                                ? geti18nText('employee.edit.familyMemberExists.title')
                                : personExistsModalVisible
                                ? geti18nText('employee.edit.personExists.title')
                                : null
                        }
                        content={
                            familyMemberExistsModalVisible
                                ? geti18nText('employee.edit.familyMemberExists.content')
                                : personExistsModalVisible
                                ? geti18nText('employee.edit.personExists.content')
                                : null
                        }
                        employeeInfo={employeeData}
                        employee={false}
                    />
                )}
            </NyDataEdit>
        </>
    );
};

export default EmployeeFamilyMemberEdit;
