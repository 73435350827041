import { geti18nText, NySearchField } from '@nybble/nyreact';
import { Form } from 'antd';
import WorkTypeIndex from '.';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import WorkTypeEdit from './edit';

const WorkTypeSearch = ({
    label = geti18nText('work.type.edit.title'),
    name = 'attendanceWorkType',
    required = false,
    disabled = false,
    onChange = undefined,
}: any) => {
    const customRenderOption = (option: any) => {
        return (
            <div custom-data={option}>
                <span style={{ display: 'flex' }}>
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '4px',
                            border: '2px solid rgba(0, 0, 0, 0.2)',
                            background: option?.color,
                            marginRight: '5px',
                            marginTop: '3px',
                        }}
                    />
                    <div style={{ alignSelf: 'center' }}>{option?.text && option.text}</div>
                </span>
            </div>
        );
    };

    return (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.WORK_TYPE.SEARCH}
                map={{ id: 'id', label: 'text', text: 'name', name: 'name', color: 'color' }}
                searchBy="name"
                itemName={[['color'], ['name']]}
                customItemNameLabel={'color name'}
                SearchPopupComponent={<WorkTypeIndex />}
                AddNewModalComponent={WorkTypeEdit}
                nyTestId="attendanceWorkType"
                disabled={disabled}
                renderOption={customRenderOption}
                style={{ height: '20px' }}
                onChange={onChange}
            />
        </Form.Item>
    );
};

export default WorkTypeSearch;
