import { ExclamationCircleFilled, LeftCircleOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Button } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RowAction from '../../../../components/row-action';
import useEnum from '../../../../hooks/useEnum';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { GetEnumNameForValue } from '../../../../utils/Enums';
import {
    GetEmployeeLeaveStatusIcon,
    errorNotification,
    getEnumArray,
    getEnumArrayStatus,
    getLocationStateOpenModal,
    okNotification,
    userIsNotificationRecipient,
} from '../../../../utils/Utils';
import EmployeeLeaveReqEdit from './EmployeeLeaveReqEdit';
import MonthlyReportRmployeeLeaveModal from './MonthlyReportEmployeeLeaveModal';

const EmployeeLeaveReqIndex = ({
    search,
    employeeOverview = false,
    listView,
    setListView,
    refreshView,
    setRefreshView,
    isHumanResources = true,
    showRowSelection,
    defaultStatusFilter = undefined,
    defaultFilterType = undefined,
    activeList,
    scroll = { y: 583, x: 800 },
    sortOrder,
    showMonthlyReport = false,
}: any) => {
    const location: any = useLocation();
    const [visible, setVisible] = useState<boolean>(false);
    const [refresh, setRefresh] = useState(0);
    const employeeLeaveType = useEnum('EMPLOYEE_LEAVE_TYPE');
    const employeeLeaveStatus = useEnum('EMPLOYEE_LEAVE_STATUS');
    const notificationType = useEnum('NOTIFICATION_TYPE');
    const { employee } = useSelector((state: RootState) => state.employee);
    const [moduleAccessRight, setModuleAccessRight] = useState<any>([]);
    const table = useTableSettings();
    const url = activeList == 3 ? CONSTANTS_REQ.EMPLOYEE_LEAVE.LIST_ACTION : CONSTANTS_REQ.EMPLOYEE_LEAVE.LIST;

    useEffect(() => {
        getModuleAccessRights();
    }, []);

    function getModuleAccessRights() {
        const moduleAccessRightList: any = [];
        employee?.moduleAccessRightList?.forEach((value: any) => {
            const enumName = GetEnumNameForValue({ enumName: 'MODULE_ACCESS_RIGHT', value: parseInt(value) });

            moduleAccessRightList.push(enumName);
        });
        setModuleAccessRight(moduleAccessRightList);
    }

    const canCreate = () => {
        return (
            HumanResourcesRights.canCreateEmployeeLeave() || (moduleAccessRight && moduleAccessRight.includes('LEAVE'))
        );
    };

    const canCreateAdmin = () => {
        return (
            HumanResourcesRights.isAdmin() ||
            userIsNotificationRecipient(employee, notificationType.EMPLOYEE_ANNUAL_LEAVE)
        );
    };

    useEffect(() => {
        getLocationStateOpenModal(location, setVisible, false);
    }, [location.state]);

    const setDefaultFilterValue = () => {
        if (search) {
            return search;
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const changeStatus = (values: any, status: any) => {
        const valuesNew = {
            id: values.id,
            status: status,
        };
        NyRequestResolver.requestPut(
            CONSTANTS_REQ.EMPLOYEE_LEAVE.CHANGE_STATUS + '/' + values.id,
            undefined,
            valuesNew
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                okNotification();
                setRefresh((refresh: any) => refresh + 1);
                // dispatch(refreshItemList());
            } else {
                errorNotification(geti18nText('employee.leave.update.error'));
            }
        });
    };

    const addedButtons = (onSave: () => void) => {
        return (
            <>
                <div style={{ float: 'left', paddingLeft: '8px' }}>
                    {employeeOverview ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                setListView(0);
                                setRefreshView((refreshView: any) => refreshView + 1);
                            }}
                        >
                            <LeftCircleOutlined />
                            {geti18nText('employee.leave.cards.back')}
                        </Button>
                    ) : showMonthlyReport ? (
                        <MonthlyReportRmployeeLeaveModal />
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    };

    const initialColumns = [
        ...[
            {
                title: geti18nText('employeeAnnualLeave.table.column.employee'),
                dataIndex:
                    "concat(person.first_name, ' ', person.last_name, ' ', coalesce(cast(employee.employment_record_id as varchar),'')) ",
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (person: any, record: any) => {
                    if (record && record.person) {
                        return (
                            <>
                                {`${record.person.firstName} ${record.person.lastName} `}
                                {record.employee &&
                                    record.employee.employmentRecordId &&
                                    ` (${record.employee.employmentRecordId})`}

                                {canCreateAdmin() &&
                                    record.type == employeeLeaveType.SICK_LEAVE &&
                                    record.status == employeeLeaveStatus.CONFIRMED &&
                                    !record.employeeSickLeave && (
                                        <ExclamationCircleFilled
                                            className="ny-blink"
                                            style={{ color: 'red', marginLeft: '5px', fontWeight: 'bold' }}
                                        />
                                    )}
                            </>
                        );
                    }
                },
            },

            {
                title: geti18nText('employeeLeave.table.column.from'),
                dataIndex: 'dateFrom',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(false),
                render: (text: any, record: any) => {
                    if (record.dateFrom) {
                        return moment(record.dateFrom).format('DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('employeeLeave.table.column.to'),
                dataIndex: 'dateTo',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(false),
                render: (text: any, record: any) => {
                    if (record.dateTo) {
                        return moment(record.dateTo).format('DD.MM.YYYY');
                    }
                },
            },
            {
                title: geti18nText('employeeLeave.table.column.days'),
                dataIndex: 'workingDays',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('employeeLeave.table.column.type'),
                dataIndex: 'type',
                sorter: (a: any, b: any) => {},
                ...(activeList == 3
                    ? getColumnSearchCheckbox(getEnumArray('EMPLOYEE_LEAVE_TYPE'), 'in', defaultFilterType)
                    : getColumnSearchCheckbox(getEnumArray('EMPLOYEE_LEAVE_TYPE'))),

                render: (text: any, record: any) => {
                    if (record.type !== undefined) {
                        return geti18nText('app.enum.EMPLOYEE_LEAVE_TYPE.' + record.type);
                    }
                },
            },
            {
                title: geti18nText('employeeLeave.table.column.status'),
                dataIndex: 'status',
                width: '180px',
                sorter: activeList != 3 ? (a: any, b: any) => {} : undefined,
                ...(activeList != 3 &&
                    getColumnSearchCheckbox(getEnumArrayStatus('EMPLOYEE_LEAVE_STATUS'), 'in', defaultStatusFilter)),
                render: (status: any, record: any) => {
                    return GetEmployeeLeaveStatusIcon(status);
                },
            },
        ],
        ...(activeList == 3
            ? [
                  {
                      title: geti18nText('app.default.actions'),
                      dataIndex: 'actions',
                      width: '70px',
                      render: (text: any, record: any) => {
                          if (record.id && record.status == employeeLeaveStatus.REQUESTED) {
                              return (
                                  <RowAction
                                      component={'EmployeeAnnualLeaveChangeStatus'}
                                      record={record}
                                      changeStatus={changeStatus}
                                  ></RowAction>
                              );
                          }
                      },
                  },
              ]
            : []),
    ];

    return (
        <>
            <NyDataTable
                nyId="human-employee-leave-req-table"
                url={url}
                addNewButtonText={geti18nText('employeeLeave.table.add')}
                addedButtons={addedButtons}
                buttonsClassName="buttons-sticky"
                columns={initialColumns}
                setDefaultSortOrder={sortOrder ?? { order: 'dateFrom', orderType: 'desc' }}
                scroll={scroll}
                hideNewButton={!canCreateAdmin() || !isHumanResources}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                showRowSelection={showRowSelection}
                shortcuts={true}
                modalComponent={EmployeeLeaveReqEdit}
                editProps={{
                    visible: visible,
                    setVisible: setVisible,
                    refresh: refresh,
                    setRefresh: setRefresh,
                    canCreate: canCreate(),
                    canCreateAdmin: canCreateAdmin(),
                    editHeader: geti18nText('employeeLeave.edit.new'),
                    submitButtonText: geti18nText('app.default.button.save'),
                    isHumanResources: isHumanResources,
                    isVacation: true,
                }}
                showRecordModal={true}
                nyTestId="employee-leave-table"
                fetchWhenChange={refresh}
            />
        </>
    );
};

export default EmployeeLeaveReqIndex;
