import { DesktopOutlined, EditOutlined, FormOutlined, IdcardOutlined, MobileOutlined } from '@ant-design/icons';
import { getColumnDateOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { Button, Tabs, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import useApplicationSetting from '../../../../hooks/useApplicationSetting';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee } from '../../../../utils/Filters';
import {
    GetAttendanceRegistrationIcon,
    GetCustomAttendanceDateFormat,
    getTransparentColor,
} from '../../../../utils/Utils';
import AttendanceRegistrationEdit from './AttendanceRegistrationEdit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
const { TabPane } = Tabs;

const AttendanceListIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const table = useTableSettings();
    const [editModalVisible, setEditModalVisible] = useState<any>(false);
    const [editModalData, setEditModalData] = useState<any>();
    const enAttendanceBreakType = useEnum('ATTENDANCE_REGISTRATION_TYPE');
    const attendanceMinLoggedMin = useApplicationSetting('ATTENDANCE_MINIMUM_LOGGED_MINUTES');
    const [refreshTable, setRefreshTable] = useState<any>(0);
    const { theme } = useSelector((state: RootState) => state.generalSettings);
    const isDarkTheme = theme != 'dark' ? false : true;

    useHelper('human/attendance-registration');

    const canCreate = () => {
        return HumanResourcesRights.canCreateAttendanceAdminAndRegistration();
    };

    const canExportCSV = () => {
        return HumanResourcesRights.canCreateAttendanceAdminAndRegistration();
    };

    const getDailyAttendanceIcons = (items: any) => {
        return JSON.parse(items.value).map((item: any) => (
            <div style={{ display: 'flex' }}>
                <Tag
                    style={{
                        marginTop: '2px',
                        position: 'relative',
                        color: isDarkTheme ? 'lightgray' : 'black',
                        backgroundColor: getTransparentColor(item.attendance_work_type_color, 0.25),
                        border: `1px solid ${getTransparentColor(
                            item.attendance_work_type_color,
                            isDarkTheme ? 0.25 : 0.5
                        )}`,
                    }}
                    onClick={(e: any) => {
                        setEditModalData(item);
                        setEditModalVisible(true);
                    }}
                >
                    <Tooltip
                        title={
                            <>
                                {item?.location_in_name || item?.registration_in || item?.attendance_work_type_name ? (
                                    <>
                                        {item?.location_in_name ? (
                                            <>
                                                {geti18nText('attendance.registration.edit.location.in', [
                                                    item?.location_in_name,
                                                ])}
                                                <br />
                                            </>
                                        ) : undefined}
                                        {item?.registration_in ? (
                                            <>
                                                {geti18nText('attendance.registration.edit.type.in')}{' '}
                                                {item?.registration_in &&
                                                    geti18nText(
                                                        'app.enum.ATTENDANCE_REGISTRATION_TYPE.' + item?.registration_in
                                                    )}
                                                <br />
                                            </>
                                        ) : undefined}
                                    </>
                                ) : undefined}
                                {item?.location_out_name || item?.registration_out ? (
                                    <>
                                        {item?.location_out_name ? (
                                            <>
                                                {geti18nText('attendance.registration.edit.location.out', [
                                                    item?.location_out_name,
                                                ])}
                                                <br />
                                            </>
                                        ) : undefined}
                                        {item?.registration_out ? (
                                            <>
                                                {geti18nText('attendance.registration.edit.type.out')}{' '}
                                                {item?.registration_out &&
                                                    geti18nText(
                                                        'app.enum.ATTENDANCE_REGISTRATION_TYPE.' +
                                                            item?.registration_out
                                                    )}
                                                <br />
                                            </>
                                        ) : undefined}
                                    </>
                                ) : undefined}
                                {(item?.registration_out || item?.location_out_name) &&
                                    item?.type_name &&
                                    item?.type_name}
                            </>
                        }
                    >
                        <div
                            key={item.id}
                            style={{
                                display: 'inline-flex',
                                paddingTop: '2px',
                            }}
                        >
                            <div>
                                <GetAttendanceRegistrationIcon registration={item.registration_in} />
                                <span> {moment(Date.parse(item.from)).format('HH:mm')} </span>
                            </div>
                            {(item?.to || item?.location_out_name) && (
                                <div style={{ marginLeft: '10px' }}>
                                    <GetAttendanceRegistrationIcon registration={item.registration_out} />
                                    <span> {moment(Date.parse(item.to)).format('HH:mm')} </span>
                                </div>
                            )}
                        </div>
                    </Tooltip>
                </Tag>
                <Tag
                    color="default"
                    style={{
                        position: 'relative',
                        marginTop: '2px',
                    }}
                >
                    {item?.attendance_work_type_name}
                </Tag>
                {item?.edited && (
                    <div>
                        <Tooltip
                            placement="top"
                            title={geti18nText('app.default.record.edited', [
                                item?.edited_name,
                                moment(item?.edit_time).format('DD.MM.YYYY HH:mm'),
                            ])}
                            overlayInnerStyle={{ width: 'max-content' }}
                        >
                            <Tag
                                color="orange"
                                style={{
                                    marginTop: '2px',
                                    position: 'relative',
                                    marginRight: '2px',
                                }}
                            >
                                <FormOutlined
                                    style={{
                                        fontSize: '16px',
                                        paddingTop: '2px',
                                    }}
                                />
                            </Tag>
                        </Tooltip>
                    </div>
                )}
            </div>
        ));
    };

    const getPassedTimeRender = (record: any) => {
        if (record?.id && record?.dateOfApplication && record?.passedTime) {
            const [hours, minutes, seconds] = record.passedTime.split(':');
            const time = `${hours}:${minutes}`;
            const timeInMinutes = Number(hours ? hours : 0) * 60 + Number(minutes ? minutes : 0);
            const isTodayDate: boolean = moment(moment(record.dateOfApplication).format('yyyy-MM-DD')).isSame(
                moment(moment().format('yyyy-MM-DD'))
            );
            const isZeroMinutesAndNotTodayDate: boolean = timeInMinutes == 0 && !isTodayDate;
            const isLessMinutesAndNotTodayDate: boolean = timeInMinutes < attendanceMinLoggedMin && !isTodayDate;
            const colorRed: boolean = isZeroMinutesAndNotTodayDate || isLessMinutesAndNotTodayDate;
            const timeShowValue = isZeroMinutesAndNotTodayDate
                ? geti18nText('attendance.time.value.needs.correction')
                : timeInMinutes > 0
                ? time
                : '00:00';

            return <b style={{ color: colorRed ? 'red' : '' }}>{timeShowValue}</b>;
        }

        return <>{'00:00'}</>;
    };

    const initialAdminColumns = [
        {
            title: geti18nText('attendance.table.column.employee'),
            dataIndex: "concat(person.first_name,' ', person.last_name)",
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookEmployee(),
            render: (text: any, record: any) => {
                if (record && record.person) {
                    return (
                        <div>
                            {record.person.firstName +
                                ' ' +
                                record?.person.lastName +
                                (record?.employee?.employmentRecordId
                                    ? ' (' + record.employee?.employmentRecordId + ')'
                                    : '')}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('attendance.table.column.date'),
            dataIndex: 'dateOfApplication',
            width: '10%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                if (record.dateOfApplication) {
                    return GetCustomAttendanceDateFormat(record.dateOfApplication);
                }
            },
            ...getColumnDateOption(false),
        },
        {
            title: geti18nText('attendance.table.column.hours.sum'),
            dataIndex: ['passedTime'],
            width: '15%',
            sorter: (a: any, b: any) => {},
            render: (text: any, record: any) => {
                return getPassedTimeRender(record);
            },
        },
        {
            dataIndex: ['attendanceRegistration'],
            width: '58%',
            render: (record: any) => {
                return getDailyAttendanceIcons(record);
            },
        },
    ];

    const addedButtons = () => {
        return (
            <>
                {canCreate() && (
                    <div style={{ float: 'right' }}>
                        <Button
                            type="primary"
                            onClick={() => {
                                setEditModalVisible(true);
                            }}
                        >
                            {geti18nText('attendance.table.add')}
                        </Button>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="attendance-list"
                url={CONSTANTS_REQ.ATTENDANCE_REGISTRATION.REGISTRATION_LIST}
                addNewButtonText={geti18nText('attendance.table.add')}
                buttonsClassName="buttons-sticky"
                showRecordModal={true}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={() => []}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                columns={initialAdminColumns}
                scroll={scroll}
                fetchWhenChange={refreshTable}
                hideNewButton
                shortcuts={true}
                CSVFileName={geti18nText('menu.attendance')}
                exportCSV={canExportCSV()}
                colCSVCustomization={csvColumnCustomisation()}
                csvColumns={csvColumns()}
                addedButtons={addedButtons}
            />
            {React.createElement(
                AttendanceRegistrationEdit,
                {
                    isModal: true,
                    visible: editModalVisible,
                    setVisible: setEditModalVisible,
                    value: editModalData,
                    onSave: () => {
                        setEditModalVisible(false);
                        setEditModalData(undefined);
                    },
                    onSaveAndGetID: () => {
                        setEditModalVisible(false);
                        setEditModalData(undefined);
                    },
                    onSaveAndGetData: () => {
                        setRefreshTable((prevValue: any) => prevValue + 1);
                        setEditModalVisible(false);
                        setEditModalData(undefined);
                    },
                    onModalClose: () => {
                        setEditModalVisible(false);
                        setEditModalData(undefined);
                    },
                },
                null
            )}
        </>
    );
};

export default AttendanceListIndex;

export const csvColumnCustomisation: any = () => {
    return [
        {
            dateOfApplication: (value: any) => {
                return value
                    ? moment(value).format('dddd').charAt(0).toUpperCase() +
                          moment(value).format('dddd').slice(1) +
                          moment(value).format(', DD.MM.YYYY')
                    : '';
            },
        },
        {
            passedTime: (value: any) => {
                if (value) {
                    const [hours, minutes, seconds] = value?.split(':');
                    const time = `${hours}:${minutes}`;
                    return time;
                }
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('attendance.table.column.firstName'),
            dataIndex: 'firstName',
        },
        {
            title: geti18nText('attendance.table.column.lastName'),
            dataIndex: 'lastName',
        },
        {
            title: geti18nText('attendance.table.column.employmentRecordId'),
            dataIndex: 'employmentRecordId',
        },
        {
            title: geti18nText('attendance.table.column.date'),
            dataIndex: 'dateOfApplication',
        },
        {
            title: geti18nText('attendance.table.column.hours.sum'),
            dataIndex: 'passedTime',
        },
    ];
};
