import { CloseCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    NyDataTable,
    NyModalConfirm,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchCheckbox,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useHelper from '../../../../../hooks/useHelper';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../../utils/Constants';
import { getColumnSearchByCodebookEmployee, getColumnSearchByCodebookSimple } from '../../../../../utils/Filters';
import {
    errorNotification,
    fileDownload,
    getDateFormat,
    getEnumArrayStatus,
    getMealOrderStatusIcon,
    okNotification,
} from '../../../../../utils/Utils';
import MealOrderWizard from '../meals-overview/wizard';
import { GetEnum } from '../../../../../utils/Enums';
import useApplicationSetting from '../../../../../hooks/useApplicationSetting';
import useEnum from '../../../../../hooks/useEnum';
import { AdministrationRights } from '../../../../../rights/administrationRights';
import { MealsRights } from '../../../../../rights/mealsRights';

const MealOrdersIndex = ({ scroll = { y: 540, x: 800 }, sortOrder }: any) => {
    const [refreshTable, setRefreshTable] = useState(1);
    const { mealSyncTypeHNB } = useSelector((state: RootState) => state.applicationSettings);

    const mealOrderCancelTime = useApplicationSetting('MEALS_ORDER_CANCELLATION_TIME');

    const mealOrderStatusEnum = GetEnum({ enumName: 'MEAL_ORDER_STATUS' });
    const enDeliveryType = useEnum('DELIVERY_TYPE');

    const table = useTableSettings();
    useHelper('meals/codebooks/meal');

    const canCreate = () => {
        return MealsRights.isMealsAdmin();
    };

    const canExportCSV = () => {
        return MealsRights.isMealsAdmin();
    };

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    useEffect(() => {}, []);

    const deactivateMealOrder = (e: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.MEAL_ORDER.CHANGE_STATUS + '/' + e, undefined, {
            status: mealOrderStatusEnum.CANCELED,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                okNotification();
                setRefreshTable((prevValue: any) => prevValue + 1);
            } else {
                if (result.data && result.data.error) {
                    errorNotification(result.data.error);
                } else {
                    errorNotification();
                }
            }
        });
    };

    const checkIfDeactivateable = (orderDate: any) => {
        if (moment().isBefore(moment(orderDate).add(mealOrderCancelTime, 'hours'))) {
            return true;
        } else {
            return false;
        }
    };

    const columns = [
        ...[
            {
                title: geti18nText('meal.menu.table.column.id'),
                dataIndex: 'id',
                width: '4%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('meal.delivery.table.column.person'),
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookEmployee(),
                width: '10%',
                dataIndex: [`concat(person.first_name,' ', person.last_name)`],
                render: (text: any, record: any) => {
                    if (record.person) {
                        return (
                            record.person.firstName +
                            ' ' +
                            record.person.lastName +
                            (record.employee && record.employee.employmentRecordId
                                ? ' (' + record.employee?.employmentRecordId + ')'
                                : '')
                        );
                    }
                },
            },

            {
                title: geti18nText('meal.delivery.table.column.orderDate'),
                dataIndex: 'orderDate',
                width: '9%',
                sorter: (a: any, b: any) => {},
                ...getColumnDateOption(false),
                render: (name: any, record: any) => {
                    if (record && record.orderDate) {
                        return moment(record.orderDate).format('DD.MM.YYYY');
                    }
                },
            },
        ],
        ...(AdministrationRights.isAdmin()
            ? [
                  {
                      title: geti18nText('meal.order.table.column.orderNumber'),
                      dataIndex: ['orderNumber'],
                      sorter: (a: any, b: any) => {},

                      ...getColumnSearch('number'),
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('meal.delivery.table.column.created'),
                dataIndex: 'created',
                render: (name: any, record: any) => {
                    if (record && record.created) {
                        return getDateFormat(record.created, 'DD.MM.YYYY HH:mm');
                    }
                },
                ...getColumnDateOption(true),
            },
            {
                title: geti18nText('meal.delivery.table.column.updated'),
                dataIndex: 'updated',
                render: (name: any, record: any) => {
                    if (record && record.updated) {
                        return getDateFormat(record.updated, 'DD.MM.YYYY HH:mm');
                    }
                },
                ...getColumnDateOption(true),
            },
            {
                title: geti18nText('meal.delivery.table.column.restaurant'),
                dataIndex: [`concat(delivery_spot_restaurant.name,' ', restaurant.name)`],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookSimple(
                    CONSTANTS_REQ.RESTAURANT.SEARCH,
                    'delivery_spot_restaurant.id',
                    'name',
                    'name',
                    {
                        id: 'id',
                        label: 'name',
                    }
                ),
                render: (name: any, record: any) => {
                    if (record && record.restaurant && record.restaurant.name) {
                        return record.restaurant.name;
                    }
                },
            },
            {
                title: geti18nText('meal.delivery.table.column.deliverySpot'),
                dataIndex: [`concat(deliverySpot.name,' ', restaurant.name)`],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookSimple(
                    CONSTANTS_REQ.DELIVERY_SPOT.SEARCH_ALL_NO_DATE,
                    'deliverySpot.id',
                    'deliverySpot.name',
                    'deliverySpot.name',
                    {
                        id: 'deliverySpotId',
                        name: 'delivery.name',
                        //label: 'delivery.name',
                        text: 'delivery.name',
                    }
                ),
                render: (name: any, record: any) => {
                    if (record?.deliverySpotTime?.type == enDeliveryType.DELIVERY && record?.deliverySpot?.name) {
                        return record.deliverySpot.name;
                    } else if (record?.deliverySpotTime?.type == enDeliveryType.PICKUP && record?.restaurant?.name) {
                        return record.restaurant.name;
                    }
                },
            },

            {
                title: geti18nText('meal.delivery.table.column.deliverySpot.time'),
                dataIndex: ['deliverySpotTime', 'time'],
                width: '10%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
                render: (name: any, record: any) => {
                    if (record && record.deliverySpotTime && record.deliverySpotTime.time) {
                        return record.deliverySpotTime.time;
                    }
                },
            },
            {
                title: geti18nText('meal.delivery.table.column.deliverySpot.type'),
                dataIndex: ['deliverySpotTime', 'type'],
                sorter: (a: any, b: any) => {},
                width: '8%',
                ...getColumnSearchCheckbox(getEnumArrayStatus('DELIVERY_TYPE')),
                render: (name: any, record: any) => {
                    if (record && record.deliverySpotTime && record.deliverySpotTime.type) {
                        return geti18nText('app.enum.DELIVERY_TYPE.' + record.deliverySpotTime.type);
                    }
                },
            },
            {
                title: geti18nText('travelWarrant.table.column.status'),
                dataIndex: 'status',
                width: '170px',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchCheckbox(getEnumArrayStatus('MEAL_ORDER_STATUS')),
                render: (status: any) => {
                    return getMealOrderStatusIcon(status);
                },
            },
        ],

        ...(mealSyncTypeHNB
            ? [
                  {
                      title: geti18nText('meal.delivery.table.column.deliverySpot.totalAdvance'),
                      dataIndex: 'totalAdvance',
                      align: 'right',
                      width: '5%',
                      ...getColumnSearch('number'),
                      render: (text: any, record: any) => {
                          return (
                              NyUtils.formatNumber(record.totalAdvance ?? 0, 2) +
                              ' ' +
                              (record.currency && record.currency.isoCode ? record.currency.isoCode : '')
                          );
                      },
                  },
                  {
                      title: geti18nText('meal.delivery.table.column.cancel'),
                      width: '6%',
                      align: 'center',
                      render: (record: any) => {
                          if (
                              canCreate() &&
                              record?.status != mealOrderStatusEnum.CANCELED &&
                              checkIfDeactivateable(record.orderDate)
                          ) {
                              return (
                                  <Tooltip title={geti18nText('meal.delivery.table.tooltip.cancel')}>
                                      <NyModalConfirm
                                          title={geti18nText('meal.delivery.table.cancel.question')}
                                          onConfirm={() => deactivateMealOrder(record.id)}
                                      >
                                          <CloseCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
                                      </NyModalConfirm>
                                  </Tooltip>
                              );
                          }
                      },
                  },
                  {
                      title: geti18nText('meal.delivery.table.column.bill'),
                      dataIndex: 'billFileId',
                      width: '5%',
                      render: (text: any, record: any) => {
                          if (record) {
                              return (
                                  <div style={{ textAlign: 'center' }}>
                                      {record.billFileId && (
                                          <Tooltip title={geti18nText('meal.delivery.table.column.deliverySpot.bill')}>
                                              <Button
                                                  icon={<FilePdfOutlined />}
                                                  onClick={() => {
                                                      fileDownload(
                                                          CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.billFileId,
                                                          null,
                                                          geti18nText('meal.delivery.edit.collapse.billFileId') +
                                                              ' ' +
                                                              getDateFormat(record.billingTimestamp, 'DDMMYYYY - HH:mm')
                                                      );
                                                  }}
                                              >
                                                  {geti18nText('meal.delivery.table.column.deliverySpot.bill')}
                                              </Button>
                                          </Tooltip>
                                      )}
                                  </div>
                              );
                          }
                      },
                  },
              ]
            : [
                  {
                      title: geti18nText('meal.delivery.table.column.deliverySpot.totalAdvance'),
                      dataIndex: 'totalPrice',
                      align: 'right',
                      ...getColumnSearch('number'),
                      width: '5%',
                      render: (text: any, record: any) => {
                          return (
                              NyUtils.formatNumber(record.totalPrice ?? 0, 2) +
                              ' ' +
                              (record.currency && record.currency.isoCode ? record.currency.isoCode : '')
                          );
                      },
                  },
                  {
                      title: geti18nText('meal.delivery.table.column.cancel'),
                      width: '6%',
                      align: 'center',
                      render: (record: any) => {
                          if (
                              canCreate() &&
                              record?.status != mealOrderStatusEnum.CANCELED &&
                              checkIfDeactivateable(record.orderDate)
                          ) {
                              return (
                                  <NyModalConfirm
                                      title={geti18nText('meal.delivery.table.cancel.question')}
                                      onConfirm={() => deactivateMealOrder(record.id)}
                                  >
                                      <Tooltip title={geti18nText('meal.delivery.table.tooltip.cancel')}>
                                          <CloseCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
                                      </Tooltip>
                                  </NyModalConfirm>
                              );
                          }
                      },
                  },
              ]),
    ];

    return (
        <NyDataTable
            nyId="meal-order-table"
            url={CONSTANTS_REQ.MEAL_ORDER.LIST}
            buttonsClassName="buttons-sticky"
            showRecordModal={true}
            modalComponent={MealOrderWizard}
            columns={columns}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            scroll={scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            hideNewButton={true}
            shortcuts={true}
            exportCSV={canExportCSV()}
            CSVFileName={geti18nText('menu.meals.order.menu')}
            colCSVCustomization={csvColumnCustomisation()}
            csvColumns={mealSyncTypeHNB ? csvColumnsHNB() : csvColumns()}
            fetchWhenChange={refreshTable}
            editProps={{
                setRefreshTable: setRefreshTable,
                refreshTable: refreshTable,
            }}
            headerTitle={geti18nText('menu.meals.order.menu')}
        />
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            orderDate: (value: any) => {
                return value != undefined && new Date(value).toLocaleDateString(NyUtils.getSelectedLocale());
            },
        },
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.MEAL_ORDER_STATUS.' + value);
            },
        },
        {
            totalPrice: (value: any) => {
                return NyUtils.formatNumber(value ?? 0, 2);
            },
        },
        {
            totalAdvance: (value: any) => {
                return NyUtils.formatNumber(value ?? 0, 2);
            },
        },
        {
            person: (value: any) => {
                return value != undefined && value.firstName + ' ' + value.lastName + ' (' + value.id + ')';
            },
        },
        {
            deliverySpotTime: (value: any) => {
                return value != undefined && value.type && geti18nText('app.enum.DELIVERY_TYPE.' + value.type);
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('meal.menu.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('meal.delivery.table.column.person'),
            dataIndex: 'person',
        },
        {
            title: geti18nText('meal.order.table.column.orderDate'),
            dataIndex: 'orderDate',
        },
        {
            title: geti18nText('meal.delivery.table.column.restaurant'),
            dataIndex: ['restaurant', 'name'],
        },
        {
            title: geti18nText('meal.order.table.column.deliverySpot'),
            dataIndex: ['deliverySpot', 'name'],
        },
        {
            title: geti18nText('meal.order.table.column.deliverySpotTime'),
            dataIndex: ['deliverySpotTime', 'time'],
        },
        {
            title: geti18nText('meal.delivery.table.column.deliverySpot.type'),
            dataIndex: 'deliverySpotTime',
        },
        {
            title: geti18nText('meal.order.table.column.status'),
            dataIndex: 'status',
        },
        {
            title: geti18nText('meal.order.table.column.amount'),
            dataIndex: 'totalPrice',
        },
    ];
};

export const csvColumnsHNB: any = () => {
    return [
        {
            title: geti18nText('meal.menu.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('meal.delivery.table.column.person'),
            dataIndex: 'person',
        },
        {
            title: geti18nText('meal.order.table.column.orderDate'),
            dataIndex: 'orderDate',
        },
        {
            title: geti18nText('meal.delivery.table.column.restaurant'),
            dataIndex: ['restaurant', 'name'],
        },
        {
            title: geti18nText('meal.order.table.column.deliverySpot'),
            dataIndex: ['deliverySpot', 'name'],
        },
        {
            title: geti18nText('meal.order.table.column.deliverySpotTime'),
            dataIndex: ['deliverySpotTime', 'time'],
        },
        {
            title: geti18nText('meal.delivery.table.column.deliverySpot.type'),
            dataIndex: 'deliverySpotTime',
        },
        {
            title: geti18nText('meal.order.table.column.status'),
            dataIndex: 'status',
        },
        {
            title: geti18nText('meal.order.table.column.amount'),
            dataIndex: 'totalAdvance',
        },
    ];
};

export default MealOrdersIndex;
