import {
    CaretLeftOutlined,
    CaretRightOutlined,
    DownloadOutlined,
    FilePdfOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import {
    NyDataEdit,
    NyModalConfirm,
    NyRequestResolver,
    NySession,
    NySpinner,
    RESPONSE,
    geti18nText,
} from '@nybble/nyreact';
import { Button, Col, Divider, Form, Row, Tooltip, Upload, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NyHistoryModal from '../../../../../components/history';
import useEnum from '../../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../utils/Constants';
import {
    downloadFileCustom,
    errorNotification,
    fileDownload,
    getCanCreateContract,
    getDateFormat,
    getEmployeeSelectOption,
    getEnumFormat,
    getErrorNotificationMessage,
    getSearchFormat,
    okNotification,
    setDateFormat,
    setEnumFormat,
    setSearchFormat,
} from '../../../../../utils/Utils';
import ContractSelectMetadataIndex from '../contract-metadata/ContractSelectMetadataIndex';
import ContractTypeSelectIndex from '../contract-type/ContractTypeSelectIndex';
import ContractTypeSearch from '../contract-type/search';
import GeneralEditData from './GeneralEditData';
import SummaryData from './SummaryData';

const ContractCTREdit = (props: any) => {
    const enContractMetadata = useEnum('CONTRACT_METADATA');
    const [editHeader, setEditHeader] = useState(
        props?.editProps?.annex ? geti18nText('contract.type.edit.new.annex') : geti18nText('contract.edit.new')
    );
    const enContractStatus = useEnum('CONTRACT_STATUS');
    const focusInput = useRef<any>(null);
    const history = useHistory();
    const [form] = Form.useForm();
    const [objectMetadataForm] = Form.useForm();
    const { id } = useParams<any>();
    const [loading, setLoading] = useState(false);
    const [triggerCloseModal, setTriggerCloseModal] = useState<boolean>(false);
    const [canCreate, setCanCreate] = useState(false);
    //form
    const [current, setCurrent] = useState<any>(0);
    const [dataForm, setDataForm] = useState(null);
    const [status, setStatus] = useState(null);
    const [contractType, setContractType] = useState<any>(null);
    const [refreshContractType, setRefreshContractType] = useState<any>(0);
    const [contractDocumentTemplate, setContractDocumentTemplate] = useState<any>(null);
    const [partyTwo, setPartyTwo] = useState<any>(null);
    const [validFrom, setValidFrom] = useState<any>(null);
    const [validTo, setValidTo] = useState<any>(null);
    const [dateOfConclusion, setDateOfConclusion] = useState<any>(null);
    const [contractEmployee, setContractEmployee] = useState<any>(null);
    const [newEmployee, setNewEmployee] = useState<any>(null);
    //files
    const [files, setFiles] = useState<any>([]);
    const [uploadFile, setUploadFile] = useState<any>(undefined);
    const [contractDocuments, setContractDocuments] = useState<any>([]);

    // metadata
    const [contractTypeMetadata, setContractTypeMetadata] = useState<any>(undefined);
    const [contractMetadata, setContractMetadata] = useState<any>(undefined);
    const [employeeMetadata, setEmployeeMetadata] = useState<any>(undefined);
    const [triggerGetKeys, setTriggerGetKeys] = useState<any>(0);
    const [newTemplate, setNewTemplate] = useState<boolean>(true);
    const [tabKey, setTabKey] = useState<any>('overview');
    const [checkFormChange, setCheckFormChange] = useState<any>(true);
    const [isCreate, setIsCreate] = useState<boolean>(true);

    useEffect(() => {
        if (props?.editProps?.documentTemplateFromContractType?.id && !contractDocumentTemplate) {
            setTemplateValues(props?.editProps?.documentTemplateFromContractType);
        }
        if (props?.editProps?.contractType?.id && !contractDocumentTemplate) {
            setContractType(props?.editProps?.contractType);
            form.setFieldsValue({ name: props?.editProps?.contractType?.name });
            getContractTypeVale(props?.editProps?.contractType);
        }
    }, [props]);

    useEffect(() => {
        const employeeId =
            props?.editProps?.contractMetadataEmployee?.id != null
                ? props.editProps.contractMetadataEmployee?.id
                : props?.editProps?.employeeId != null
                ? props.editProps.employeeId
                : undefined;
        if (props?.editProps?.contractMetadataEmployee?.id && props?.editProps?.annex) {
            setContractEmployee(props.editProps.contractMetadataEmployee);
        }
        if (employeeId && !dataForm) {
            fetchEmployeeData(employeeId);
            if (contractType?.id) {
                employeeOnChange(
                    {
                        id: employeeId,
                    },
                    isCreate
                );
            }
        }
    }, [props, contractType]);

    useEffect(() => {
        if (dataForm) {
            getContractFiles();
        }
    }, [dataForm]);

    useEffect(() => {
        getCanCreate();
    }, []);

    const getCanCreate = async () => {
        getCanCreateContract(setCanCreate, props?.editProps?.mark, 'HUMAN_RESOURCES');
    };

    const isNew = () => {
        return !dataForm && !props?.editProps?.contractType?.id;
    };

    const isNewAndHasType = () => {
        return !dataForm && props?.editProps?.contractType?.id;
    };

    const isSummary = () => {
        return dataForm && status == enContractStatus.SIGNED && uploadFile !== undefined;
    };

    function setValues(dataForm: any, setEmployeeValue: boolean = true) {
        setDataForm(dataForm.id);
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
        setIsCreate(false);
        if (dataForm.hasOwnProperty('name')) {
            let label = props?.editProps?.annex
                ? geti18nText('contract.type.edit.title.annex')
                : geti18nText('contract.edit.title');
            setEditHeader(label + ' - ' + dataForm.name);
        }
        if (dataForm.contractType) {
            setContractType(dataForm.contractType);
        }
        if (dataForm.status) {
            setStatus(dataForm?.status);
            dataForm.status = setEnumFormat('CONTRACT_STATUS', dataForm.status);
        }
        if (dataForm.partyTwo) {
            dataForm.partyTwo = Number(dataForm.partyTwo);
            setPartyTwo(dataForm.partyTwo);
        } else if (dataForm?.contractType?.contractTypeGroup?.partyTwoCategory) {
            setPartyTwo(Number(dataForm?.contractType?.contractTypeGroup?.partyTwoCategory));
        }
        if (dataForm.employee) {
            if (setEmployeeValue) {
                if (dataForm.employee?.person?.firstName) {
                    dataForm.employee = getEmployeeSelectOption(dataForm.employee);
                    setContractEmployee(dataForm.employee);
                    setNewEmployee(dataForm.employee);
                } else {
                    setContractEmployee(dataForm.employee);
                    setNewEmployee(dataForm.employee);
                    dataForm.employee = fetchEmployeeData(dataForm.employee.id);
                }
            }
        }
        if (dataForm.validFrom) {
            dataForm.validFrom = setDateFormat(dataForm.validFrom);
            setValidFrom(dataForm.validFrom);
        }
        if (dataForm.validTo) {
            dataForm.validTo = setDateFormat(dataForm.validTo);
            setValidTo(dataForm.validTo);
        }
        if (dataForm.dateOfConclusion) {
            dataForm.dateOfConclusion = setDateFormat(dataForm.dateOfConclusion);
            setDateOfConclusion(dataForm.dateOfConclusion);
        }
        if (dataForm.contract) {
            dataForm.contract = setSearchFormat(dataForm.contract, 'name', 'name', 'contractNumber');
        }
        if (dataForm?.remark?.length > 0) {
            dataForm.remark = dataForm.remark.replaceAll('#\\n', '\n');
        }

        //contractDocuments
        if (dataForm.contractDocuments != undefined) {
            setContractDocuments(dataForm.contractDocuments);
            let uploadedFile = dataForm.contractDocuments.find((file: any) => file.active);
            if (uploadedFile) {
                setUploadFile(uploadedFile?.fileId ?? null);
            }
        }
        //contractDocumentTemplate
        setTemplateValues(dataForm?.contractDocumentTemplate);

        //metadata
        setContractTypeMetadata(contractTypeMetadata ?? dataForm.contractTypeMetadata ?? []);
        if (dataForm.contractMetadataValuesList) {
            setContractMetadata(dataForm.contractMetadataValuesList ?? []);
            setEmployeeMetadata(dataForm.contractMetadataValuesList ?? []);
        } else if (dataForm.employee) {
            employeeOnChange(dataForm.employee, false);
        }
        //contractNumber
        if (dataForm.employee && props?.editProps?.employeeId) {
            getContractNumberValue(dataForm.employee.id, false, dataForm.contractNumber);
        }
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
        if (!dataForm?.contractDocumentTemplate) {
            getContractTypeVale(dataForm?.contractType);
        }
    }

    const onModalClose = () => {
        form.resetFields();
        objectMetadataForm.resetFields();
        setEditHeader(
            props?.editProps?.annex ? geti18nText('contract.type.edit.title.annex') : geti18nText('contract.edit.new')
        );
        setContractDocumentTemplate(null);
        setFiles([]);
        setContractType(null);
        setStatus(null);
        setCurrent(0);
        setPartyTwo(null);
        setContractDocuments([]);
        setUploadFile(undefined);
        setValidFrom(null);
        setValidTo(null);
        setDateOfConclusion(null);
        setDataForm(null);
        setNewTemplate(true);
        setContractEmployee(null);
        setNewEmployee(null);
        setIsCreate(true);
    };

    const onModalOpen = () => {
        setTimeout(() => {
            if (focusInput.current) {
                focusInput.current.focus();
            }
        });
    };

    const getContractNumberValue = (employeeId: any, generateContractNumber: boolean, contractNumberValue?: any) => {
        if (employeeId > 0) {
            if (generateContractNumber) {
                NyRequestResolver.requestGet(
                    CONSTANTS_REQ.CONTRACT_HR.GENERATE_CONTRACT_NUMBER + '/' + employeeId
                ).then((res: any) => {
                    if (res?.status === RESPONSE.OK && res?.data?.length == 1 && res?.data[0]) {
                        form.setFieldsValue({ contractNumber: res?.data[0] });
                    }
                });
            } else if (contractNumberValue) {
                form.setFieldsValue({ contractNumber: contractNumberValue });
            }
        } else {
            form.setFieldsValue({ contractNumber: null });
        }
    };

    const employeeOnChange = (value: any, generateContractNumber: boolean = true) => {
        setEmployeeMetadata(undefined);
        setNewEmployee(value);
        if (value?.id && contractType?.id) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.CONTRACT_TYPE.DEFAULT_VALUES_EMPLOYEE, {
                contractTypeId: contractType.id,
                employeeId: value.id,
            }).then((result: any) => {
                if (result.status == RESPONSE.OK && result?.data) {
                    setEmployeeMetadata(result.data);
                } else {
                    setEmployeeMetadata(undefined);
                }
            });
        }
        getContractNumberValue(value.id, generateContractNumber);
    };

    const setTemplateValues = (value: any) => {
        setContractDocumentTemplate(value);
        setNewTemplate(value?.id ? false : true);
        if (value?.fileId) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + value.fileId + '?lastmod=' + new Date().valueOf()
            ).then((result: any) => {
                if (result.status === RESPONSE.OK && result.data) {
                    let filesTemp = [
                        {
                            id: value.fileId,
                            uid: value.fileId,
                            name: value.name,
                            url: CONSTANTS_REQ.FILES.DOWNLOAD + '/' + value.fileId + '?lastmod=' + new Date().valueOf(),
                        },
                    ];
                    setFiles(filesTemp);
                }
            });
        } else {
            setFiles([]);
        }
    };

    const fetchEmployeeData = (employeeId: any, returnValue: boolean = false) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.EDIT + '/' + employeeId).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                const tempEmployee: any = getEmployeeSelectOption(result.data);
                if (returnValue) {
                    return tempEmployee;
                } else {
                    form.setFieldsValue({ employee: tempEmployee });
                }
            } else {
                if (returnValue) {
                    return null;
                } else {
                    form.setFieldsValue({ employee: null });
                }
            }
        });
    };

    const getContractFiles = () => {
        if (dataForm) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.CONTRACT_CTR.DOCUMENT_LIST + '/' + dataForm).then(
                (result: any) => {
                    if (result.status == RESPONSE.OK && result?.data?.content) {
                        setContractDocuments(result.data.content);
                    }
                }
            );
        }
    };

    const prev = () => {
        setCurrent(current - 1);
        setRefreshContractType(refreshContractType + 1);
    };

    const getContractTypeVale = (value: any) => {
        if (value) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.CONTRACT_TYPE.EDIT + '/' + value?.id, undefined).then(
                (result: any) => {
                    if (result.status === RESPONSE.OK && result?.data) {
                        setPartyTwo(result.data?.contractTypeGroup?.partyTwoCategory);
                        setContractTypeMetadata(result?.data?.contractTypeMetadataList ?? []);
                        setTemplateValues(result?.data?.documentTemplate);
                    }
                }
            );
            setCurrent(current + 1);
        }
    };

    const next = () => {
        form.validateFields().then((values: any) => {
            getContractTypeVale(contractType);
        });
    };

    const getTypeForm = () => (
        <>
            <Form
                layout="vertical"
                form={form}
                onFinishFailed={({ errorFields }) => {
                    form.scrollToField(errorFields[0].name);
                }}
            >
                {steps[current].content}
            </Form>
        </>
    );

    const getContractType = () => (
        <Row gutter={24}>
            <Col span={24}>
                <ContractTypeSearch hide />
                {React.cloneElement(
                    ContractTypeSelectIndex(
                        form,
                        contractType,
                        setContractType,
                        refreshContractType,
                        props?.editProps?.annex,
                        'HR',
                        true
                    )
                )}
            </Col>
        </Row>
    );
    const getGeneralEditData = () => (
        <>
            <GeneralEditData
                form={form}
                canCreate={canCreate}
                files={files}
                setFiles={setFiles}
                contractType={contractType}
                partyTwo={partyTwo}
                validFrom={validFrom}
                setValidFrom={setValidFrom}
                validTo={validTo}
                setValidTo={setValidTo}
                dateOfConclusion={dateOfConclusion}
                setDateOfConclusion={setDateOfConclusion}
                setNewTemplate={setNewTemplate}
                setEmployeeMetadata={setEmployeeMetadata}
                employeeOnChange={employeeOnChange}
                contractId={dataForm}
                employeeId={props?.editProps?.employeeId}
            />
            {contractTypeMetadata?.length > 0 && (
                <>
                    <Divider plain style={{ marginTop: '15px' }}>
                        {geti18nText('contract.edit.metadata')}
                    </Divider>
                    <Row gutter={24}>
                        <Col span={24}>
                            <ContractSelectMetadataIndex
                                objectMetadataForm={objectMetadataForm}
                                metadata={contractMetadata}
                                metadataTemplate={contractTypeMetadata}
                                employeeMetadata={employeeMetadata}
                                personalView={props?.editProps?.employeeId > 0 ? true : false}
                                contractEmployeeId={contractEmployee?.id}
                                newEmployeeId={newEmployee?.id}
                                annex={props?.editProps?.annex}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
    const getSummaryData = () => (
        <SummaryData
            form={form}
            partyTwo={partyTwo}
            files={files}
            setFiles={setFiles}
            setContractDocumentTemplate={setContractDocumentTemplate}
            metadata={contractMetadata}
            contractId={dataForm}
            tabKey={tabKey}
            setTabKey={setTabKey}
            contractEmployee={contractEmployee}
        />
    );

    const steps = [
        {
            content: <>{getContractType()}</>,
            key: 'contractType',
        },
        {
            content: <>{getGeneralEditData()}</>,
            key: 'generalEditData',
        },
    ];

    const uploadSigned = (file: any) => {
        if (file?.id && dataForm) {
            NyRequestResolver.requestPost(CONSTANTS_REQ.CONTRACT_CTR.UPLOAD_SIGNED, undefined, {
                id: dataForm,
                fileId: file?.id,
            }).then((result: any) => {
                if (result.status === RESPONSE.CREATED) {
                    okNotification();
                    props?.editProps?.setRefresh(props?.editProps?.refresh + 1);
                    setStatus(enContractStatus.SIGNED);
                } else {
                    getErrorNotificationMessage(result);
                }
            });
        }
    };

    const upload = {
        name: 'file',
        action: CONSTANTS_REQ.FILES.UPLOAD,
        showUploadList: false,
        multiple: false,
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onChange(info: any) {
            if (info.file.status === 'uploading') {
                setLoading(true);
                message.destroy();
                message.loading(`${info.file.name} ${geti18nText('import.text.uploading')}`);
            }
            if (info.file.status === 'done') {
                setLoading(false);
                message.destroy();
                if (info.file.response) {
                    const result = info.file.response;
                    info.file.id = result;
                    setUploadFile(result);
                    uploadSigned(info.file);
                    getContractFiles();
                }
            } else if (info.file.status === 'error') {
                setLoading(false);
                message.destroy();
                message.error(`${info.file.name} ${geti18nText('import.text.error')}`);
            }
        },
    };

    const generateDocument = () => {
        if (dataForm) {
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.CONTRACT_CTR.CREATE_DOCUMENT + '/' + dataForm,
                undefined,
                false,
                true
            ).then((result: any) => {
                if (result?.status == RESPONSE.OK || result?.status == RESPONSE.CREATED) {
                    downloadFileCustom(result);
                    props?.editProps?.setRefresh(props?.editProps?.refresh + 1);
                    setStatus(enContractStatus.CREATED);
                } else {
                    errorNotification();
                }
            });
        }
    };

    const normalizeMetadata = async () => {
        let metadataReturn: any = [];
        await objectMetadataForm.validateFields().then(async (metadataValues: any) => {
            if (metadataValues) {
                for (const [key, value] of Object.entries(metadataValues)) {
                    if (key != undefined && value != undefined) {
                        const metadataItem: any = contractTypeMetadata.find(
                            (item: any) => item?.contractMetadata && item?.contractMetadata?.name === key
                        );
                        let valueItem: any = value;
                        let codebookValue: any = undefined;
                        if (metadataItem?.contractMetadata?.dataType === enContractMetadata.DATE) {
                            valueItem = getDateFormat(value, 'yyyy-MM-DD HH:mm:ss');
                        } else if (metadataItem?.contractMetadata?.dataType === enContractMetadata.CODEBOOK) {
                            valueItem = valueItem?.name ?? valueItem?.text;
                            codebookValue = JSON.stringify(value);
                        }
                        await metadataReturn.push({
                            id: metadataItem.id,
                            contract: { id: dataForm },
                            contractMetadata: {
                                id: metadataItem?.contractMetadata?.id,
                                dataType: metadataItem?.contractMetadata?.dataType,
                            },
                            value: valueItem,
                            codebookValue: codebookValue,
                            documentPlaceholder: metadataItem?.documentPlaceholder,
                        });
                    }
                }
            }
        });
        return metadataReturn;
    };

    const normalizeTemplate = async () => {
        if (files?.length > 0 && files[0]) {
            let templateId = contractDocumentTemplate?.id ?? null;
            if (contractDocumentTemplate?.id && files[0] && !newTemplate) {
                await NyRequestResolver.requestPut(
                    CONSTANTS_REQ.CONTRACT_DOCUMENT_TEMPLATE.EDIT + '/' + templateId,
                    undefined,
                    {
                        id: contractDocumentTemplate.id,
                        fileId: files[0].id,
                        name: files[0].name,
                        status: 2,
                        parent: contractDocumentTemplate?.parent?.id
                            ? { id: contractDocumentTemplate?.parent?.id }
                            : null,
                        version: contractDocumentTemplate?.version ? contractDocumentTemplate?.version + 1 : 1,
                    }
                );
            } else if (newTemplate) {
                await NyRequestResolver.requestPost(CONSTANTS_REQ.CONTRACT_DOCUMENT_TEMPLATE.EDIT, undefined, {
                    fileId: files[0].id,
                    name: files[0].name,
                    status: 2,
                    parent: contractDocumentTemplate?.id ? { id: contractDocumentTemplate?.id } : null,
                }).then((result: any) => {
                    templateId = result?.data?.id;
                });
            }

            return {
                id: templateId,
                fileId: files[0].id,
                name: files[0].name,
            };
        } else {
            return {
                id: contractDocumentTemplate?.id,
            };
        }
    };
    const normalize = (values: any) => {
        //form
        if (values.status) values.status = getEnumFormat(values.status);
        if (values.partyTwo) values.partyTwo = getEnumFormat(values.partyTwo);
        if (values.employee) values.employee = getSearchFormat(values.employee);
        if (values.contract) values.contract = getSearchFormat(values.contract);
        if (values.dateOfConclusion) values.dateOfConclusion = getDateFormat(values.dateOfConclusion);
        if (values.validFrom) values.validFrom = getDateFormat(values.validFrom);
        if (values.validTo) values.validTo = getDateFormat(values.validTo);
        if (!values.metadataVersion) {
            values.metadataVersion = 1;
        }
        values.annex = props?.editProps?.annex;

        if (props?.editProps?.contractId) {
            values.contract = { id: props.editProps.contractId };
        }

        values.contractType = { id: contractType?.id };
        values.contractDocumentTemplate = {
            id: contractDocumentTemplate?.id,
        };
        if (values?.remark?.length >= 0) {
            values.remark = values.remark.replaceAll('\n', '#\\n');
        }
        return values;
    };

    const save = () => {
        setLoading(true);
        form.validateFields().then(async (values: any) => {
            values = normalize(values);
            if (!values.employee && contractEmployee?.id && props?.editProps?.annex) {
                values.employee = { id: contractEmployee.id };
            }
            //metadata
            const metadataReturn: any = await normalizeMetadata();
            values.contractMetadataValuesList = metadataReturn;
            //template
            const contractDocumentTemplate: any = await normalizeTemplate();
            values.contractDocumentTemplate = contractDocumentTemplate;
            if (values.id) {
                NyRequestResolver.requestPut(CONSTANTS_REQ.CONTRACT_CTR.EDIT + '/' + values.id, undefined, values).then(
                    (result: any) => {
                        if (result && result.status === RESPONSE.CREATED) {
                            okNotification();
                            onModalClose();
                            setTriggerCloseModal(true);
                            props?.editProps?.setRefresh(props?.editProps?.refresh + 1);
                        } else {
                            getErrorNotificationMessage(result);
                        }
                    }
                );
            } else {
                values.status = enContractStatus.DRAFT;
                NyRequestResolver.requestPost(CONSTANTS_REQ.CONTRACT_CTR.EDIT, undefined, values).then(
                    (result: any) => {
                        if (result && result.status === RESPONSE.CREATED) {
                            setDataForm(result.data.id);
                            okNotification();
                            if (result.data?.employee?.id == newEmployee?.id) {
                                result.data.employee = newEmployee;
                                setContractEmployee(newEmployee);
                            }
                            setValues(result.data, false);
                            props?.editProps?.setRefresh(props?.editProps?.refresh + 1);
                        } else {
                            getErrorNotificationMessage(result);
                        }
                    }
                );
            }
        });
        setLoading(false);
    };

    const changeStatus = () => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.CONTRACT_CTR.MARK_NOT_SIGNED + '/' + dataForm, undefined, {
            id: dataForm,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                okNotification();
                onModalClose();
                props?.editProps?.setRefresh(props?.editProps?.refresh + 1);
            }
        });
    };

    const historyColumns = [
        {
            title: geti18nText('assetRelease.history.column.name'),
            dataIndex: 'fileName',
        },
        {
            title: geti18nText('contractDocumentTemplate.table.column.created'),
            dataIndex: 'created',
            render: (uploaded: any) => {
                if (uploaded) {
                    return getDateFormat(uploaded, 'DD.MM.YYYY. HH:mm');
                }
            },
        },
        {
            title: geti18nText('contract.job.documents.table.column.status'),
            dataIndex: 'status',
            width: '180px',
            render: (text: any, record: any) => {
                if (record.status) {
                    return geti18nText('app.enum.CONTRACT_DOCUMENT_STATUS.' + record.status);
                }
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            {record && (record.fileId || record.id) && (
                                <Tooltip title={geti18nText('app.default.download')}>
                                    <FilePdfOutlined
                                        style={{ marginLeft: '10px', fontSize: '16px' }}
                                        onClick={() => {
                                            fileDownload(
                                                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + record.fileId ?? record.id,
                                                null,
                                                record.fileName ?? record.name
                                            );
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    );
                }
            },
        },
    ];

    const getCustomFooterContent = (
        <>
            <div style={{ float: 'left' }}>
                {dataForm && canCreate && !isNew() && (
                    <>
                        {(status == enContractStatus.DRAFT || status == enContractStatus.CREATED) && !isSummary() && (
                            <Tooltip
                                title={
                                    newTemplate && geti18nText('contract.edit.generateDocument.new.version.discovered')
                                }
                            >
                                <Button
                                    style={{ marginRight: '5px' }}
                                    type="primary"
                                    loading={loading}
                                    onClick={generateDocument}
                                >
                                    {geti18nText('contract.edit.generateDocument')}
                                </Button>
                            </Tooltip>
                        )}
                        {uploadFile !== undefined &&
                            status == enContractStatus.SIGNED &&
                            isSummary() &&
                            tabKey == 'overview' && (
                                <>
                                    <NyHistoryModal
                                        columns={historyColumns}
                                        dataSource={contractDocuments}
                                        customColumns={true}
                                    />
                                    <Button
                                        style={{ marginRight: '5px' }}
                                        icon={<DownloadOutlined />}
                                        onClick={() => {
                                            fileDownload(
                                                CONSTANTS_REQ.FILES.DOWNLOAD + '/' + uploadFile,
                                                null,
                                                geti18nText('contract.edit.contract.signed')
                                            );
                                        }}
                                    >
                                        {geti18nText('contract.edit.contract.signed.download')}
                                    </Button>
                                </>
                            )}
                        {status == enContractStatus.CREATED && !isSummary() && (
                            <Upload {...upload} data={{ id: dataForm }}>
                                <Button icon={<UploadOutlined />} disabled={loading} style={{ marginLeft: '5px' }}>
                                    {geti18nText('contract.edit.upload')}
                                </Button>
                            </Upload>
                        )}
                    </>
                )}
            </div>
            <div style={{ float: 'right' }}>
                {isNew() && (
                    <>
                        {current > 0 && (
                            <Button
                                onClick={prev}
                                icon={<CaretLeftOutlined />}
                                style={{ marginRight: contractDocumentTemplate?.id ? '5px' : '10px' }}
                            >
                                {geti18nText('travelWarrantWizard.previous')}
                            </Button>
                        )}
                        {current < steps.length - 1 && (
                            <Button type="primary" onClick={next} disabled={!contractType}>
                                {geti18nText('travelWarrantWizard.next')}
                                <CaretRightOutlined />
                            </Button>
                        )}
                    </>
                )}
                {((current > 0 && !dataForm) || dataForm || isNewAndHasType()) && !isSummary() && canCreate && (
                    <Tooltip title={!contractDocumentTemplate?.id && geti18nText('contract.cannot.save.no.template')}>
                        <Button
                            onClick={save}
                            type="primary"
                            loading={loading}
                            disabled={!contractDocumentTemplate?.id && !files[0]}
                        >
                            {geti18nText('app.default.button.save')}
                        </Button>
                    </Tooltip>
                )}
            </div>
            {!isNew() && isSummary() && HumanResourcesRights.canCreateContractAdmin() && (
                <NyModalConfirm title={geti18nText('contract.edit.message.markNotSigned')} onConfirm={changeStatus}>
                    <Button type="primary" danger>
                        {geti18nText('contract.edit.button.markNotSigned')}
                    </Button>
                </NyModalConfirm>
            )}
        </>
    );

    return (
        <NyDataEdit
            layout="vertical"
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.CONTRACT_CTR.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={true}
            hideActivationButtons={!canCreate || isSummary()}
            customFooterContent={getCustomFooterContent}
            paramsId={id}
            // leaveNewModalOpen={true}
            onBeforeSave={() => {
                setTriggerGetKeys(triggerGetKeys + 1);
            }}
            onSaveAndGetID={(id: any) => {
                setDataForm(id);
            }}
            shortcuts={true}
            onModalOpen={onModalOpen}
            checkIsFormChanged={checkFormChange}
            normalize={normalize}
            triggerCloseModal={triggerCloseModal}
            fetchWhenChange={props?.editProps?.refresh}
            {...props}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <div
                        style={{
                            maxHeight: '70vh',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        }}
                    >
                        {loading ? (
                            <NySpinner />
                        ) : isNew() ? (
                            <>{getTypeForm()}</>
                        ) : isSummary() ? (
                            <>{getSummaryData()}</>
                        ) : (
                            <>{getGeneralEditData()}</>
                        )}
                    </div>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ContractCTREdit;
