import { geti18nText, NyDataEdit, NyDatePicker, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import HumanCodebooksSearch from '../../../../../../components/human-codebooks/search';
import useEnum from '../../../../../../hooks/useEnum';
import { HumanResourcesRights } from '../../../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ } from '../../../../../../utils/Constants';
import NyImageUpload from '../../../../../../utils/ny-file-upload-image';
import { getDateFormat, getEnumArray, setDateFormat, setEnumFormat } from '../../../../../../utils/Utils';

const EmployeeHealthExaminationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('employee.healthExamination.edit.new'));
    const [isCreate, setIsCreate] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(undefined);
    const [files, setFiles] = useState<any>([]);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const enCodebookType = useEnum('HUMAN_CODEBOOKS');

    const canCreate = () => {
        return HumanResourcesRights.isAdmin();
    };

    function setValues(dataForm: any) {
        setEditHeader(geti18nText('employee.healthExamination.edit.header'));
        if (dataForm.fileId) {
            let tempFiles = [
                {
                    id: dataForm.fileId,
                    uid: dataForm.fileId,
                    name: geti18nText('employee.healthExamination.edit.header') + '_' + dataForm.fileId.toString(),
                    url: CONSTANTS_REQ.FILES.DOWNLOAD + '/' + dataForm.fileId + '?lastmod=' + new Date().valueOf(),
                },
            ];
            setFiles(tempFiles);
        } else {
            setFiles([]);
        }
        if (dataForm.inspectionDate) {
            dataForm.inspectionDate = setDateFormat(dataForm.inspectionDate);
        }
        if (dataForm.nextControlExaminationDate) {
            dataForm.nextControlExaminationDate = setDateFormat(dataForm.nextControlExaminationDate);
        }
        dataForm.healthExaminationCategory = dataForm.healthExaminationCategory
            ? setEnumFormat('HEALTH_EXAMINATION_CATEGORY', dataForm.healthExaminationCategory)
            : null;
        //  delete dataForm.active;
        setDataForm(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('employee.healthExamination.edit.new'));
        setFiles([]);
        setDataForm(null);
    };

    return (
        <NyDataEdit
            layout="vertical"
            //    shouldCloseModal={!isCreate}
            leaveNewModalOpen={isCreate}
            setIsCreate={setIsCreate}
            formProps={{ labelCol: { span: 24 }, wrapperCol: { span: 24 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.EMPLOYEE_HEALTH_EXAMINATION.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            hideSubmitButton={!canCreate()}
            hideActivationButtons={!canCreate()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.inspectionDate) values.inspectionDate = getDateFormat(values.inspectionDate);
                if (values.nextControlExaminationDate)
                    values.nextControlExaminationDate = getDateFormat(values.nextControlExaminationDate);
                values.employee = { id: props?.editProps?.employeeId };
                values.healthExaminationType = { id: values.healthExaminationType?.id };
                if (values.healthExaminationType?.id == -1) {
                    delete values.healthExaminationType;
                }
                values.healthExaminationCategory = values.healthExaminationCategory?.id;
                if (values.healthExaminationCategory?.id == -1) {
                    delete values.healthExaminationCategory;
                }
                values.evaluationMedicalCapacity = { id: values.evaluationMedicalCapacity?.id };
                if (values.evaluationMedicalCapacity?.id == -1) {
                    delete values.evaluationMedicalCapacity;
                }
                if (files.length > 0 && files[0]) {
                    values.fileId = files[0].id && files[0].id.toString();
                } else {
                    values.fileId = null;
                }
                return values;
            }}
            onSaveAndGetData={(data: any) => {
                if (!isCreate) {
                    setValues(data);
                }
                setIsCreate(false);
                props?.editProps?.setRefreshTable((prevValue: any) => prevValue + 1);
            }}
            shortcuts={true}
            nyTestId="employee-examination-edit"
            isModal={true}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.healthExamination.table.column.category')}
                        name={'healthExaminationCategory'}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NySearchField
                            options={getEnumArray('HEALTH_EXAMINATION_CATEGORY')}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            className=""
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <HumanCodebooksSearch
                        required={true}
                        label={geti18nText('employee.healthExamination.table.column.type')}
                        codebooksType={enCodebookType.HEALTH_EXAMINATION_TYPE}
                        formItemName={'healthExaminationType'}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.healthExamination.table.column.inspectionDate')}
                        name="inspectionDate"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <NyDatePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('employee.healthExamination.table.column.nextControlExaminationDate')}
                        name="nextControlExaminationDate"
                    >
                        <NyDatePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            {!isCreate ? (
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                            <HumanCodebooksSearch
                                label={geti18nText('employee.healthExamination.table.column.evaluationMedicalCapacity')}
                                codebooksType={enCodebookType.EVALUATION_MEDICAL_CAPACITY}
                                formItemName={'evaluationMedicalCapacity'}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name={'fileId'}
                                label={geti18nText('employee.healthExamination.table.column.pdf')}
                            >
                                <NyImageUpload
                                    files={files}
                                    setFiles={setFiles}
                                    saveOnUpload={true}
                                    accept={'*'}
                                    cropShape={null}
                                    listType={'text'}
                                    maxImage={1}
                                    canDeleteFile={!HumanResourcesRights.canViewEDRModal()}
                                    canUpload={!HumanResourcesRights.canViewEDRModal()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ) : null}
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name={'remark'} label={geti18nText('employee.healthExamination.table.column.remark')}>
                        <TextArea rows={4}></TextArea>
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default EmployeeHealthExaminationEdit;
