import { CheckOutlined, CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import { NyDataTable, getColumnDateOption, getColumnSearch, getColumnSearchOption, geti18nText } from '@nybble/nyreact';
import { Button, Dropdown, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ImportCSVModal from '../../../../components/import-csv-modal';
import useTableSettings from '../../../../hooks/useTableSettings';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getColumnSearchByCodebookBusinessUnit, getColumnSearchWorkplaceDetails } from '../../../../utils/Filters';
import EmployeeWorkObligationEdit from './edit';
import EmployeeMobilizationNew from './new';
import { getEnumBooleanArray } from '../../../../utils/Utils';
import moment from 'moment';

const EmployeeWorkObligationIndex = ({
    scroll = { y: 620, x: 800 },
    sortOrder = { order: 'businessUnit.sort', orderType: 'asc' },
}: any) => {
    const location: any = useLocation();
    const table = useTableSettings();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [item, setItem] = useState<any>();
    const [refresh, setRefresh] = useState(0);
    const [isNew, setIsNew] = useState(false);

    useEffect(() => {
        if (location?.state?.id) {
            setItem(location.state.id);
            setIsModalVisible(true);
            delete location.state;
        }
    }, [location]);

    const canCreate = () => {
        return MobilizationRights.isMobilizationAdmin();
    };

    const columns = [
        {
            title: geti18nText('work.obligation.employee.table.column.lastName'),
            dataIndex: ['person', 'lastName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (employee: any, record: any) => {
                if (record && record.employee && record.employee.person) {
                    return record.employee.person.lastName;
                }
            },
        },
        {
            title: geti18nText('work.obligation.employee.table.column.fatherName'),
            dataIndex: ['person', 'parentName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (employee: any, record: any) => {
                if (record && record.employee && record.employee.person) {
                    return record.employee.person.parentName;
                }
            },
        },
        {
            title: geti18nText('work.obligation.employee.table.column.firstName'),
            dataIndex: ['person', 'firstName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (employee: any, record: any) => {
                if (record && record.employee && record.employee.person) {
                    return record.employee.person.firstName;
                }
            },
        },
        {
            title: geti18nText('work.obligation.employee.table.column.employmentRecordId'),
            dataIndex: ['employee', 'employmentRecordId'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('work.obligation.employee.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(true),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        (record.businessUnit.code ? '(' + record.businessUnit.code + ') ' : '') +
                        record.businessUnit.name
                    );
                }
            },
        },
        {
            title: geti18nText('work.obligation.employee.table.column.vocation'),
            dataIndex: "concat(vocation.name, ' ', vocationDescription.name)",
            sorter: (a: any, b: any) => {},
            ...getColumnSearchWorkplaceDetails(),
            render: (text: any, record: any) => {
                if (record && record.vocation) {
                    return (
                        record.vocation.name ??
                        '' + (record.vocationDescription ? ' - ' + record.vocationDescription.name ?? '' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('employee.mobilization.edit.expirationDateCertification'),
            dataIndex: 'expirationDateCertification',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.expirationDateCertification) {
                    return moment(record.expirationDateCertification).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('employee.mobilization.edit.expirationDate'),
            dataIndex: 'expirationDate',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.expirationDate) {
                    return moment(record.expirationDate).format('DD.MM.YYYY');
                }
            },
        },
        {
            title: geti18nText('work.obligation.employee.table.column.obligated'),
            dataIndex: 'obligated',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
            render: (text: any, record: any) => {
                if (record.obligated) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
    ];

    const onRowSelect = (item: any) => {
        if (item) {
            if (item.employee.id) {
                setItem(item.employee.id);
            }
            onModalOpen();
        }
    };

    const onModalOpen = () => {
        setIsModalVisible(true);
    };

    const addedButtons = (onSave: () => void) => {
        const actionsMenu = (
            <Menu>
                <div style={{ padding: '0px', textAlign: 'left' }}>
                    <div style={{ display: 'block', margin: '5px' }}>
                        <ImportCSVModal url={CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.IMPORT_CSV} onSave={onSave} />
                    </div>
                </div>
            </Menu>
        );

        return (
            <>
                <div style={{ float: 'left', paddingLeft: '15px' }}>
                    <Dropdown key="more" overlay={actionsMenu} trigger={['click']}>
                        <Button type="primary">
                            {geti18nText('app.default.actions')}
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                </div>
                {canCreate() && (
                    <Button
                        type="primary"
                        style={{ float: 'right', marginRight: '15px' }}
                        onClick={() => {
                            setIsNew(true);
                        }}
                    >
                        {geti18nText('employee.table.add')}
                    </Button>
                )}
            </>
        );
    };

    return (
        <>
            <NyDataTable
                nyId="human-employee-mobilization-table"
                url={CONSTANTS_REQ.EMPLOYEE_MOBILIZATION.LIST}
                buttonsClassName={'buttons-sticky'}
                modalComponent={EmployeeWorkObligationEdit}
                showRecordModal
                columns={columns}
                onRowSelect={onRowSelect}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={table.setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                shortcuts={true}
                fetchWhenChange={refresh}
                hideNewButton={true}
                addedButtons={canCreate() ? addedButtons : undefined}
            />
            {isModalVisible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
            {isNew && (
                <EmployeeMobilizationNew
                    isModalVisible={isNew}
                    setIsModalVisible={setIsNew}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
        </>
    );
};

export default EmployeeWorkObligationIndex;
