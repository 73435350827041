import { NySearchField, geti18nText } from '@nybble/nyreact';
import { Form } from 'antd';
import useEnum from '../../../../hooks/useEnum';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import WorkplaceSearchIndex from './searchIndex';
import { customWorkplaceRenderOption } from '../../../../utils/Utils';

const WorkplaceSearch = ({
    formItemName = 'workplace',
    label = geti18nText('menu.workplace'),
    required = false,
    onChange = undefined,
    mode = 'default',
    disabled = false,
    mustGetPopupContainer = false,
    setDefaultFilterValue = null,
}: any) => {
    const enWorkplaceStatus = useEnum('WORKPLACE_STATUS');

    const setDefaultFilterValueWorkplace = () => {
        return setDefaultFilterValue
            ? setDefaultFilterValue()
            : [
                  { field: 'status', condition: 'in', value: [enWorkplaceStatus.CONFIRMED].toString() },
                  { field: 'active', condition: 'equals_bool', value: 1 },
              ];
    };

    return (
        <Form.Item
            label={label}
            name={formItemName}
            rules={[
                {
                    required: required,
                    message: geti18nText('app.default.required'),
                },
            ]}
        >
            <NySearchField
                url={CONSTANTS_REQ.WORKPLACE.SEARCH}
                map={{
                    id: 'id',
                    label: 'text',
                    text: 'name',
                    businessUnit: 'businessUnit.name',
                    vocation: 'vocation.name',
                    vocationID: 'vocation.id',
                    vocationDescription: 'vocationDescription.name',
                    vocationDescriptionID: 'vocationDescription.id',
                    dateFrom: 'dateFrom',
                    dateTo: 'dateTo',
                }}
                searchBy="businessUnit.name"
                order="id"
                orderType="desc"
                onChange={onChange}
                setDefaultFilterValue={setDefaultFilterValueWorkplace}
                nyTestId="workplace"
                SearchPopupComponent={<WorkplaceSearchIndex defaultFilterValue={setDefaultFilterValueWorkplace()} />}
                renderOption={customWorkplaceRenderOption}
                customListWidth={1400}
                mode={mode}
                disabled={disabled}
                mustGetPopupContainer={mustGetPopupContainer}
            />
        </Form.Item>
    );
};

export default WorkplaceSearch;
