import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NySpinner,
    RESPONSE,
} from '@nybble/nyreact';
import { getColumnSearchCheckbox } from '@nybble/nyreact/build/ny-data-table/ColumnFilters';
import { Empty, Radio } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RowAction from '../../../../../components/row-action';
import { RootState } from '../../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_PAGE_SIZE } from '../../../../../utils/Constants';
import { getEnumArrayStatus, getOrderStatusIcon, isAdmin } from '../../../../../utils/Utils';
import ServicesOrderEdit from './edit';
import { getColumnSearchByCodebookBusinessUnit } from '../../../../../utils/Filters';
import useTableSettings from '../../../../../hooks/useTableSettings';
import { ServicesRights } from '../../../../../rights/servicesRights';

const ServicesOrderTable = ({ scroll = { y: 620, x: 800 }, sortOrder }: any) => {
    const { refreshItem } = useSelector((state: RootState) => state.selectedItem);
    const table = useTableSettings();

    const [orderViewId, setOrderViewId] = useState<any>(null);
    const [orderViewLabel, setOrderViewLabel] = useState<any>('');
    const [orderViewList, setOrderViewList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [refreshTable, setRefreshTable] = useState<number>(1);

    useEffect(() => {
        setLoading(true);
        fetchOrderView();
    }, []);

    useEffect(() => {
        setRefreshTable(refreshTable + 1);
    }, [refreshItem, orderViewId]);

    const canCreate = () => {
        return ServicesRights.canCreateOrder();
    };

    const canExportCSV = () => {
        return ServicesRights.canCsvExportCodebooks();
    };

    let setDefaultFilterValue = () => {
        if (orderViewId != undefined) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                {
                    field: 'orderView.id',
                    condition: 'equals',
                    value: orderViewId,
                },
            ];
        }

        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const setDefaultFilterValueOrderView = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'type', condition: 'equals', value: 3 },
            { field: 'orderViewAccessType', condition: 'equals', value: 2 },
        ];
    };

    const fetchOrderView = () => {
        const emptyData: any = [];
        if (orderViewList == emptyData || orderViewList.length < 1) {
            let defaultFilterOrder = setDefaultFilterValueOrderView();
            NyRequestResolver.requestGet(CONSTANTS_REQ.ORDER_VIEW.USER_SEARCH, {
                search: encodeURI(JSON.stringify(defaultFilterOrder)),
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        if (result.data[0]) {
                            if (result.data[0].id) setOrderViewId(result.data[0].id);
                            if (result.data[0].name) setOrderViewLabel(result.data[0].name);
                        }

                        let oView: any = [];
                        for (let key in result.data) {
                            if (result.data.hasOwnProperty(key)) {
                                let order = { value: undefined, label: undefined, number: undefined };
                                order.value = result.data[key].id;
                                order.label = result.data[key].name;
                                oView.push(order);
                            }
                        }
                        setOrderViewList(oView);
                    }
                }
                setLoading(false);
            });
        }
    };

    const onChangeOrderView = (e: any) => {
        setOrderViewId(e.target.value > 0 ? e.target.value : null);
    };

    const setDefaultSortOrder = { order: 'date', orderType: 'desc' };

    const initialColumns = [
        {
            title: geti18nText('servicesOrder.table.column.id'),
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('servicesOrder.table.column.ord'),
            dataIndex: [`concat(_so.ord, '/', date_part('year', _so.date))`],
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.ord && record.date) {
                    return record.ord + '/' + moment(record.date).format('YYYY');
                }
            },
        },
        {
            title: geti18nText('servicesOrder.table.column.firstName'),
            dataIndex: ['person', 'firstName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.employee) {
                    return record.employee.person.firstName;
                }
            },
        },
        {
            title: geti18nText('servicesOrder.table.column.lastName'),
            dataIndex: ['person', 'lastName'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            render: (text: any, record: any) => {
                if (record.employee) {
                    return record.employee.person.lastName;
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.businessUnit'),
            dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchByCodebookBusinessUnit(),
            render: (text: any, record: any) => {
                if (record.businessUnit) {
                    return (
                        record.businessUnit.name +
                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                    );
                }
            },
        },
        {
            title: geti18nText('servicesOrder.table.column.date'),
            dataIndex: 'date',
            sorter: (a: any, b: any) => {},
            ...getColumnDateOption(),
            render: (text: any, record: any) => {
                if (record.date) {
                    return moment(record.date).format('DD.MM.YYYY HH:mm');
                }
            },
        },
        {
            title: geti18nText('inventoryOrder.table.column.status'),
            dataIndex: 'status',
            width: '200px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('INVENTORY_ORDER_STATUS_TYPE')),
            render: (status: any) => {
                return getOrderStatusIcon(status);
            },
        },
        {
            title: geti18nText('app.default.actions'),
            dataIndex: 'actions',
            width: '100px',
            render: (text: any, record: any) => {
                if (record.id) {
                    return <RowAction component={'ServicesOrder'} record={record}></RowAction>;
                }
            },
        },
    ];

    return loading ? (
        <NySpinner />
    ) : (
        <React.Fragment>
            <Radio.Group
                options={orderViewList}
                value={orderViewId}
                onChange={onChangeOrderView}
                buttonStyle="solid"
                optionType="button"
                style={{ marginBottom: '15px' }}
            />
            {isAdmin() || (orderViewList && orderViewList.length > 0) ? (
                <NyDataTable
                    nyId="webshop-services-other-table"
                    url={CONSTANTS_REQ.SERVICES_ORDER.LIST}
                    addNewButtonText={geti18nText('servicesOrder.table.add')}
                    buttonsClassName="buttons-sticky"
                    showRecordModal={true}
                    modalComponent={ServicesOrderEdit}
                    columns={initialColumns}
                    setDefaultSortOrder={sortOrder ?? setDefaultSortOrder}
                    scroll={scroll}
                    setDefaultFilterValue={setDefaultFilterValue}
                    setDefaultPageSize={table.setDefaultPageSize()}
                    shortcuts={true}
                    hideNewButton={!canCreate()}
                    fetchWhenChange={refreshTable}
                    addedData={{ orderViewId: orderViewId }}
                    exportCSV={canExportCSV()}
                    CSVFileName={geti18nText('menu.webshopServices')}
                    colCSVCustomization={csvColumnCustomisation()}
                    csvColumns={csvColumns()}
                />
            ) : (
                <Empty />
            )}
        </React.Fragment>
    );
};

export const csvColumnCustomisation: any = () => {
    return [
        {
            ord: (value: any) => {
                return value != undefined && value;
            },
        },
        {
            businessUnit: (value: any) => {
                if (value && value.name) {
                    if (value.code) {
                        return value.name + ' (' + value.code + ')';
                    } else {
                        return value.name;
                    }
                }
            },
        },
        {
            date: (value: any) => {
                return value != undefined && moment(value).format('DD.MM.YYYY HH:mm');
            },
        },
        {
            status: (value: any) => {
                return value != undefined && geti18nText('app.enum.INVENTORY_ORDER_STATUS_TYPE.' + value);
            },
        },
    ];
};

export const csvColumns: any = () => {
    return [
        {
            title: geti18nText('servicesOrder.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('servicesOrder.table.column.ord'),
            dataIndex: 'ord',
        },
        {
            title: geti18nText('servicesOrder.table.column.firstName'),
            dataIndex: ['employee', 'person', 'firstName'],
        },
        {
            title: geti18nText('servicesOrder.table.column.lastName'),
            dataIndex: ['employee', 'person', 'lastName'],
        },
        {
            title: geti18nText('inventoryOrder.table.column.businessUnit'),
            dataIndex: 'businessUnit',
        },
        {
            title: geti18nText('servicesOrder.table.column.date'),
            dataIndex: 'date',
        },
        {
            title: geti18nText('inventoryOrder.table.column.status'),
            dataIndex: 'status',
        },
    ];
};

export default ServicesOrderTable;
