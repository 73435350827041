import {
    NyDataTable,
    NyRequestResolver,
    NySession,
    RESPONSE,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchCheckbox,
    geti18nText,
} from '@nybble/nyreact';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import RowAction from '../../../../components/row-action';
import useEnum from '../../../../hooks/useEnum';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL } from '../../../../utils/Constants';
import {
    errorNotification,
    getDateFormat,
    getEnumArrayStatus,
    getMobilizationStatusIcon,
    okNotification,
    userIsNotificationRecipient,
} from '../../../../utils/Utils';
import WorkObligationCallEdit from './edit';
import { MobilizationRights } from '../../../../rights/mobilizationRights';
import EmployeeWorkObligationEdit from '../employee-mobilization/edit';

const WorkObligationCallIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    scroll = DEFAULT_TABLE_SCROLL,
    sortOrder,
}: any) => {
    const location = useLocation();

    const table = useTableSettings();
    const { employee } = useSelector((state: RootState) => state.employee);
    const notificationType = useEnum('NOTIFICATION_TYPE');
    userIsNotificationRecipient(employee, notificationType.MOBILIZATION_APPROVAL);
    const [refresh, setRefresh] = useState<any>(0);
    const [item, setItem] = useState<any>(0);
    const [visible, setVisible] = useState<any>(false);
    const [isNew, setIsNew] = useState(false);

    useHelper('human/work-obligation/mobilization');

    const canCreate = () => {
        return MobilizationRights.isMobilizationAdmin();
    };

    const mobilizationStatus = useEnum('MOBILIZATION_STATUS');

    const setDefaultFilterValue = () => {
        return [
            {
                field: 'active',
                condition: 'equals_bool',
                value: 1,
            },
        ];
    };

    const changeStatus = (recordId: any, status: any) => {
        if (recordId && status) {
            NyRequestResolver.requestPut(CONSTANTS_REQ.MOBILIZATION.CHANGE_STATUS + '/' + recordId, undefined, {
                id: recordId,
                status: status,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setRefresh((refresh: any) => refresh + 1);
                    okNotification(geti18nText('menu.work.obligation.call.change.status.success'));
                } else {
                    if (result.data && result.data.error) {
                        errorNotification(result.data.error);
                    } else {
                        errorNotification();
                    }
                }
            });
        }
    };

    const columns = [
        ...[
            {
                title: geti18nText('menu.work.obligation.call.table.requestDate'),
                dataIndex: 'created',
                sorter: (a: any, b: any) => {},
                render: (created: any) => {
                    if (created) {
                        return getDateFormat(created, 'DD.MM.YYYY');
                    }
                },
                ...getColumnDateOption(false),
            },
            {
                title: geti18nText('menu.work.obligation.call.table.requestedBy'),
                dataIndex: [`concat(createdPerson.first_name,' ', createdPerson.last_name)`],
                sorter: (a: any, b: any) => {},
                render: (text: any, record: any) => {
                    if (record?.createdPerson) {
                        return (
                            <a
                                style={{ textDecoration: 'underline' }}
                                onClick={() => modalVisible(record?.createdEmployee)}
                            >
                                {record?.createdPerson?.firstName +
                                    ' ' +
                                    record?.createdPerson?.lastName +
                                    ' (' +
                                    record?.createdEmployee?.employmentRecordId +
                                    ')'}
                            </a>
                        );
                    }
                },
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('menu.work.obligation.call.table.approvalDate'),
                dataIndex: 'approved',
                sorter: (a: any, b: any) => {},
                render: (approved: any) => {
                    if (approved) {
                        return getDateFormat(approved, 'DD.MM.YYYY');
                    }
                },
                ...getColumnDateOption(false),
            },
            {
                title: geti18nText('menu.work.obligation.call.table.approvedBy'),
                dataIndex: [`concat(approvedPerson.first_name,' ', approvedPerson.last_name)`],
                sorter: (a: any, b: any) => {},
                render: (text: any, record: any) => {
                    if (record?.approvedPerson) {
                        return (
                            <a
                                style={{ textDecoration: 'underline' }}
                                onClick={() => modalVisible(record?.approvedEmployee)}
                            >
                                {record?.approvedPerson?.firstName +
                                    ' ' +
                                    record?.approvedPerson?.lastName +
                                    ' (' +
                                    record?.approvedEmployee?.employmentRecordId +
                                    ')'}
                            </a>
                        );
                    }
                },
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('menu.work.obligation.call.table.status'),
                dataIndex: 'status',
                width: '340px',
                sorter: (a: any, b: any) => {},
                render: (record: any, status: any) => {
                    return getMobilizationStatusIcon(record);
                },
                ...getColumnSearchCheckbox(getEnumArrayStatus('MOBILIZATION_STATUS')),
            },
        ],
        ...(canCreate() == true
            ? [
                  {
                      title: geti18nText('menu.work.obligation.call.table.action'),
                      dataIndex: 'name',
                      width: '5%',
                      render: (text: any, record: any) => {
                          if (record?.status == mobilizationStatus.PLAN) {
                              return (
                                  <RowAction
                                      component={'MobilizationRequest'}
                                      record={record}
                                      changeStatus={changeStatus}
                                  ></RowAction>
                              );
                          } else if (
                              record?.status == mobilizationStatus.WAITING_FOR_APPROVAL &&
                              userIsNotificationRecipient(employee, notificationType.MOBILIZATION_APPROVAL)
                          ) {
                              return (
                                  <RowAction
                                      component={'MobilizationApproval'}
                                      record={record}
                                      changeStatus={changeStatus}
                                  ></RowAction>
                              );
                          }
                      },
                  },
              ]
            : []),
    ];

    const modalVisible = (employee: any) => {
        setItem(employee?.id);
        setVisible(true);
    };

    return (
        <>
            <NyDataTable
                nyId="human-work-obligation-mobilization-table"
                url={CONSTANTS_REQ.MOBILIZATION.LIST}
                buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
                showRecordModal={true}
                modalComponent={WorkObligationCallEdit}
                showRowSelection={showRowSelection}
                rowSelectionType={rowSelectionType}
                rowSelectionModal={rowSelectionModal}
                setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
                columns={columns}
                setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
                scroll={scroll}
                onDataLoaded={table.onDataLoaded}
                setDefaultFilterValue={setDefaultFilterValue}
                setDefaultPageSize={table.setDefaultPageSize()}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                hideNewButton={!canCreate()}
                hideButtons={false}
                shortcuts={true}
                selectOnSave={true}
                fetchWhenChange={refresh}
                headerTitle={geti18nText('menu.work.obligation.call.mobilization')}
            />
            {visible && (
                <EmployeeWorkObligationEdit
                    isModalVisible={visible}
                    setIsModalVisible={setVisible}
                    id={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isNew={isNew}
                    setIsNew={setIsNew}
                />
            )}
        </>
    );
};

export default WorkObligationCallIndex;
