import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    NyDataTable,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
} from '@nybble/nyreact';
import { Tooltip } from 'antd';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import useHelper from '../../../../hooks/useHelper';
import useTableSettings from '../../../../hooks/useTableSettings';
import { HumanResourcesRights } from '../../../../rights/humanResourcesRights';
import { CONSTANTS_REQ, DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS } from '../../../../utils/Constants';
import { getColumnSearchByCodebookBusinessUnit, getColumnSearchByCodebookSimple } from '../../../../utils/Filters';
import EmployeeSimpleEdit from '../employee-simple/edit';

const EmployeeIndex = ({
    showRowSelection,
    rowSelectionType,
    rowSelectionModal,
    setDefaultSelectedRowKeys,
    disabled = false,
    filteredForTravel = false,
    filteredForEmployeeLeave = false,
    customFilter,
    height,
    scroll = DEFAULT_TABLE_SCROLL_CODEBOOKS_TABS,
    sortOrder,
    userless = false,
    parentKey,
}: any) => {
    const location = useLocation();

    const extendedTable = location.pathname == '/human/employee' ? true : false;

    const table = useTableSettings();

    useHelper('human/employee');

    const canCreate = () => {
        return HumanResourcesRights.canCreateEmployee();
    };

    const columns: any = [
        ...[
            // {
            //     title: geti18nText('employee.table.column.id'),
            //     dataIndex: 'id',
            //     width: '10%',
            //     sorter: (a: any, b: any) => {},
            //     ...getColumnSearch('number'),
            // },
            {
                title: geti18nText('employee.table.column.employmentRecordId'),
                dataIndex: 'employmentRecordId',
                width: extendedTable ? '7%' : '15%',
                sorter: (a: any, b: any) => {},
                render: (record: any) => {
                    return (
                        <Tooltip placement="topLeft" title={record}>
                            <div className="ny-table-paragraph"> {record}</div>
                        </Tooltip>
                    );
                },
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('employee.table.column.firstName'),
                dataIndex: ['person', 'firstName'],
                width: extendedTable ? '7%' : '15%',
                sorter: (a: any, b: any) => {},
                render: (record: any) => {
                    return (
                        <Tooltip placement="topLeft" title={record}>
                            <div className="ny-table-paragraph"> {record}</div>
                        </Tooltip>
                    );
                },
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('employee.table.column.lastName'),
                dataIndex: ['person', 'lastName'],
                width: extendedTable ? '9%' : '18%',
                sorter: (a: any, b: any) => {},
                render: (record: any) => {
                    return (
                        <Tooltip placement="topLeft" title={record}>
                            <div className="ny-table-paragraph"> {record}</div>
                        </Tooltip>
                    );
                },
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('employee.table.column.businessUnit'),
                dataIndex: [`concat(businessUnit.name,' ', coalesce(businessUnit.code, ''))`],
                sorter: (a: any, b: any) => {},
                ...getColumnSearchByCodebookBusinessUnit(),
                render: (text: any, record: any) => {
                    if (record.businessUnit) {
                        return (
                            <Tooltip
                                placement="topLeft"
                                title={
                                    record.businessUnit.name +
                                    (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')
                                }
                            >
                                <div className="ny-table-paragraph">
                                    {' '}
                                    {record.businessUnit.name +
                                        (record.businessUnit.code ? ' (' + record.businessUnit.code + ')' : '')}
                                </div>
                            </Tooltip>
                        );
                    }
                },
            },
        ],
        ...(extendedTable
            ? [
                  {
                      title: geti18nText('employee.table.column.contact'),
                      dataIndex: ['contact', 'shortNumber'],
                      width: '7%',
                      sorter: (a: any, b: any) => {},
                      render: (record: any) => {
                          return (
                              <Tooltip placement="topLeft" title={record}>
                                  <div className="ny-table-paragraph"> {record}</div>
                              </Tooltip>
                          );
                      },
                      ...getColumnSearch('number'),
                  },
                  {
                      title: geti18nText('employee.table.column.employmentType'),
                      dataIndex: ['employmentType', 'name'],
                      width: '7%',
                      sorter: (a: any, b: any) => {},
                      render: (record: any) => {
                          return (
                              <Tooltip placement="topLeft" title={record}>
                                  <div className="ny-table-paragraph"> {record}</div>
                              </Tooltip>
                          );
                      },
                      ...getColumnSearchByCodebookSimple(
                          CONSTANTS_REQ.EMPLOYMENT_TYPE.SEARCH,
                          'employmentType.id',
                          'name',
                          'name',
                          {
                              id: 'id',
                              label: 'name',
                          }
                      ),
                  },
                  {
                      title: geti18nText('employee.table.column.vocationName'),
                      dataIndex: ['vocation', 'name'],
                      width: '10%',
                      sorter: (a: any, b: any) => {},
                      render: (record: any) => {
                          return (
                              <Tooltip placement="topLeft" title={record}>
                                  <div className="ny-table-paragraph"> {record}</div>
                              </Tooltip>
                          );
                      },
                      ...getColumnSearchByCodebookSimple(CONSTANTS_REQ.VOCATION.SEARCH, 'vocation.id', 'name', 'name', {
                          id: 'id',
                          label: 'name',
                      }),
                  },
                  {
                      title: geti18nText('employee.table.column.vocationDescription'),
                      dataIndex: ['vocationDescription', 'name'],
                      width: '10%',
                      sorter: (a: any, b: any) => {},
                      render: (record: any) => {
                          return (
                              <Tooltip placement="topLeft" title={record}>
                                  <div className="ny-table-paragraph"> {record}</div>
                              </Tooltip>
                          );
                      },
                      ...getColumnSearchByCodebookSimple(
                          CONSTANTS_REQ.VOCATION_DESCRIPTION.SEARCH,
                          'vocationDescription.id',
                          'name',
                          'name',
                          {
                              id: 'id',
                              label: 'name',
                              text: 'name',
                          }
                      ),
                  },
                  {
                      title: geti18nText('employee.table.column.qualificationLevel'),
                      dataIndex: ['qualificationLevel', 'name'],
                      width: '10%',
                      sorter: (a: any, b: any) => {},
                      render: (record: any) => {
                          return (
                              <Tooltip placement="topLeft" title={record}>
                                  <div className="ny-table-paragraph"> {record}</div>
                              </Tooltip>
                          );
                      },
                      ...getColumnSearchByCodebookSimple(
                          CONSTANTS_REQ.QUALIFICATION_LEVEL.SEARCH,
                          'qualificationLevel.id',
                          'name',
                          'name',
                          {
                              id: 'id',
                              label: 'name',
                              text: 'name',
                          }
                      ),
                  },
                  {
                      title: geti18nText('employeeAddress.table.column.employeeStatusValidFrom'),
                      dataIndex: 'employeeStatusValidFrom',
                      width: '8%',
                      sorter: (a: any, b: any) => {},
                      render: (text: any, record: any) => {
                          if (record.employeeStatusValidFrom) {
                              return moment(record.employeeStatusValidFrom).format('DD.MM.YYYY');
                          }
                      },
                      ...getColumnDateOption(false),
                  },
                  {
                      title: geti18nText('travelOverview.general.table.column.status'),
                      dataIndex: ['employeeStatus', 'name'],
                      width: '160px',
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearchByCodebookSimple(
                          CONSTANTS_REQ.EMPLOYEE_STATUS.SEARCH,
                          'employeeStatus.id',
                          'name',
                          'name',
                          {
                              id: 'id',
                              label: 'name',
                          }
                      ),
                      fixed: 'left',
                      render: (record: any) => {
                          return (
                              <Tooltip placement="topLeft" title={record}>
                                  <div className="ny-table-paragraph"> {record}</div>
                              </Tooltip>
                          );
                      },
                  },
                  /*   {
            title: geti18nText('employee.table.column.receivesChildBenefit'),
            dataIndex: 'receivesChildBenefit',
            render: (text: any, record: { receivesChildBenefit: any }) => {
                if (record.receivesChildBenefit) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(getEnumBooleanArray(), 'equals_bool'),
        }, */
                  /*   {
            title: geti18nText('travelOverview.general.table.column.status'),
            dataIndex: 'status',
            width: '260px',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchCheckbox(getEnumArrayStatus('EMPLOYEE_STATUS')),
            fixed: 'left',
            render: (status: any) => {
                return getEmployeeStatusIcon(status);
            },
        }, */
              ]
            : []),
        ...[
            {
                title: geti18nText('location.table.column.active'),
                dataIndex: 'active',
                width: extendedTable ? '4%' : '10%',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', undefined),
                defaultFilteredValue: table.setDefaultFilterValue(),
            },
        ],
    ];

    return (
        <NyDataTable
            nyId={parentKey ? `${parentKey}-human-employee-table` : 'human-employee-table'}
            url={
                userless
                    ? CONSTANTS_REQ.EMPLOYEE.UNASSIGNED_LIST
                    : filteredForTravel
                    ? CONSTANTS_REQ.EMPLOYEE.TRAVEL_LIST
                    : filteredForEmployeeLeave
                    ? CONSTANTS_REQ.EMPLOYEE.EMPLOYEE_LEAVE_LIST
                    : CONSTANTS_REQ.EMPLOYEE.LIST
            }
            modalComponent={EmployeeSimpleEdit}
            showRecordModal={true}
            addNewButtonText={geti18nText('employee.table.add')}
            buttonsClassName={showRowSelection ? '' : 'buttons-sticky'}
            columns={columns}
            scroll={height ? { y: height ?? 680, x: 800 } : scroll}
            onDataLoaded={table.onDataLoaded}
            setDefaultFilterValue={customFilter ? customFilter : table.setDefaultFilterValue}
            setDefaultPageSize={table.setDefaultPageSize()}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={sortOrder ?? table.setDefaultSortOrder()}
            showRowSelection={showRowSelection}
            rowSelectionType={rowSelectionType}
            rowSelectionModal={rowSelectionModal}
            setDefaultSelectedRowKeys={setDefaultSelectedRowKeys}
            shortcuts={true}
            hideNewButton={!canCreate()}
            hideButtons={disabled}
            readonly={disabled}
            headerTitle={geti18nText('menu.employee')}
        />
    );
};
export default EmployeeIndex;
