import { NyIsDirtyModal, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import storage from 'redux-persist/lib/storage';
import './assets/scss/index.scss';
import ApprovalIndex from './components/approval';
import DownloadIndex from './components/download';
import LayoutIndex from './components/layout';
import ReRouteAsset from './components/layout/re-routes/ReRouteAsset';
import ReRouteAssetOrder from './components/layout/re-routes/ReRouteAssetOrder';
import ReRouteAssetRelease from './components/layout/re-routes/ReRouteAssetRelease';
import ReRouteAssetReturn from './components/layout/re-routes/ReRouteAssetReturn';
import ReRouteEmployeeEvaluation from './components/layout/re-routes/ReRouteEmployeeEvaluation';
import ReRouteInventoryOrder from './components/layout/re-routes/ReRouteInventoryOrder';
import ReRouteServicesOrder from './components/layout/re-routes/ReRouteServicesOrder';
import Login from './components/login';
import OpenRouteIndex from './components/open-route';
import TravelWarrantApprovalIndex from './components/travel-warrant-approval';
import { RootState } from './rootReducer';
import { setEmployeeId } from './slices/attendanceSlice';
import { setEmployee } from './slices/employeeSlice';
import { setVersion } from './slices/generalSettingsSlice';
import { CONSTANTS_REQ } from './utils/Constants';
import { LoadEnums } from './utils/Enums';
import { InitSlices } from './utils/slices';
import ReRouteEmployeeMobilization from './components/layout/re-routes/ReRouteEmployeeMobilization';
import TravelWarrantEdit from './views/travel-warrant/views/travel-warrant/edit';
import ReRouteTravelWarrant from './components/layout/re-routes/ReRouteTravelWarrant';
import { clearCache } from './utils/Utils';

const App = () => {
    const { theme, font, version } = useSelector((state: RootState) => state.generalSettings);
    const [loading, setLoading] = useState(true);
    const [promptVisible, setPromptVisible] = useState(false);
    const [promptCallback, setPromptCallback] = useState<any>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(process.env.NODE_ENV);
        initApp();
    }, []);

    const initApp = async () => {
        setLoading(true);
        await NySession.init(CONSTANTS_REQ.INIT);
        if (NySession.getSetting('application-name')) {
            document.title = NySession.getSetting('application-name');
        }

        if (NySession.getSetting('version') != undefined) {
            if (NySession.getSetting('version') != version) {
                storage.removeItem('persist:root');
                clearCache();
                NySession.logoutUser();
                InitSlices(dispatch);
                dispatch(setVersion(NySession.getSetting('version')));
            }
        }
        await getUser();

        await LoadEnums();
        setLoading(false);
    };

    const themes = {
        dark: `${process.env.PUBLIC_URL}/dark.css`,
        dark_16: `${process.env.PUBLIC_URL}/dark_16.css`,
        light: `${process.env.PUBLIC_URL}/light.css`,
        light_16: `${process.env.PUBLIC_URL}/light_16.css`,
        default: `${process.env.PUBLIC_URL}/default.css`,
        default_16: `${process.env.PUBLIC_URL}/default_16.css`,
    };

    const getUser = async () => {
        if (NySession.isUserAuthenticated()) {
            await NyRequestResolver.requestGet(CONSTANTS_REQ.EMPLOYEE.GET_EMPLOYEE_BY_USER, {}).then((result: any) => {
                if (result.status == RESPONSE.OK && result.data) {
                    if (result.data.id) {
                        dispatch(setEmployee({ employee: result.data }));
                        dispatch(setEmployeeId({ employeeId: result.data.id }));
                    }
                }
            });
        }
    };

    if (loading) {
        return (
            <div className="spin-container">
                <Spin size="large" />
            </div>
        );
    } else {
        return (
            <>
                <ThemeSwitcherProvider
                    themeMap={themes}
                    defaultTheme={font === 16 ? `${theme}_${font}` : theme.toString()}
                    insertionPoint="styles-insertion-point"
                >
                    <HashRouter
                        getUserConfirmation={(message, callback) => {
                            setPromptCallback(() => callback);
                            setPromptVisible(true);
                        }}
                    >
                        <Switch>
                            <Route exact path="/login" component={() => <Login />} />
                            <Route path="/approval" component={ApprovalIndex} />
                            <Route path="/open" component={OpenRouteIndex} />
                            <Route path="/travel-warrant-approval" component={TravelWarrantApprovalIndex} />
                            <Route path="/download" component={DownloadIndex} />
                            {/* loginRedirectCustomUrl start*/}
                            <Route path="/asset/:id" component={ReRouteAsset} />
                            <Route path="/asset-order/:id" component={ReRouteAssetOrder} />
                            <Route path="/asset-release/:id" component={ReRouteAssetRelease} />
                            <Route path="/asset-return/:id" component={ReRouteAssetReturn} />
                            <Route path="/inventory-order/:id" component={ReRouteInventoryOrder} />
                            <Route path="/services-order/:id" component={ReRouteServicesOrder} />
                            <Route path="/travelWarrant/:id" component={ReRouteTravelWarrant} />
                            <Route
                                path="/employee-evaluation/employeeEvaluation/:id"
                                component={ReRouteEmployeeEvaluation}
                            />
                            <Route path="/human/employee-evaluation/:id" component={ReRouteEmployeeEvaluation} />
                            <Route
                                path="/human/work-obligation/employee-mobilization-certificate/:id"
                                component={ReRouteEmployeeMobilization}
                            />
                            {/* loginRedirectCustomUrl end*/}
                            <Route path="/" component={LayoutIndex} />
                        </Switch>
                    </HashRouter>
                </ThemeSwitcherProvider>
                <NyIsDirtyModal
                    visible={promptVisible}
                    onContinue={() => {
                        promptCallback(true);
                        setPromptVisible(false);
                    }}
                    onCancel={() => {
                        promptCallback(false);
                        setPromptVisible(false);
                    }}
                ></NyIsDirtyModal>
            </>
        );
    }
};

export default App;
