import {
    BookOutlined,
    CoffeeOutlined,
    ContactsOutlined,
    ControlOutlined,
    DeleteRowOutlined,
    EnvironmentOutlined,
    EuroOutlined,
    FileOutlined,
    FileProtectOutlined,
    FileTextOutlined,
    FolderViewOutlined,
    HomeOutlined,
    HourglassOutlined,
    LaptopOutlined,
    MedicineBoxOutlined,
    MoneyCollectOutlined,
    NodeCollapseOutlined,
    NodeExpandOutlined,
    ReadOutlined,
    ScheduleOutlined,
    SettingOutlined,
    SnippetsOutlined,
    TeamOutlined,
    TrophyFilled,
    TrophyOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    UserSwitchOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import { HumanResourcesRights } from '../../rights/humanResourcesRights';
import { MobilizationRights } from '../../rights/mobilizationRights';
import { PayrollRights } from '../../rights/payrollRights';

const HumanMenu = [
    {
        key: 'menu.human',
        path: '/human',
        icon: <HomeOutlined />,
        i18n: 'menu.home',
        role: HumanResourcesRights.canViewHumanOverviewModule(),
    },
    {
        key: 'menu.employee',
        path: '/human/employee',
        icon: <UserOutlined />,
        i18n: 'menu.employee',
        role: HumanResourcesRights.canViewEmployee(),
    },
    {
        key: 'menu.employeePersonal',
        path: '/human/employeePersonal',
        icon: <UserOutlined />,
        i18n: 'menu.employee',
        role: HumanResourcesRights.canViewHumanResourcesUser(),
    },
    /*   {
        key: 'menu.attendance', //zakomentirati
        path: '/human/attendance',
        icon: <HourglassOutlined />,
        i18n: 'menu.attendance',
        role: HumanResourcesRights.canViewAttendanceHRMenu(),
    }, */
    {
        key: 'menu.attendance.registration',
        path: '/human/attendance-registration',
        icon: <HourglassOutlined />,
        i18n: 'menu.attendance.registration',
        role: HumanResourcesRights.canViewAttendanceMenu(),
    },
    {
        key: 'menu.employeeLeave',
        path: '/human/employeeLeave',
        icon: <CoffeeOutlined />,
        i18n: 'menu.employeeLeave',
        role: HumanResourcesRights.canViewEmployeeLeaveMenu(),
    },
    {
        key: 'menu.employeeSickLeave',
        path: '/human/employeeSickLeave',
        icon: <MedicineBoxOutlined />,
        i18n: 'menu.employeeSickLeave',
        role: HumanResourcesRights.canViewEmployeeSickLeaveMenu(),
    },
    {
        key: 'menu.absence',
        path: '/human/employee-leave-overview',
        icon: <ReadOutlined />,
        i18n: 'menu.absence',
        role: HumanResourcesRights.canViewMenuEmployeeLeave(),
    },
    {
        key: 'menu.payroll',
        path: '/human/payroll',
        icon: <WalletOutlined />,
        i18n: 'menu.payroll',
        role: PayrollRights.canViewPayrollMenu(),
        submenu: [
            {
                key: 'menu.payment.calculation',
                path: '/human/payroll/calculation',
                icon: <EuroOutlined />,
                i18n: 'menu.payment.calculation',
                role: PayrollRights.canViewCalculationMenu(),
                submenu: [
                    {
                        key: 'menu.payment.calculation.income',
                        path: '/human/payroll/calculation/paymentCalculationIncome',
                        icon: <MoneyCollectOutlined />,
                        i18n: 'app.enum.PAYMENT_CALCULATION_TYPE.1',
                        role: PayrollRights.canViewCalculationMenu(),
                    },
                    {
                        key: 'menu.payment.calculation.secondIncome',
                        path: '/human/payroll/calculation/paymentCalculationSecondIncome',
                        icon: <MoneyCollectOutlined />,
                        i18n: 'app.enum.PAYMENT_CALCULATION_TYPE.2',
                        role: PayrollRights.canViewCalculationMenu(),
                    },
                    {
                        key: 'menu.payment.calculation.contract',
                        path: '/human/payroll/calculation/paymentCalculationContract',
                        icon: <MoneyCollectOutlined />,
                        i18n: 'app.enum.PAYMENT_CALCULATION_TYPE.3',
                        role: PayrollRights.canViewCalculationMenu(),
                    },
                    {
                        key: 'menu.payment.calculation.royalties',
                        path: '/human/payroll/calculation/paymentCalculationRoyalties',
                        icon: <MoneyCollectOutlined />,
                        i18n: 'app.enum.PAYMENT_CALCULATION_TYPE.4',
                        role: PayrollRights.canViewCalculationMenu(),
                    },
                ],
            },
            {
                key: 'menu.joppd',
                path: '/human/payroll/joppd',
                icon: <FileTextOutlined />,
                i18n: 'menu.joppd',
                role: PayrollRights.canViewJoppdMenu(),
            },
        ],
    },
    {
        key: 'menu.work.obligation',
        path: '/human/work-obligation',
        icon: <i style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }} className="las la-building" />,
        i18n: 'menu.work.obligation',
        role: HumanResourcesRights.canViewMobilizationMenu(),
        submenu: [
            {
                key: 'menu.work.obligation.war.systematization',
                path: '/human/work-obligation/war-systematization',
                icon: (
                    <i style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }} className="las la-sitemap" />
                ),
                i18n: 'menu.work.obligation.war.systematization',
                role: MobilizationRights.isMobilizationViewUserMenu(),
                submenu: [
                    {
                        key: 'menu.work.obligation.businessUnit',
                        path: '/human/work-obligation/war-systematization/war-business-unit',
                        icon: <UserSwitchOutlined />,
                        i18n: 'menu.work.obligation.businessUnit',
                        role: MobilizationRights.isMobilizationViewUserMenu(),
                    },
                    {
                        key: 'menu.work.obligation.systematization.of.jobs',
                        path: '/human/work-obligation/war-systematization/systematization-of-jobs',
                        icon: (
                            <i
                                style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }}
                                className="las la-user-tie"
                            />
                        ),
                        i18n: 'menu.work.obligation.systematization.of.jobs',
                        role: MobilizationRights.isMobilizationViewUserMenu(),
                    },
                    // {
                    //     key: 'menu.work.obligation.systematization.documents',
                    //     path: '/human/work-obligation/war-systematization/systematization-documents',
                    //     icon: <FileTextOutlined />,
                    //     i18n: 'menu.work.obligation.systematization.documents',
                    //     role: MobilizationRights.isMobilizationViewUserMenu(),
                    // },
                ],
            },
            {
                key: 'menu.work.obligation.employee.mobilization',
                path: '/human/work-obligation/employee-mobilization',
                icon: <UserOutlined />,
                i18n: 'menu.employee',
                role: MobilizationRights.isMobilizationViewUserMenu(),
            },
            {
                key: 'menu.work.obligation.scheduled',
                icon: (
                    <i
                        style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }}
                        className="las la-building"
                    />
                ),
                i18n: 'menu.work.obligation',
                role: MobilizationRights.isMobilizationViewUserMenu(),
                submenu: [
                    {
                        key: 'menu.work.obligation.schedule.suggestion.approvals',
                        icon: <ScheduleOutlined />,
                        path: '/human/work-obligation/schedule-suggestions',
                        i18n: 'menu.work.obligation.schedule.suggestion.approvals',
                        role: MobilizationRights.isMobilizationViewUserMenu(),
                    },
                    /* {
						key: 'menu.work.obligation.schedule.approvals',
						icon: <CheckSquareOutlined />,
						path: '/human/schedule-approvals',
						i18n: 'menu.work.obligation.schedule.approvals',
						role: MobilizationRights.isMobilizationViewUserMenu(),
					}, */
                    {
                        key: 'menu.work.obligation.records',
                        path: '/human/work-obligation/records',
                        icon: <FileOutlined />,
                        i18n: 'menu.work.obligation.records',
                        role: MobilizationRights.isMobilizationViewUserMenu(),
                        submenu: [
                            {
                                key: 'menu.work.obligation.military.obligees',
                                icon: <NodeExpandOutlined />,
                                path: '/human/work-obligation/records/military-obligees',
                                i18n: 'menu.work.obligation.military.obligees',
                                role: MobilizationRights.isMobilizationViewUserMenu(),
                            },
                            {
                                key: 'menu.work.obligation.military.not.obligees',
                                icon: <NodeCollapseOutlined />,
                                path: '/human/work-obligation/records/military-not-obligees',
                                i18n: 'menu.work.obligation.military.not.obligees',
                                role: MobilizationRights.isMobilizationViewUserMenu(),
                            },
                            {
                                key: 'menu.work.obligation.military.without.scheduled',
                                icon: <DeleteRowOutlined />,
                                path: '/human/work-obligation/records/military-without-schedule',
                                i18n: 'menu.work.obligation.military.without.scheduled',
                                role: MobilizationRights.isMobilizationViewUserMenu(),
                            },
                        ],
                    },
                    {
                        key: 'menu.work.obligation.employeeAnalyze',
                        icon: <UserOutlined />,
                        path: '/human/work-obligation/employee-analyze',
                        i18n: 'menu.work.obligation.employeeAnalyze',
                        role: MobilizationRights.isMobilizationViewUserMenu(),
                    },
                ],
            },
            {
                key: 'menu.work.obligation.call',
                path: '/human/work-obligation/call',
                icon: <FileTextOutlined />,
                i18n: 'menu.work.obligation.call',
                role: MobilizationRights.isMobilizationViewUserMenu(),
                submenu: [
                    {
                        key: 'menu.work.obligation.call.mobilization',
                        path: '/human/work-obligation/call/work-obligation-call',
                        icon: <FileOutlined />,
                        i18n: 'menu.work.obligation.call.mobilization',
                        role: MobilizationRights.isMobilizationViewUserMenu(),
                    },
                    {
                        key: 'menu.work.obligation.special.duty.in.mobilization',
                        path: '/human/work-obligation/call/special-duty-in-mobilization',
                        icon: <FileOutlined />,
                        i18n: 'menu.work.obligation.special.duty.in.mobilization',
                        role: MobilizationRights.isMobilizationViewUserMenu(),
                    },
                    {
                        key: 'menu.work.obligation.employee.schedule',
                        path: '/human/work-obligation/call/employee-schedule',
                        icon: <FileOutlined />,
                        i18n: 'menu.work.obligation.employee.schedule',
                        role: MobilizationRights.isMobilizationViewUserMenu(),
                    },
                    {
                        key: 'menu.work.obligation.calling.for.performance.of.duty',
                        path: '/human/work-obligation/call/calling-for-performance-of-duty',
                        icon: <FileOutlined />,
                        i18n: 'menu.work.obligation.calling.for.performance.of.duty',
                        role: MobilizationRights.isMobilizationViewUserMenu(),
                    },
                    {
                        key: 'menu.work.obligation.employeeMilitaryRecords2',
                        path: '/human/work-obligation/call/employee-military-records',
                        icon: <SnippetsOutlined />,
                        i18n: 'menu.work.obligation.employeeMilitaryRecords',
                        role: MobilizationRights.isMobilizationViewUserMenu(),
                    },
                    {
                        key: 'menu.work.obligation.documents',
                        path: '/human/work-obligation/call/work-obligation-documents',
                        icon: <FileTextOutlined />,
                        i18n: 'menu.work.obligation.documents',
                        role: MobilizationRights.isMobilizationViewUserMenu(),
                    },
                ],
            },
            {
                key: 'menu.work.obligation.employeeMilitaryRecords',
                path: '/human/work-obligation/employee-military-records',
                icon: <SnippetsOutlined />,
                i18n: 'menu.work.obligation.employeeMilitaryRecords',
                role: MobilizationRights.isMobilizationViewExecutorMenu(),
            },
            {
                key: 'menu.work.obligation.settings',
                path: '/human/work-obligation/mobilization-settings',
                icon: <SettingOutlined />,
                i18n: 'menu.work.obligation.settings',
                role: MobilizationRights.isMobilizationViewSettingsMenu(),
            },
        ],
    },
    {
        key: 'menu.employee.education',
        path: '/human/employee-education-overview',
        icon: <i className="la la-graduation-cap" style={{ fontSize: '17px', marginTop: '5px' }} />,
        i18n: 'menu.employee.education',
        role: HumanResourcesRights.canViewEducationMenu(),
    },
    {
        key: 'menu.contracts',
        path: '/human/contracts',
        icon: <FileProtectOutlined />,
        i18n: 'menu.contracts.codebooks',
        role: HumanResourcesRights.canViewContractsMenuAll(),
        submenu: [
            {
                key: 'menu.contract.category.rights',
                path: '/human/contracts/contract-category-rights',
                icon: <FileOutlined />,
                i18n: 'menu.contract.category.rights',
                role: HumanResourcesRights.canViewContractsMenuAdmin(),
            },
            {
                key: 'menu.contractsCTR',
                path: '/human/contracts/contractCTR',
                icon: <FileOutlined />,
                i18n: 'menu.contractsCTR',
                role: HumanResourcesRights.canViewContractsMenuAll(),
            },
            {
                key: 'menu.contract.type',
                path: '/human/contracts/contract-type',
                icon: <FileOutlined />,
                i18n: 'menu.contract.type',
                role: HumanResourcesRights.canViewContractsMenuAll(),
            },
        ],
    },
    {
        key: 'menu.evaluation',
        path: '/human/employee-evaluation',
        icon: <FileProtectOutlined />,
        i18n: 'menu.evaluation.codebooks',
        role: HumanResourcesRights.canViewEvaluation(),
    },

    {
        key: 'menu.human.resources.settings',
        path: '/human/settings',
        icon: <SettingOutlined />,
        i18n: 'menu.settings',
        role: HumanResourcesRights.canViewMenuSettingsAdmin(),
        submenu: [
            {
                key: 'menu.human.notification.settings',
                path: '/human/settings/human-notification-settings',
                icon: <ControlOutlined />,
                i18n: 'menu.notification.settings',
                role: HumanResourcesRights.canViewMenuSettingsAdmin(),
            },
            {
                key: 'menu.human.access.rights',
                path: '/human/settings/human-access-right',
                icon: <FolderViewOutlined />,
                i18n: 'menu.human.access.rights',
                role: HumanResourcesRights.canViewMenuSettingsAdmin(),
            },
            {
                key: 'menu.human.access.rights.attendance',
                path: '/human/settings/human-access-right-attendance',
                icon: <FolderViewOutlined />,
                i18n: 'menu.human.access.rights.attendance',
                role: HumanResourcesRights.canViewMenuSettingsAdmin(),
            },
        ],
    },
    {
        key: 'menu.human.codebooks',
        path: '/human/codebooks',
        icon: <BookOutlined />,
        i18n: 'menu.codebooks',
        role: HumanResourcesRights.canViewHumanCodebooksMenu(),
        submenu: [
            {
                key: 'menu.human.codebooks.human',
                path: '/human/codebooks/human',
                icon: <BookOutlined />,
                i18n: 'menu.human',
                role: HumanResourcesRights.canViewCodeooksHumanMenuWorkplace(),
                submenu: [
                    {
                        key: 'menu.employeeGroup',
                        path: '/human/codebooks/human/employeeGroup',
                        icon: <TeamOutlined />,
                        i18n: 'menu.employeeGroup',
                        role: HumanResourcesRights.canViewCodeooksHumanMenu(),
                    },
                    {
                        key: 'menu.vocation',
                        path: '/human/codebooks/human/vocation',
                        icon: <TrophyOutlined />,
                        i18n: 'menu.vocation',
                        role: HumanResourcesRights.canViewCodeooksHumanMenu(),
                    },
                    {
                        key: 'menu.vocation.group',
                        path: '/human/codebooks/human/vocation-group',
                        icon: <TrophyFilled />,
                        i18n: 'menu.vocation.group',
                        role: HumanResourcesRights.canViewCodeooksHumanMenu(),
                    },
                    {
                        key: 'menu.vocation.description',
                        path: '/human/codebooks/human/vocation-description',
                        icon: (
                            <i
                                style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }}
                                className="las la-comment"
                            />
                        ),
                        i18n: 'menu.vocation.description',
                        role: HumanResourcesRights.canViewCodeooksHumanMenu(),
                    },
                    {
                        key: 'menu.shift',
                        path: '/human/codebooks/human/shift',
                        icon: <ContactsOutlined />,
                        i18n: 'menu.shift',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.room',
                        path: '/human/codebooks/human/room',
                        icon: <UsergroupAddOutlined />,
                        i18n: 'menu.room',
                        role: HumanResourcesRights.canViewCodeooksHumanMenu(),
                    },
                    {
                        key: 'menu.businessUnit',
                        path: '/human/codebooks/human/business-unit',
                        icon: <UserSwitchOutlined />,
                        i18n: 'menu.businessUnit',
                        role: HumanResourcesRights.canViewCodeooksHumanMenu(),
                    },
                    {
                        key: 'menu.businessUnitType',
                        path: '/human/codebooks/human/business-unit-type',
                        icon: <FileOutlined />,
                        i18n: 'menu.businessUnitType',
                        role: HumanResourcesRights.canViewCodeooksHumanMenu(),
                    },
                    {
                        key: 'menu.portfolio',
                        path: '/human/codebooks/human/portfolio',
                        icon: <UserOutlined />,
                        i18n: 'menu.portfolio',
                        role: HumanResourcesRights.canViewCodeooksHumanMenu(),
                    },
                    {
                        key: 'menu.sickLeave',
                        path: '/human/codebooks/human/sickLeave',
                        icon: <MedicineBoxOutlined />,
                        i18n: 'menu.sickLeave',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.workplace',
                        path: '/human/codebooks/human/workplace',
                        icon: <FileOutlined />,
                        i18n: 'menu.workplace',
                        role: HumanResourcesRights.canViewCodeooksHumanMenuWorkplace(),
                    },
                    {
                        key: 'menu.employee.status',
                        path: '/human/codebooks/human/employee-status',
                        icon: <FileOutlined />,
                        i18n: 'menu.employee.status',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.professional.qualification',
                        path: '/human/codebooks/human/professional-qualification',
                        icon: (
                            <i
                                style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }}
                                className="las la-graduation-cap"
                            />
                        ),
                        i18n: 'menu.professional.qualification',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.qualification.level',
                        path: '/human/codebooks/human/qualification-level',
                        icon: (
                            <i
                                style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }}
                                className="las la-graduation-cap"
                            />
                        ),
                        i18n: 'menu.qualification.level',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.educational.program',
                        path: '/human/codebooks/human/educational-program',
                        icon: (
                            <i
                                style={{ fontSize: '15px', marginLeft: '-1px', marginTop: '-1px' }}
                                className="las la-school"
                            />
                        ),
                        i18n: 'menu.educational.program',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },

                    {
                        key: 'menu.employment.type',
                        path: '/human/codebooks/human/employment-type',
                        icon: (
                            <i
                                style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }}
                                className="las la-briefcase"
                            />
                        ),
                        i18n: 'menu.employment.type',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.employee.type',
                        path: '/human/codebooks/human/employee-type',
                        icon: (
                            <i
                                style={{ fontSize: '17px', marginLeft: '-2px', marginTop: '-2px' }}
                                className="las la-briefcase"
                            />
                        ),
                        i18n: 'menu.employee.type',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.citizenship',
                        path: '/human/codebooks/human/citizenship',
                        icon: <FileOutlined />,
                        i18n: 'menu.citizenship',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.nationality',
                        path: '/human/codebooks/human/nationality',
                        icon: <FileOutlined />,
                        i18n: 'menu.nationality',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.educational.domain',
                        path: '/human/codebooks/human/educational-domain',
                        icon: <FileOutlined />,
                        i18n: 'menu.educational.domain',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.educationalTitle',
                        path: '/human/codebooks/human/educationalTitle',
                        icon: <FileOutlined />,
                        i18n: 'menu.educationalTitle',
                        role: HumanResourcesRights.canViewHRCodebooksMenu(),
                    },
                    {
                        key: 'menu.educational.institution',
                        path: '/human/codebooks/human/educational-institution',
                        icon: <FileOutlined />,
                        i18n: 'menu.educational.institution',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.document.type',
                        path: '/human/codebooks/human/document-type',
                        icon: <FileOutlined />,
                        i18n: 'menu.document.type',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.reward.type',
                        path: '/human/codebooks/human/reward-type',
                        icon: <FileOutlined />,
                        i18n: 'menu.reward.type',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.payrollBrut.human',
                        path: '/human/codebooks/human/payrollBrut',
                        icon: <FileOutlined />,
                        i18n: 'menu.payrollBrut',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.pointAdditionDecision.human',
                        path: '/human/codebooks/human/pointAdditionDecision',
                        icon: <FileOutlined />,
                        i18n: 'menu.pointAdditionDecision',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    // {
                    //     key: 'menu.educational.sub.program',
                    //     path: '/human/educational-sub-program',
                    //     icon: <FileOutlined />,
                    //     i18n: 'menu.educational.sub.program',
                    //     role: HumanResourcesRights.canViewEmployeeMenu(),
                    // },
                ],
            },
            {
                key: 'menu.payroll.codebooks',
                path: '/human/codebooks/payroll',
                icon: <BookOutlined />,
                i18n: 'menu.payroll',
                role: PayrollRights.canViewPayrollCodebooksMenu(),
                submenu: [
                    {
                        key: 'menu.payrollInsurance',
                        path: '/human/codebooks/payroll/payrollInsurance',
                        icon: <FileOutlined />,
                        i18n: 'menu.payrollInsurance',
                        role: PayrollRights.canViewPayrollCodebooksMenu(),
                    },
                    {
                        key: 'menu.payrollBrut',
                        path: '/human/codebooks/payroll/payrollBrut',
                        icon: <FileOutlined />,
                        i18n: 'menu.payrollBrut',
                        role: PayrollRights.canViewPayrollCodebooksMenu(),
                    },
                    {
                        key: 'menu.pointAdditionDecision',
                        path: '/human/codebooks/payroll/pointAdditionDecision',
                        icon: <FileOutlined />,
                        i18n: 'menu.pointAdditionDecision',
                        role: PayrollRights.canViewPayrollCodebooksMenu(),
                    },
                    {
                        key: 'menu.payrollTaxDeduction',
                        path: '/human/codebooks/payroll/payrollTaxDeduction',
                        icon: <FileOutlined />,
                        i18n: 'menu.payrollTaxDeduction',
                        role: PayrollRights.canViewPayrollCodebooksMenu(),
                    },
                    {
                        key: 'menu.payrollTax',
                        path: '/human/codebooks/payroll/payrollTax',
                        icon: <FileOutlined />,
                        i18n: 'menu.payrollTax',
                        role: PayrollRights.canViewPayrollCodebooksMenu(),
                    },
                    {
                        key: 'menu.payrollAddition',
                        path: '/human/codebooks/payroll/payrollAddition',
                        icon: <FileOutlined />,
                        i18n: 'menu.payrollAddition',
                        role: PayrollRights.canViewPayrollCodebooksMenu(),
                    },
                    {
                        key: 'menu.township.tax',
                        path: '/human/codebooks/payroll/township-tax',
                        icon: <FileOutlined />,
                        i18n: 'menu.township.tax',
                        role: PayrollRights.canViewPayrollCodebooksMenu(),
                    },
                    {
                        key: 'menu.payrollExpense',
                        path: '/human/codebooks/payroll/payrollExpense',
                        icon: <FileOutlined />,
                        i18n: 'menu.payrollExpense',
                        role: PayrollRights.canViewPayrollCodebooksMenu(),
                    },
                    {
                        key: 'menu.payrollPointValue',
                        path: '/human/codebooks/payroll/payrollPointValue',
                        icon: <FileOutlined />,
                        i18n: 'menu.payrollPointValue',
                        role: PayrollRights.canViewPayrollCodebooksMenu(),
                    },
                    {
                        key: 'menu.payrollTaxReduction',
                        path: '/human/codebooks/payroll/payrollTaxReduction',
                        icon: <FileOutlined />,
                        i18n: 'menu.payrollTaxReduction',
                        role: PayrollRights.canViewPayrollCodebooksMenu(),
                    },
                ],
            },
            {
                key: 'menu.work.obligation.codebooks',
                path: '/human/codebooks/work-obligation',
                icon: <BookOutlined />,
                i18n: 'menu.work.obligation',
                role: MobilizationRights.isMobilizationViewMenu(),
                submenu: [
                    {
                        key: 'menu.work.obligation.reserve.rank',
                        path: '/human/codebooks/work-obligation/reserve-rank',
                        icon: <FileOutlined />,
                        i18n: 'menu.work.obligation.reserve.rank',
                        role: MobilizationRights.isMobilizationViewMenu(),
                    },
                    {
                        key: 'menu.work.obligation.professional.specialty',
                        path: '/human/codebooks/work-obligation/professional-specialty',
                        icon: <BookOutlined />,
                        i18n: 'menu.work.obligation.professional.specialty',
                        role: MobilizationRights.isMobilizationViewMenu(),
                    },
                    {
                        key: 'menu.work.obligation.defense.department',
                        path: '/human/codebooks/work-obligation/defense-department',
                        icon: <FileOutlined />,
                        i18n: 'menu.work.obligation.defense.department',
                        role: MobilizationRights.isMobilizationViewMenu(),
                    },
                    {
                        key: 'menu.work.obligation.mobilization.location',
                        path: '/human/codebooks/work-obligation/mobilization-location',
                        icon: <FileOutlined />,
                        i18n: 'menu.work.obligation.mobilization.location',
                        role: MobilizationRights.isMobilizationViewMenu(),
                    },
                    {
                        key: 'menu.security.certificate.level',
                        path: '/human/codebooks/work-obligation/security-certificate-level',
                        icon: <FileOutlined />,
                        i18n: 'menu.security.certificate.level',
                        role: MobilizationRights.isMobilizationViewMenu(),
                    },
                ],
            },
            {
                key: 'menu.contracts.codebooks',
                path: '/human/codebooks/contracts',
                icon: <BookOutlined />,
                i18n: 'menu.contracts.codebooks',
                role: HumanResourcesRights.canViewContractsMenuAll(),
                submenu: [
                    // {
                    //     key: 'menu.contract.category',
                    //     path: '/human/codebooks/contracts/contract-category',
                    //     icon: <FileOutlined />,
                    //     i18n: 'menu.contract.category',
                    //     role:  HumanResourcesRights.canViewContractsMenuAdmin(),
                    // },
                    {
                        key: 'menu.contract.type.group',
                        path: '/human/codebooks/contracts/contract-type-group',
                        icon: <FileOutlined />,
                        i18n: 'menu.contract.type.group',
                        role: HumanResourcesRights.canViewContractsMenuAll(),
                    },
                    {
                        key: 'menu.contract.global.constants',
                        path: '/human/codebooks/contracts/contract-global-constants',
                        icon: <FileOutlined />,
                        i18n: 'menu.contract.global.constants',
                        role: HumanResourcesRights.canViewContractsMenuContract(),
                    },
                    {
                        key: 'menu.contract.hrs',
                        path: '/human/codebooks/contracts/contract-hr',
                        icon: <FileOutlined />,
                        i18n: 'menu.contract.hr',
                        role: HumanResourcesRights.isAdminMenu(),
                    },
                ],
            },
            {
                key: 'menu.attendance.registration.codebooks',
                path: '/human/codebooks/attendance',
                icon: <BookOutlined />,
                i18n: 'menu.attendance.registration',
                role: HumanResourcesRights.canViewAttendanceHumanMenu(),
                submenu: [
                    {
                        key: 'menu.location',
                        path: '/human/codebooks/attendance/location',
                        icon: <EnvironmentOutlined />,
                        i18n: 'menu.location',
                        role: HumanResourcesRights.canViewAttendanceHumanMenu(),
                    },
                    {
                        key: 'menu.attendance.break.type',
                        path: '/human/codebooks/attendance/attendance-break-type',
                        icon: <CoffeeOutlined />,
                        i18n: 'menu.attendance.break.type',
                        role: HumanResourcesRights.canViewAttendanceHumanMenu(),
                    },
                    {
                        key: 'menu.device',
                        path: '/human/codebooks/attendance/device',
                        icon: <LaptopOutlined />,
                        i18n: 'menu.device',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                    {
                        key: 'menu.work.type',
                        path: '/human/codebooks/attendance/work-type',
                        icon: <SnippetsOutlined />,
                        i18n: 'menu.work.type',
                        role: HumanResourcesRights.canViewEmployeeMenu(),
                    },
                ],
            },
            {
                key: 'menu.education.codebooks',
                path: '/human/codebooks/education',
                icon: <BookOutlined />,
                i18n: 'menu.education.codebooks',
                role: HumanResourcesRights.canViewEducationCodebooks(),
                submenu: [
                    {
                        key: 'menu.education.educationForm',
                        path: '/human/codebooks/education/education-form',
                        icon: <FileOutlined />,
                        i18n: 'menu.education.educationForm',
                        role: HumanResourcesRights.canViewEducationCodebooks(),
                    },
                    {
                        key: 'menu.education.educationInstitution',
                        path: '/human/codebooks/education/education-institution',
                        icon: <FileOutlined />,
                        i18n: 'menu.education.educationInstitution',
                        role: HumanResourcesRights.canViewEducationCodebooks(),
                    },
                    {
                        key: 'menu.education.educationPurpose',
                        path: '/human/codebooks/education/education-purpose',
                        icon: <FileOutlined />,
                        i18n: 'menu.education.educationPurpose',
                        role: HumanResourcesRights.canViewEducationCodebooks(),
                    },
                    {
                        key: 'menu.education.programCatalog',
                        path: '/human/codebooks/education/program-catalog',
                        icon: <FileOutlined />,
                        i18n: 'menu.education.programCatalog',
                        role: HumanResourcesRights.canViewEducationCodebooks(),
                    },
                ],
            },
            {
                key: 'menu.evaluation.codebooks',
                path: '/human/codebooks/evaluation',
                icon: <BookOutlined />,
                i18n: 'menu.evaluation.codebooks',
                role: HumanResourcesRights.canViewEvaluationCodebooks(),
                submenu: [
                    {
                        key: 'menu.evaluation.competency.evaluation',
                        path: '/human/codebooks/evaluation/competency-evaluation',
                        icon: <FileOutlined />,
                        i18n: 'menu.evaluation.competency.evaluation',
                        role: HumanResourcesRights.canViewEvaluationCodebooks(),
                    },
                    {
                        key: 'menu.evaluation.competency.type',
                        path: '/human/codebooks/evaluation/competency-type',
                        icon: <FileOutlined />,
                        i18n: 'menu.evaluation.competency.type',
                        role: HumanResourcesRights.canViewEvaluationCodebooks(),
                    },
                    {
                        key: 'menu.evaluation.competency.template',
                        path: '/human/codebooks/evaluation/competency-template',
                        icon: <FileOutlined />,
                        i18n: 'menu.evaluation.competency.template',
                        role: HumanResourcesRights.canViewEvaluationCodebooks(),
                    },
                ],
            },
        ],
    },
];

export default HumanMenu;
